import { Fragment, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { XMarkIcon } from "@heroicons/react/24/solid";
import CheckoutForm from "../components/CheckoutForm";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { STRIPE_KEY } from "../utils/config";
const ChangeCardModal = ({ isOpen, setIsOpen, getProfile , selected }) => {
  // Context
  const { setBtnLoading } = useLoggedContext();

  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
    setBtnLoading(false);
  };

  // Stripe Details
  const stripePromise = loadStripe(STRIPE_KEY);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-lg overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-bold leading-7 text-secondary-color">
                      Add Card
                      <p className="text-sm font-normal text-gray-500">Add new card for payments</p>
                    </Dialog.Title>

                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>

                  <Elements stripe={stripePromise}>
                    <CheckoutForm price={selected} closeModal={closeModal} getProfile={getProfile} />
                  </Elements>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default ChangeCardModal;
