import React from "react";
import { images } from "../assets/styles/Images";

const Badge = ({ status, crown }) => {
  let lowerCaseStatus = status?.toLowerCase();
  if (lowerCaseStatus === "current plan") {
    lowerCaseStatus = "Current Plan";
  } else if (lowerCaseStatus === "notifications enabled") {
    lowerCaseStatus = "Notifications Enabled";
  } else if (lowerCaseStatus === "notifications disabled") {
    lowerCaseStatus = "Notifications Disabled";
  }

  const badgeColors = {
    "Current Plan": "bg-[#1F8D6026] text-accent",
    active: "bg-[#1F8D6026] text-accent",
    accept: "bg-[#1F8D6026] text-accent",
    block: "bg-[#FE4A4933] text-red-500",
    open: "bg-accent text-white",
    closed: "bg-[#FE4A49] text-white",
    deactive: "bg-red-200 text-red-400",
    admin: "bg-[#30303026] text-black",
    author: "bg-[#FFBC0926] text-[#FFBC09]",
    pending: "bg-[#FFBC0926] text-[#FFBC09]",
    success: "bg-[#1F8D6026] text-accent",
    failed: "bg-[#FE4A4933] text-red-500",
    "Notifications Enabled": "bg-[#1F8D6026] text-accent",
    "Notifications Disabled": "bg-[#FE4A4933] text-red-500",
  };

  return (
    <div
      className={`flex ${lowerCaseStatus === "Current Plan" ? "w-24" : lowerCaseStatus === "Notifications Enabled" ? "w-36" : lowerCaseStatus === "Notifications Disabled" ? "w-40" : "w-20"}  items-center justify-center rounded-full py-1 text-xs font-semibold capitalize ${badgeColors[lowerCaseStatus]}`}
    >
      {lowerCaseStatus === "author" && <img src={images.Crown} className="-mt-1 h-4 w-4" />}
      {lowerCaseStatus}
    </div>
  );
};

export default Badge;
