import Button from "../Button";
import { useEffect } from "react";
import React, { useState } from "react";
import InputField from "../InputField";
import ApiServices from "../../api/services";
import { TrashIcon } from "@heroicons/react/20/solid";
import { useLoggedContext } from "../../context/provider";
import { validatePartialForm } from "../../helperFunctions/helper";
import Spinner from "../Spinner";

const InviteViaEmail = ({ closeModal, onSuccess }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // useStates
  const [errors, setErrors] = useState([]);
  const [apiErr, setApiErr] = useState([]);
  const [rowLoading, setRowLoading] = useState(false)
  const [formData, setFormData] = useState([{ email: "", fullName: "", employeeId: "" }]);
  
  // Input Values Handler
  const handleChange = (event, index) => {
    const { name, value } = event.target;
    const trimmedValue = value.trimStart();

    const updatedFormData = [...formData];
    updatedFormData[index] = { ...updatedFormData[index], [name]: trimmedValue };

    setErrors([...errors.slice(0, index), { ...errors[index], [name]: "" }, ...errors.slice(index + 1)]);
    setFormData(updatedFormData);
  };

// Add Another Row
const handleAddRow = async () => {
  try {
    setRowLoading(true)
    const newEmployeeIdResponse = await ApiServices.employees.getEmployeeIds();
    if (newEmployeeIdResponse?.data?.code === 200) {
      setRowLoading(false)
      const newEmployeeId = newEmployeeIdResponse.data.data;
      setFormData([...formData, { email: "", fullName: "", employeeId: newEmployeeId }]);
      setErrors([...errors, { email: "", fullName: "", employeeId: "" }]);
    } else {
      console.error("Error fetching new employee ID:", newEmployeeIdResponse?.data?.message);

    }
  } catch (error) {
    console.error("Error fetching new employee ID:", error);
    setRowLoading(false)
  }
};

  // Remove Row
  const handleRemoveRow = (index) => {
    // Ensure the first row is non-removable
    if (index !== 0) {
      setFormData((prevFormData) => prevFormData.filter((_, i) => i !== index));
      setErrors((prevErrors) => prevErrors.filter((_, i) => i !== index));
    }
  };

  // Fetch employee IDs when component mounts
  const getEmployeeIds = async () => {
    try {
      const result = await ApiServices.employees.getEmployeeIds();
      //  
      if (result?.data?.code === 200) {
        const ids = result?.data?.data;
        if (formData?.length === 1) {
          setFormData([{ email: "", fullName: "", employeeId: ids }]);
        }
      } else {
        setApiErr(["Unable to fetch employee IDs"]);
      }
    } catch (error) {
       
      setApiErr(["An unexpected error occurred while fetching employee IDs"]);
    }
  };

  // Submit Function
  const handleSubmit = async (event) => {
    event.preventDefault();

    const updatedErrors = formData.map((data) => validatePartialForm(data));
    setErrors(updatedErrors);

    if (!updatedErrors.some((error) => Object.values(error).some((value) => value !== ""))) {
      const payload = {
        users: formData.map((data) => ({
          email: data.email,
          name: data.fullName || null,
          emp_id: data.employeeId || null,
        })),
        mode: "setting_user",
      };
      setBtnLoading(true);
      ApiServices.employees
        .createByArray(payload)
        .then((res) => {
          const { data, message } = res;

          if (data && data.code === 200) {
            // Success case
            setBtnLoading(false);
            closeModal();
            onSuccess();
            openSuccessModal({
              title: "Success!",
              message: "Employee invited successfully.",
              onPress: (close) => {
                close();
                onSuccess();
              },
            });
          } else if (data && data instanceof Array) {
            const errors = data?.map((item) => `${item.email}: ${item.error}`).join("\n");
            setApiErr(errors);
            setBtnLoading(false);
          }
        })
        .catch((err) => {
           

          setApiErr(err.response.data.message);
          setBtnLoading(false);
        });
    }
  };

  useEffect(() => {
    getEmployeeIds();
  }, []);


  return (
    <div className="space-y-4">
      <form className="space-y-6">
        {formData.map((data, index) => (
          <div key={index} className="relative grid grid-cols-[30%_35%_20%_15%] 2xl:grid-cols-[30%_30%_25%_15%] gap-4 3xl:gap-6">
            <InputField
              type="email"
              name="email"
              label="Email"
              value={data.email}
              error={errors[index]?.email}
              onChange={(event) => handleChange(event, index)}
              placeholder="Enter email address"
              required
            />

            <InputField
              type="text"
              name="fullName"
              label="Full Name"
              value={data.fullName}
              error={errors[index]?.fullName}
              onChange={(event) => handleChange(event, index)}
              placeholder="Enter full name"
            />

            <InputField
              type="number"
              name="employeeId"
              label="Employee ID"
              value={data.employeeId}
              error={errors[index]?.employeeId}
              onChange={(event) => handleChange(event, index)}
              placeholder="Enter employee id"
              required
              disabled
            />

            {/* Delete */}

            {index > 0 && (
              <div className="space-y-3 ">
                <label className="invisible">Delete</label>
                <button
                  type="button"
                  className="flex items-center justify-center w-10 h-10 bg-red-500 rounded-full"
                  onClick={() => handleRemoveRow(index)}
                >
                  <TrashIcon className="w-5 h-5 text-white" />
                </button>
              </div>
            )}
          </div>
        ))}
      </form>

      <button type="button" className="text-sm font-medium text-secondary" onClick={handleAddRow}>
       {rowLoading ? <Spinner/> : " + Invite Another"}
      </button>

      {apiErr && <p className="text-red-500">{apiErr}</p>}

      <div className="flex items-center justify-end gap-x-6">
        <h3 onClick={closeModal} className="text-lg font-medium cursor-pointer text-secondary">
          Cancel
        </h3>
        <Button loading={btnLoading} title="Invite" onClick={handleSubmit} />
      </div>
    </div>
  );
};

export default InviteViaEmail;
