import React from "react";

// ===================== React Router DOM ===================== //
import { Navigate, Outlet } from "react-router-dom";

// ===================== Context Provider ===================== //
import { useLoggedContext } from "../context/provider";

const PublicRoute = ({ RedirectPath }) => {
  const { isLoggedin } = useLoggedContext();

  if (isLoggedin) {
    return <Navigate to={RedirectPath} replace />;
  }

  return <Outlet />;
};

export default PublicRoute;
