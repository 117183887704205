export const scroll = (val) => {
  if (val) {
    document.body.style.position = "static";
    document.body.style.overflowY = "scroll";
  } else {
    document.body.style.position = "fixed";
    document.body.style.overflowY = "scroll";
    document.body.style.width = "100%";
  }
};
