import React from "react";

// ===================== Context Provider ===================== //
import { useLoggedContext } from "../context/provider";

// ===================== React Router DOM ===================== //
import { Link } from "react-router-dom";

function NotFound() {
  const { isLoggedin, adminRole } = useLoggedContext();

  return (
    <>
      <div
        className={
          " flex items-center justify-center " +
          (isLoggedin ? "h-[90vh]" : "h-screen")
        }
      >
        <div className="lg:px-40 lg:py-20 lg:mx-0 sm:px-20 sm:py-14 px-12 py-8 mx-4 bg-white rounded-md shadow-xl">
          <div className="flex flex-col items-center">
            <h1 className="font-bold text-primary md:text-9xl text-7xl mb-2">
              404
            </h1>

            <h6 className="mb-2 text-[20px] font-bold text-center text-secondary md:text-3xl">
              <span className="text-primary">Oops!</span> Page not found
            </h6>

            <p className="md:mb-8 mb-5 text-center text-gray-500 md:text-lg text-md">
              The page you're looking for doesn't exist.
            </p>

            <Link
              to={isLoggedin ? adminRole ? "/super-dashboard" : "/dashboard" : "/login"}
              className="px-6 py-2 lg:text-base text-md font-semibold text-white bg-primary rounded-full"
            >
              Go home
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export default NotFound;
