import { useState, useMemo } from "react";

const defaultComparator = (fieldA, fieldB, sortOrder) => {
  if (sortOrder === "asc") {
    return fieldA < fieldB ? -1 : fieldA > fieldB ? 1 : 0;
  } else {
    return fieldB < fieldA ? -1 : fieldB > fieldA ? 1 : 0;
  }
};

// const sortArray = (array, field, sortOrder, comparator) => {
//   return [...array].sort((a, b) => {
//     let fieldA, fieldB;

//     if (field === "nursery.name") {
//       fieldA = a.nursery?.name?.toLowerCase();
//       fieldB = b.nursery?.name?.toLowerCase();
//     } else {
//       fieldA = a[field];
//       fieldB = b[field];
//     }

//     if (comparator) {
//       return comparator(fieldA, fieldB, sortOrder);
//     }

//     return defaultComparator(fieldA, fieldB, sortOrder);
//   });
// };

const sortArray = (array, field, sortOrder, comparator) => {
  // Check if the array is undefined or null
  if (!Array.isArray(array)) {
    return [];
  }

  return [...array].sort((a, b) => {
    let fieldA, fieldB;

    if (field === "employee.name") {
      fieldA = a.employee && a.employee[0]?.name?.toLowerCase();
      fieldB = b.employee && b.employee[0]?.name?.toLowerCase();
      if (!fieldA || !fieldB) {
        fieldA = a.user?.employee && a.user.employee[0]?.name?.toLowerCase();
        fieldB = b.user?.employee && b.user.employee[0]?.name?.toLowerCase();
      }
    } else if (field === "employee.id") {
      fieldA = a.employee && a.employee[0]?.emp_id?.toLowerCase();
      fieldB = b.employee && b.employee[0]?.emp_id?.toLowerCase();
      if (!fieldA || !fieldB) {
        fieldA = a.user?.employee && a.user.employee[0]?.emp_id?.toLowerCase();
        fieldB = b.user?.employee && b.user.employee[0]?.emp_id?.toLowerCase();
      }
    } else if (field === "employee.shift_time") {
      fieldA = a.employee[0]?.shift_time?.toLowerCase();
      fieldB = b.employee[0]?.shift_time?.toLowerCase();
    } else if (field === "employee.joining_date") {
      fieldA = a.employee[0]?.unix_joining_date?.toLowerCase();
      fieldB = b.employee[0]?.unix_joining_date?.toLowerCase();
    } else if (field === "employee.expiresAt") {
      fieldA = a.employee[0]?.expire_date?.toLowerCase();
      fieldB = b.employee[0]?.expire_date?.toLowerCase();
    } else if (field === "employee.campus.name") {
      fieldA = a.employee[0]?.campus?.name?.toLowerCase();
      fieldB = b.employee[0]?.campus?.name?.toLowerCase();
    } else if (field === "createdBy.evaluation") {
      fieldA = a.user?.evaluation_employee[0]?.name?.toLowerCase();
      fieldB = b.user?.evaluation_employee[0]?.name?.toLowerCase();
    } else if (field === "reasonDate") {
      fieldA = a.user_response[0]?.created_date_unix;
      fieldB = b.user_response[0]?.created_date_unix;
    } else if (field === "reason") {
      fieldA = a.user_response[0]?.reason?.toLowerCase();
      fieldB = b.user_response[0]?.reason?.toLowerCase();
    } else if (field === "responses.reason") {
      fieldA = a?.reason?.toLowerCase();
      fieldB = b?.reason?.toLowerCase();
    } else {
      fieldA = a[field];
      fieldB = b[field];
    }

    if (comparator) {
      return comparator(fieldA, fieldB, sortOrder);
    }

    return defaultComparator(fieldA, fieldB, sortOrder);
  });
};

export const useSortableData = (items, defaultField = null) => {
  const [sortConfig, setSortConfig] = useState({
    key: defaultField,
    direction: "asc",
  });

  const sortedItems = useMemo(() => {
    return sortArray(items, sortConfig.key, sortConfig.direction);
  }, [items, sortConfig]);

  const requestSort = (key) => {
    let direction = "asc";
    if (sortConfig.key === key && sortConfig.direction === "asc") {
      direction = "desc";
    }
    setSortConfig({ key, direction });
  };

  return { items: sortedItems, requestSort, sortConfig };
};
