import React from "react";
import Spinner from "./Spinner";

const Button = ({ type, title, className, icon, btnSmall, onClick, buttonColor, loading, disabled }) => {
  return (
    <button
      type={type ? type : "submit"}
      onClick={onClick}
      disabled={disabled} 
      className={`flex ${btnSmall ? "h-10 px-6 text-sm" : "h-11 3xl:h-12 px-8 text-sm 2xl:text-base"} items-center justify-center rounded-full ${buttonColor ? buttonColor : "border-secondary bg-secondary text-white"} font-medium 
     transition-all hover:shadow-lg ${className ? className : ""}`}
    >
      {loading ? (
        <Spinner />
      ) : (
        <>
          {icon && <span className="w-6 h-6 mr-2 text-white">{icon}</span>}
          {title}
        </>
      )}
    </button>
  );
};

export default Button;
