import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { images } from "../assets/styles/Images";
import StatusFilter from "./StatusFilter";
import ApiServices from "../api/services/index";
import moment from "moment";
import { useLoggedContext } from "../context/provider";

const getCurrentDate = () => {
  const todayStart = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 0, 0, 0));
  const todayEnd = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 23, 59, 59));

  return { start: todayStart, end: todayEnd };
};

const getYesterdayDate = () => {
  const yesterdayStart = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 1, 0, 0, 0));
  const yesterdayEnd = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate() - 1, 23, 59, 59));

  return { start: yesterdayStart, end: yesterdayEnd };
};

const getLastSevenDaysRange = () => {
  const today = new Date();
  const lastSevenDays = new Date(today.getTime() - 7 * 24 * 60 * 60 * 1000);
  return { start: lastSevenDays, end: today };
};

const getLastMonthDate = () => {
  const lastMonth = new Date();
  lastMonth.setUTCMonth(lastMonth.getUTCMonth() - 1);
  lastMonth.setUTCDate(1);
  return lastMonth;
};

const getCurrentYear = () => {
  const today = new Date();
  return today.getUTCFullYear();
};

const getLastYear = () => {
  const today = new Date();
  return today.getUTCFullYear() - 1;
};

const Drawer = ({
  clearFilter,
  api,
  isOpen,
  setIsOpen,
  onApplyFilters,
  allSettingsList,
  allDepartments,
  allCampuses,
  dateRange,
  setDateRange,
  setSelectedDepartmentOption,
  selectedDepartmentOption,
  setSelectedSettingOption,
  selectedSettingOption,
  setSelectedCampusOption,
  selectedCampusOption,
  selectedValue,
  setSelectedValue,
  setIsFilterApplied,
  dateError,
  setDateError,
  isFilterApplied,
  setAllDepartments,
  getDepartmentList,
  // clearDraweFilters,
  selectedFilter,
  setSelectedFilter,
  selectedCampus,
  setSelectedCampus,
  selectedDepartment,
  setSelectedDepartment,
}) => {
  const { user } = useLoggedContext();

  const allCampusStatus = user?.user?.employee[0]?.all_campus_status;
  const allCampusStatusByRole = user?.user?.role;
  console.log("🚀 ~ allCampusStatusByRole:", allCampusStatusByRole);

  // useEffect(() => {
  //   // Set the default selection to 'lastSevenDays' when the component mounts
  //   setSelectedValue('lastSevenDays');
  //   const lastSevenDays = getLastSevenDaysRange();
  //   setDateRange({
  //     startDate: Math.floor(lastSevenDays.start.getTime() / 1000),
  //     endDate: Math.floor(lastSevenDays.end.getTime() / 1000),
  //   });
  // }, []);

  const handleRadioChange = (event) => {
    const value = event.target.value;
    // setIsFilterApplied(false);
    setSelectedFilter(value);

    // switch (value) {
    //   case "today":
    //     const today = getCurrentDate();
    //     startDate = Math.floor(today.start.getTime() / 1000);
    //     endDate = Math.floor(today.end.getTime() / 1000);
    //     break;
    //   case "yesterday":
    //     const yesterday = getYesterdayDate();
    //     startDate = Math.floor(yesterday.start.getTime() / 1000);
    //     endDate = Math.floor(yesterday.end.getTime() / 1000);
    //     break;
    //   case "lastSevenDays":
    //     const lastSevenDays = getLastSevenDaysRange();
    //     startDate = Math.floor(lastSevenDays.start.getTime() / 1000);
    //     endDate = Math.floor(lastSevenDays.end.getTime() / 1000);
    //     break;
    //   case "lastMonth":
    //     startDate = Math.floor(getLastMonthDate().getTime() / 1000);
    //     endDate = Math.floor(getCurrentDate().end.getTime() / 1000);
    //     break;
    //   case "thisYear":
    //     const thisYear = new Date(Date.UTC(getCurrentYear(), 0, 1, 0, 0, 0));
    //     startDate = Math.floor(thisYear.getTime() / 1000);
    //     endDate = Math.floor(getCurrentDate().end.getTime() / 1000);
    //     break;
    //   case "lastYear":
    //     const lastYearStart = new Date(Date.UTC(getLastYear(), 0, 1, 0, 0, 0));
    //     const lastYearEnd = new Date(Date.UTC(getLastYear(), 11, 31, 23, 59, 59));
    //     startDate = Math.floor(lastYearStart.getTime() / 1000);
    //     endDate = Math.floor(lastYearEnd.getTime() / 1000);
    //     break;
    //   case "custom":
    //     // Handle custom date range separately
    //     break;
    //   default:
    //     break;
    // }
    // if (isFilterApplied) {
    // setDateRange({ startDate, endDate });
    // }
  };

  const handleCustomDateChange = (e) => {
    const { name, value } = e.target;
    const newDate = Math.floor(new Date(value).getTime() / 1000);
    // if (isFilterApplied) {
    setDateRange((prev) => {
      let updatedRange = { ...prev, [name]: newDate };

      if (name === "startDate" && updatedRange.endDate) {
        const startMoment = moment.unix(newDate);
        const endMoment = moment.unix(updatedRange.endDate);

        if (newDate > updatedRange.endDate) {
          setDateError("Start date cannot be after end date.");
          return prev;
        } else if (startMoment.isSame(endMoment, "day")) {
          updatedRange.endDate = startMoment.endOf("day").unix();
        }
      } else if (name === "endDate" && updatedRange.startDate) {
        const startMoment = moment.unix(updatedRange.startDate);
        const endMoment = moment.unix(newDate);

        if (newDate < updatedRange.startDate) {
          setDateError("End date cannot be before start date.");
          return prev;
        } else if (startMoment.isSame(endMoment, "day")) {
          updatedRange.endDate = endMoment.endOf("day").unix();
        }
      }

      setDateError("");
      return updatedRange;
    });
    // }
  };

  const handleApplyFilters = () => {
    setSelectedValue(selectedFilter);
    setSelectedCampusOption(selectedCampus);
    setSelectedDepartmentOption(selectedDepartment);
    let startDate = null;
    let endDate = null;
    switch (selectedFilter) {
      case "today":
        const today = getCurrentDate();
        startDate = Math.floor(today.start.getTime() / 1000);
        endDate = Math.floor(today.end.getTime() / 1000);
        break;
      case "yesterday":
        const yesterday = getYesterdayDate();
        startDate = Math.floor(yesterday.start.getTime() / 1000);
        endDate = Math.floor(yesterday.end.getTime() / 1000);
        break;
      case "lastSevenDays":
        const lastSevenDays = getLastSevenDaysRange();
        startDate = Math.floor(lastSevenDays.start.getTime() / 1000);
        endDate = Math.floor(lastSevenDays.end.getTime() / 1000);
        break;
      case "lastMonth":
        startDate = Math.floor(getLastMonthDate().getTime() / 1000);
        endDate = Math.floor(getCurrentDate().end.getTime() / 1000);
        break;
      case "thisYear":
        const thisYear = new Date(Date.UTC(getCurrentYear(), 0, 1, 0, 0, 0));
        startDate = Math.floor(thisYear.getTime() / 1000);
        endDate = Math.floor(getCurrentDate().end.getTime() / 1000);
        break;
      case "lastYear":
        const lastYearStart = new Date(Date.UTC(getLastYear(), 0, 1, 0, 0, 0));
        const lastYearEnd = new Date(Date.UTC(getLastYear(), 11, 31, 23, 59, 59));
        startDate = Math.floor(lastYearStart.getTime() / 1000);
        endDate = Math.floor(lastYearEnd.getTime() / 1000);
        break;
      case "custom":
        startDate = dateRange.startDate;
        endDate = dateRange.endDate;
        break;
      default:
        break;
    }
    // setDateRange({ startDate, endDate });
    // setIsFilterApplied(true);

    if (selectedFilter === "custom") {
      if (!startDate || !endDate) {
        setDateError("Please select both start and end dates.");
        return;
      }
      if (startDate > endDate) {
        setDateError("End date cannot be before start date.");
        return;
      }
      setDateError("");
    }
    const dateRangeModified = { startDate, endDate };
    setDateRange(dateRangeModified);

    const filterStates = {
      dateRangeModified,
      selectedCampusOption,
      selectedDepartmentOption,
    };
    onApplyFilters(filterStates);
    setIsOpen(false);
    api(startDate, endDate, selectedCampus, selectedDepartment);
  };

  function closeModal() {
    setIsOpen(false);
    // clearFilter();
  }
  useEffect(() => {
    setSelectedFilter(selectedValue);
    setSelectedDepartment(selectedDepartmentOption);
    if (allCampusStatus !== 1 && allCampusStatusByRole !== "setting_user") {
      setSelectedCampus({ label: user?.user?.employee[0]?.campus?.name, value: user?.user?.employee[0]?.campus?.id });
    } else {
      setSelectedCampus(selectedCampusOption);
    }
  }, [isOpen]);
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-in-out duration-500"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in-out duration-500"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black/50 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto relative max-w-md" style={{ width: "414px" }}>
                  <div className="flex h-screen flex-col bg-white py-6 shadow-xl">
                    <div className="flex items-center justify-between px-4 sm:px-6">
                      <Dialog.Title className="font-poppins text-lg font-semibold leading-6 text-secondary-color">
                        <img className="mr-4 inline-block" src={images.FILTER} alt="filter" />
                        Filters
                      </Dialog.Title>

                      <span onClick={() => setIsOpen(false)} className="cursor-pointer">
                        <img src={images.CLOSE} alt="Close" className="h-4 w-4" />
                      </span>
                    </div>
                    <div className="relative mt-6 flex-1 border-t px-4 py-6 sm:px-6">
                      {/* <div className="flex justify-end">
                        <button onClick={clearFilter} type="button" className="flex items-center text-sm font-poppins gap-x-2">
                          <XMarkIcon className="w-4 h-4 text-neutral" /> Clear filter
                        </button>
                      </div> */}

                      <div className="scrollbar-non h-[calc(100vh-260px)] space-y-5 overflow-y-auto py-4 scrollbar-none">
                        <div className="space-y-4">
                          <h3 className="font-poppins text-sm font-medium text-primary-color-200">Campus</h3>
                          <StatusFilter
                            drawerFilter
                            options={allCampuses}
                            selected={selectedCampus}
                            setSelected={(e) => {
                              if (e?.value && e?.value !== "All") {
                                getDepartmentList(e?.value);
                                setSelectedDepartment({ label: "All", value: "All" });
                              }
                              if (e?.value === "All") {
                                getDepartmentList();
                                setSelectedDepartment({ label: "All", value: "All" });
                              } else {
                                setAllDepartments([]);
                                // setSelectedDepartmentOption(null);
                              }
                              setSelectedCampus(e);
                              // setSelectedCampusOption(e)
                              //  setSelectedDepartment({ label: "All", value: "All" });
                            }}
                            maxWidth="w-full"
                            isDisabled={allCampusStatus === 1 ? false : allCampusStatusByRole === "setting_user" ? false : true}
                          />
                        </div>
                        <div className="space-y-4">
                          <h3 className="font-poppins text-sm font-medium text-primary-color-200">Department</h3>
                          <StatusFilter
                            drawerFilter
                            options={allDepartments}
                            selected={selectedDepartment}
                            setSelected={setSelectedDepartment}
                            maxWidth="w-full"
                          />
                        </div>
                        <div className="space-y-4">
                          <h3 className="font-poppins text-sm font-medium text-primary-color-200">Show Data For</h3>
                          <div className="space-y-4 rounded-md bg-detailProfileColor/60 p-4">
                            <div className="flex items-center gap-x-2">
                              <input
                                id="today"
                                type="radio"
                                value="today"
                                name="default-radio"
                                checked={selectedFilter === "today"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="today" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Today</span>({getCurrentDate().start.toLocaleDateString()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="yesterday"
                                type="radio"
                                value="yesterday"
                                name="default-radio"
                                checked={selectedFilter === "yesterday"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="yesterday" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Yesterday</span>({getYesterdayDate().start.toLocaleDateString()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="lastSevenDays"
                                type="radio"
                                value="lastSevenDays"
                                name="default-radio"
                                checked={selectedFilter === "lastSevenDays"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="lastSevenDays" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Last 7 days</span>({getLastSevenDaysRange().start.toLocaleDateString()} -{" "}
                                {getLastSevenDaysRange().end.toLocaleDateString()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="lastMonth"
                                type="radio"
                                value="lastMonth"
                                name="default-radio"
                                checked={selectedFilter === "lastMonth"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="lastMonth" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Last month</span>({getLastMonthDate().toLocaleDateString()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="thisYear"
                                type="radio"
                                value="thisYear"
                                name="default-radio"
                                checked={selectedFilter === "thisYear"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="thisYear" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">This year</span>({getCurrentYear()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="lastYear"
                                type="radio"
                                value="lastYear"
                                name="default-radio"
                                checked={selectedFilter === "lastYear"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="lastYear" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Last year</span>({getLastYear()})
                              </label>
                            </div>
                            <div className="flex items-center gap-x-2">
                              <input
                                id="custom"
                                type="radio"
                                value="custom"
                                name="default-radio"
                                checked={selectedFilter === "custom"}
                                onChange={handleRadioChange}
                                className="text-radioTextColor focus:ring-radioTextColor h-4 w-4 border-gray-300 bg-gray-100 focus:ring-2"
                              />
                              <label htmlFor="custom" className="ms-2 text-radioTextColor text-sm font-medium">
                                <span className="font-semibold">Custom</span>
                              </label>
                            </div>
                          </div>
                          {selectedFilter === "custom" && (
                            <div className="flex space-x-2">
                              <div className="space-y-2">
                                <label className="pl-2 text-sm font-medium text-primary-color-100">Start Date</label>
                                <input
                                  id="startDate"
                                  type="date"
                                  name="startDate"
                                  value={dateRange.startDate ? new Date(dateRange.startDate * 1000).toISOString().substr(0, 10) : ""}
                                  className="h-10 w-full rounded-full border px-6 py-3 text-sm font-normal text-primary-color-200 placeholder:text-primary-color-100"
                                  onChange={handleCustomDateChange}
                                />
                              </div>
                              <div className="space-y-2">
                                <label className="pl-2 text-sm font-medium text-primary-color-100">End Date</label>
                                <input
                                  id="endDate"
                                  type="date"
                                  name="endDate"
                                  min={dateRange.startDate ? new Date(dateRange.startDate * 1000).toISOString().substr(0, 10) : ""}
                                  value={dateRange.endDate ? new Date(dateRange.endDate * 1000).toISOString().substr(0, 10) : ""}
                                  className="h-10 w-full rounded-full border px-6 py-3 text-sm font-normal text-primary-color-200 placeholder:text-primary-color-100"
                                  onChange={handleCustomDateChange}
                                />
                              </div>
                            </div>
                          )}
                          {dateError && <div className="mt-2 text-sm text-red-500">{dateError}</div>}
                        </div>
                      </div>
                      <div className="absolute right-0 bottom-6 flex w-full flex-col items-center space-y-4 px-4 sm:px-6">
                        <button
                          type="submit"
                          className="flex h-12 w-full items-center justify-center rounded-full bg-secondary bg-cover text-lg font-semibold text-white transition-all hover:bg-secondary/80 focus:outline-none focus:ring-offset-slate-50 3xl:h-14"
                          onClick={handleApplyFilters}
                        >
                          <span>Apply Filters</span>
                        </button>
                        <button
                          onClick={() => {
                            clearFilter();
                            closeModal();
                            // clearDraweFilters(setSelectedFilter,setSelectedCampus,setSelectedDepartment)
                          }}
                          type="button"
                          className="group flex h-12 w-full items-center justify-center gap-x-2 rounded-full border-2 border-red-500 bg-transparent text-lg font-semibold text-red-500 transition-all hover:bg-red-500 hover:text-white focus:outline-none focus:ring-offset-slate-50 3xl:h-14"
                        >
                          <XMarkIcon className="h-6 w-6 text-red-500 group-hover:text-white" /> Clear filter
                        </button>
                      </div>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Drawer;
