// ===================== Login Routes ========================== //
export const login = "login".toLowerCase();

export const forgotPassword = "forgot-password".toLowerCase();
export const resetPassword = "reset-password/:token".toLowerCase();

export const logout = "login".toLowerCase();

// ===================== Dashboard Routes ========================== //

export const dashboard = "dashboard".toLowerCase();
export const settings = "settings".toLowerCase();
export const contactBook = "contact-book".toLowerCase();
export const causeForConcern = "cause-for-concern".toLowerCase();
export const supportTickets = "support-tickets".toLowerCase();
export const users = "users".toLowerCase();
export const payments = "payments".toLowerCase();
export const subscriptionsPlans = "subscriptions-plans".toLocaleLowerCase();
export const employees = "employees".toLowerCase();
export const admins = "admins".toLowerCase();
export const notifications = "notifications".toLowerCase();
export const reports = "reports".toLowerCase();
export const profile = "profile".toLocaleLowerCase();
export const employeeDetail = "employees/:id".toLocaleLowerCase();
export const recentEvaluations = "recent-evaluations".toLocaleLowerCase();
export const campuses = "campuses".toLocaleLowerCase()
export const department = "deparment".toLocaleLowerCase()
