import ApiServices from "../api/services";
import Spinner from "./Spinner";
import camera from "../assets/img/camera.png";
import { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { employeeMediaUrl, mediaUrl } from "../utils/config";

const UpdateProfileModal = ({ isOpen, setIsOpen }) => {
  const { updateUser, user } = useLoggedContext();

  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileError, setSelectedFileError] = useState("");

  const [imgFromServer, setImgFromServer] = useState("");

  // Validation function for the name input
  const validateName = () => {
    if (!name.trim()) {
      setNameError("Required");

      return false;
    }
    setNameError("");
    return true;
  };

  const validateSelectedFile = () => {
    if (!selectedFile && !imgFromServer) {
      setSelectedFileError("Please upload an image");
      return false;
    }
    setSelectedFileError("");
    return true;
  };
  
  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
    setName("");
    setNameError("");
    setSelectedFile(null);
    setSelectedFileError("");
    setLoading(false);
  };

  // Save function
  const handleSave = () => {
    const isNameValid = validateName();
    const isSelectedFileValid = validateSelectedFile();

    if (isNameValid && isSelectedFileValid) {
      let formData = new FormData();
      formData.append("name", name);
      if (selectedFile instanceof File) {
        formData.append("profile_image_url", selectedFile);
      }

      setLoading(true);
      ApiServices.profile
        .updateProfile(formData)
        .then((res) => {
          const { data, message } = res;
          if (data.code === 200) {
            updateUser(data.data);
            setLoading(false);
            closeModal();
          }
        })
        .catch((err) => {
           
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    setName(user?.user?.employee[0]?.name);
    setImgFromServer(`${employeeMediaUrl}${user?.user?.employee[0]?.profile_image_url}`);
  }, [isOpen]);

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duratio  n-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-lg overflow-hidden rounded-2xl bg-white p-8 shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title className="text-left font-inter text-lg font-medium leading-7 text-secondary">Edit Profile</Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="text-info-color h-8 w-8 cursor-pointer" />
                  </div>
                  <div className="grid grid-cols-2 gap-4 text-center">
                    <div className="col-span-2 mx-auto my-5">
                      <div className="relative ">
                        <div className="h-40 w-40 rounded-full">
                          {selectedFile ? (
                            <>
                              <img src={URL.createObjectURL(selectedFile)} className="h-full w-full rounded-full " alt="Upload Image" />
                            </>
                          ) : (
                            <>
                              <img src={imgFromServer} className="h-full w-full rounded-full" alt="Upload Image" />
                            </>
                          )}
                        </div>
                        <div className="absolute -bottom-4 -right-5">
                          <input
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={(e) => {
                              setSelectedFile(e.target.files[0]);
                              setSelectedFileError("");
                            }}
                            style={{ display: "none" }}
                            id="imageInput"
                          />
                          <label htmlFor="imageInput">
                            <img src={camera} alt="Camera Icon" className="cursor-pointer" />
                          </label>
                        </div>
                        <div></div>
                      </div>
                      {selectedFileError && <p className="col-span-2 mt-3 text-sm text-red-500">{selectedFileError}</p>}
                    </div>
                  </div>
                  <div className="mt-5 text-left">
                    <label className="text-[#818181]">
                      Name<span className="text-red-500">*</span> {nameError && <span className="text-sm text-red-500">{nameError}</span>}
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => {
                        setName(e.target.value);
                        setNameError("");
                      }}
                      className={`text-md text-grayColor500 mt-2 mb-6 w-full rounded-full border border-grayColor100 p-3 px-5 leading-normal outline-none transition duration-150 ease-in-out md:p-3.5 md:px-6 lg:mb-8 lg:text-base`}
                      placeholder="Enter your name"
                      autoComplete="off"
                      required
                      onkeypress="return event.charCode != 32"
                    />
                  </div>
                  <div className="mt-6 flex gap-x-6 px-8">
                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-full border border-transparent bg-secondary px-4 py-2.5 text-base font-medium text-white hover:opacity-75 focus:outline-none`}
                      onClick={handleSave}
                    >
                      {loading ? <Spinner /> : "Save"}
                    </button>

                    <button
                      type="button"
                      className={`inline-flex w-full justify-center rounded-full border border-secondary bg-transparent px-4 py-2.5 text-base font-medium text-secondary transition-all duration-300 hover:bg-secondary hover:text-white focus:outline-none`}
                      onClick={closeModal}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default UpdateProfileModal;
