import { useState, useLayoutEffect, useRef, useEffect } from "react";

// ===================== React Router DOM ======================== //
import { NavLink } from "react-router-dom";

// ===================== Scroll ======================== //
import { scroll } from "../helperFunctions/scroll";

// ===================== Routes ======================== //
import routes from "../routes/sidebar";

// ===================== Context ======================== //
import { useLoggedContext } from "../context/provider";

// ===================== Admin Navbar ======================== //
import AdminNavbar from "../components/AdminNavbar";
import ConfirmationModal from "./ConfirmationModal";
import NotificationCircle from "./NotificationCircle";

import ApiServices from "../api/services";

export default function Sidebar({ logout }) {
  const ref = useRef(null);

  const { logOut, showSidebar, setShowSidebar, showBackDrop, setShowBackDrop, user } = useLoggedContext();

  const [logoutAlert, setLogoutAlert] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(0);
  const [loading, setLoading] = useState(false);

  const [pendingCount, setPendingCount] = useState(0);

  // Logout Modal
  const showLogout = (e) => {
    e.preventDefault();
    setLogoutAlert(true);
    scroll(false);
  };

  // Handle Logout
  const handleLogout = () => {
    setLogoutAlert(false);
    scroll(true);
    setLoading(true);
    logOut();
  };

  // Use Layout Effects
  useLayoutEffect(() => {
    setSidebarWidth(ref.current.offsetWidth);
  }, []);

  // Use Effects
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowSidebar(false);
        setShowBackDrop(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const filteredRoutes = routes.filter((route) => {
    const d1 = user?.user?.permissions?.includes("dashboard-view-audit-report");
    const d2 = user?.user?.permissions?.includes("dashboard-view-recent-responses");
    const d3 = user?.user?.permissions?.includes("dashboard-view-pending-forms");

    const e1 = user?.user?.permissions?.includes("employee-add-edit-delete");
    const e2 = user?.user?.permissions?.includes("employee-invite-users");
    const e3 = user?.user?.permissions?.includes("employee-notify-user");
    const e4 = user?.user?.permissions?.includes("employee-see-user-detail");
    const e5 = user?.user?.permissions?.includes("employee-mark-as-mental-health-absence");
    const e6 = user?.user?.permissions?.includes("employee-add-aciton-logs");
    const e7 = user?.user?.permissions?.includes("employee-view-submitted-forms");

    const a = user?.user?.permissions?.includes("admins-make-remove");

    const n = user?.user?.permissions?.includes("broadcast-notification-send");

    const t1 = user?.user?.permissions?.includes("support-ticket-create-new-ticket");
    const t2 = user?.user?.permissions?.includes("support-ticket-view-ticket");
    const t3 = user?.user?.permissions?.includes("support-ticket-respond-ticket");
    const t4 = user?.user?.permissions?.includes("support-ticket-change-status");

    const c1 = user?.user?.permissions?.includes("constact-book-add-new-contact");
    const c2 = user?.user?.permissions?.includes("contact-book-edit-delete");

    const ca1 = user?.user?.permissions?.includes("cause-for-concern-employee-with-multiple-low-check-ins");
    const ca2 = user?.user?.permissions?.includes("cause-for-concern-notify-user");

    const r1 = user?.user?.permissions?.includes("report-generate");
    const r2 = user?.user?.permissions?.includes("report-download-share");
    const r3 = user?.user?.permissions?.includes("report-view-history");

    const p1 = user?.user?.permissions?.includes("profile-edit");
    const p2 = user?.user?.permissions?.includes("profile-update-payment-method");
    const p3 = user?.user?.permissions?.includes("profile-change-plan");
    const p4 = user?.user?.permissions?.includes("profile-cancel-auto-renewal");
    const p5 = user?.user?.permissions?.includes("profile-view-billing-history");

    const c = user?.user?.permissions?.includes("campus");

    const d = user?.user?.permissions?.includes("department");

    if (route.path === "dashboard") {
      return d1 || d2 || d3 || user?.user?.role === "setting_user";
    }

    if (route.path === "employees") {
      return e1 || e2 || e3 || e4 || e5 || e6 || e7 || user?.user?.role === "setting_user";
    }
    if (route.path === "contact-book") {
      return c1 || c2 || user?.user?.role === "setting_user";
    }
    if (route.path === "admins") {
      return a || user?.user?.role === "setting_user";
    }
    if (route.path === "notifications") {
      return n || user?.user?.role === "setting_user";
    }
    if (route.path === "support-tickets") {
      return t1 || t2 || t3 || t4 || user?.user?.role === "setting_user";
    }
    if (route.path === "reports") {
      return r1 || r2 || r3 || user?.user?.role === "setting_user";
    }
    if (route.path === "profile") {
      return p1 || p2 || p3 || p4 || p5 || user?.user?.role === "setting_user";
    }
    if (route.path === "cause-for-concern") {
      return ca1 || ca2 || user?.user?.role === "setting_user";
    }

    if (route.path === "campuses") {
      return c || c || user?.user?.role === "setting_user";
    }
    if (route.path === "deparment") {
      return d || d || user?.user?.role === "setting_user";
    }
    return true;
  });

  return (
    <>
      {showBackDrop && <div class="absolute left-0 top-0 z-10 h-full w-full bg-black/50"></div>}
      <AdminNavbar
        sidebarWidth={sidebarWidth}
        setSidebarWidth={setSidebarWidth}
        setShowSidebar={setShowSidebar}
        showSidebar={showSidebar}
        setShowBackDrop={setShowBackDrop}
      />
      <div
        className={`fixed top-[10vh] h-screen bg-white lg:left-0 lg:bg-transparent 2xl:top-[8vh] ${showSidebar === "left-0" ? "left-0 " : "-left-64"} z-10 w-64 flex-row flex-nowrap overflow-hidden  overflow-y-auto shadow-card transition-all duration-300 md:w-56 md:shadow-none 2xl:w-64`}
        id="sidebar"
        ref={ref}
      >
        <div className="relative min-h-full flex-col flex-nowrap items-stretch px-0">
          <div className="flex flex-col py-8 pr-5 md:pr-0 2xl:py-10">
            <h5 className="d-flex w-full text-xl font-bold uppercase tracking-wider text-primary"></h5>
            <ul className="flex min-w-full list-none flex-col">
              {filteredRoutes?.map((route) => (
                <li className="rouned-full group mb-3 2xl:mb-4">
                  <NavLink
                    key={route.id}
                    to={route.path}
                    className={`flex items-center rounded-r-full py-2 pl-5 text-[12px] ${route.path === logout ? "underline-offset-4 group-hover:underline" : "group-hover:bg-gray-300 "} font-normal text-grayColor300 transition-all duration-200 2xl:text-[14px]`}
                  >
                    {route.icon !== "" && (
                      <img
                        src={route.icon}
                        alt="user group"
                        className={`${route.id === "6" || route.id === "9" ? "h-6 w-6" : "h-5 w-5"} mr-2  object-contain lg:mr-3 2xl:mr-4`}
                      />
                    )}

                    <p className={`font-normal ${route.id === "6" || route.id === "9" ? "-ml-1" : "p-0"}  text-[12px] 2xl:text-[14px]`}>
                      {route.name}
                    </p>
                  </NavLink>
                </li>
              ))}
            </ul>

            {/* Logout Modal */}
            <ConfirmationModal label="Sign Out" isOpen={logoutAlert} setIsOpen={setLogoutAlert} handleSubmit={handleLogout} />
          </div>
        </div>
      </div>
    </>
  );
}
