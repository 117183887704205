import Tabs from "./Tabs";
import Button from "./Button";
import Lottie from "react-lottie";
import ApiServices from "../api/services";
import Skeleton from "react-loading-skeleton";
import { Fragment, useState, useEffect } from "react";
import SubscriptionWidget from "./SubscriptionWidget";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { Dialog, RadioGroup, Transition } from "@headlessui/react";
import ChangeCardModal from "./ChangeCardModal";
import ConfirmationModal from "./ConfirmationModal";

// tabs
const tabs = [{ label: "Monthly" }, { label: "Yearly" }];

const SubscriptionPlansModal = ({ isOpen, setIsOpen, onSuccess, fromProvider, setSubPlanId, data }) => {
  // Context
  const { btnLoading, setBtnLoading, setLoading, openSuccessModal, loading, user, logOut } = useLoggedContext();

  // useStates
  const [message, setMessage] = useState("");
  const [selected, setSelected] = useState(null);
  const [subscriptionListing, setSubscriptionListing] = useState([]);
  const [activeSubscriptionPlanTab, setActiveSubscriptionPlanTab] = useState(tabs[0].label);
  const [logoutAlert, setLogoutAlert] = useState(false);

  const [openChangeCardModal, setOpenChangeCardModal] = useState(false);

  // Close Modal
  const closeModal = () => {
    setIsOpen(false);
    setMessage("");
  };

  // Get Subscription List
  const getSubscriptionList = () => {
    setLoading(true);

    ApiServices.subscriptionPlans
      .getSubscriptionPlans()
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setLoading(false);
          setSubscriptionListing(data?.data);
        } else {
          setLoading(false);
          setSubscriptionListing([]);
        }
      })
      .catch((err) => {
         
      });
  };

  // Send Selected Subscribe Plan
  const SendSubscribedPlan = () => {
    if (fromProvider) {
      setSubPlanId(selected?.id);
      setOpenChangeCardModal(true);
    } else {
      const formData = new FormData();

      formData.append("plan_id", selected?.id);

      setBtnLoading(true);

      ApiServices.subscriptionPlans
        .profileSubscribedPlan(formData)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            setBtnLoading(false);
            closeModal();
            setMessage("");
            onSuccess();
            openSuccessModal({
              title: "Success!",
              message: "Congratulations! Your Plan has been updated!",
              onPress: (close) => {
                close();
              },
            });
          }
        })
        .catch((err) => {
          setMessage(err?.response?.data?.message);
          setBtnLoading(false);
        });
    }
  };

  // Use Effects
  useEffect(() => {
    getSubscriptionList();
  }, [isOpen]);

  useEffect(() => {
    if (data) {
      const selectedPlan = subscriptionListing.find((plan) => plan.id === data);
      setSelected(selectedPlan);
    }
  }, [data, subscriptionListing]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

   

  // Handle Logout
  const handleLogout = () => {
    setIsOpen(false);
    setLogoutAlert(false);
    logOut();
  };

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-3xl overflow-hidden rounded-2xl bg-white p-8 text-center align-middle shadow-xl transition-all">
                  <div>
                    <div className="space-y-12">
                      <div>
                        <h2 className="text-left text-2xl font-semibold text-secondary-color 3xl:text-28">Subscription Plans</h2>
                        <div className="flex items-center justify-between">
                          <h2 className="text-base text-grayColor300 3xl:text-lg">Choose the plan that’s right for you</h2>
                          <Tabs tabs={tabs} setActiveTab={setActiveSubscriptionPlanTab} activeTab={activeSubscriptionPlanTab} />
                        </div>
                      </div>

                      <RadioGroup value={selected} onChange={setSelected}>
                        <div className="grid grid-cols-3 items-center justify-center gap-6">
                          {loading ? (
                            <>
                              <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                              <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                              <Skeleton count={1} height={220} style={{ borderRadius: "10px" }} />
                            </>
                          ) : subscriptionListing && subscriptionListing.length > 0 ? (
                            subscriptionListing
                              .filter((plan) => {
                                const planType = plan?.type?.toLowerCase();
                                if (activeSubscriptionPlanTab.toLowerCase() === "monthly") {
                                  return planType === "monthly";
                                } else if (activeSubscriptionPlanTab.toLowerCase() === "yearly") {
                                  return planType === "yearly";
                                }
                                return planType === "monthly";
                              })
                              .map((selectedPlan) => (
                                <RadioGroup.Option key={selectedPlan.name} value={selectedPlan}>
                                  {({ checked }) => (
                                    <SubscriptionWidget
                                      checked={checked}
                                      planName={selectedPlan?.name}
                                      planType={selectedPlan?.type}
                                      users={`${selectedPlan?.user_count_min}-${selectedPlan?.user_count_max}`}
                                      perUser={selectedPlan?.per_user}
                                      price={selectedPlan?.price}
                                      discount={
                                        activeSubscriptionPlanTab === "Yearly" && selectedPlan?.name !== "Basic"
                                          ? selectedPlan?.discount_percentage
                                          : null
                                      }
                                    />
                                  )}
                                </RadioGroup.Option>
                              ))
                          ) : (
                            <Lottie options={emptyFolderAnimation} width={200} height={100} />
                          )}
                        </div>
                      </RadioGroup>
                    </div>

                    {/* Error Message */}
                    <div className="py-8 text-center">
                      <p className="text-sm font-medium text-red-500">{message}</p>
                    </div>
                    {/* End Error Message */}

                    <div className="mt-16 flex items-center justify-end gap-x-6">
                      {loading ? (
                        <Skeleton count={1} height={50} width={300} />
                      ) : (
                        <>
                          {user?.user?.subscribed !== 0 && (
                            <h3 onClick={closeModal} className="cursor-pointer text-base font-medium text-secondary 3xl:text-lg">
                              Cancel
                            </h3>
                          )}
                          {user?.user?.subscribed == 0 && (
                            <h3 onClick={() => setLogoutAlert(true)} className="cursor-pointer text-base font-medium text-red-500 3xl:text-lg ">
                              Sign Out
                            </h3>
                          )}
                          <Button
                            title="Proceed"
                            loading={btnLoading}
                            className={selected === null ? "cursor-not-allowed opacity-50" : "opacity-full cursor-pointer"}
                            onClick={selected === null ? null : SendSubscribedPlan}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <ChangeCardModal isOpen={openChangeCardModal} selected={selected} setIsOpen={setOpenChangeCardModal} getProfile={() => {}} />

      <ConfirmationModal label="Sign Out" isOpen={logoutAlert} setIsOpen={setLogoutAlert} handleSubmit={handleLogout} />
    </>
  );
};

export default SubscriptionPlansModal;
