import moment from "moment";
import Spinner from "./Spinner";
import Heading from "./Heading";
import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import { XMarkIcon } from "@heroicons/react/20/solid";

export default function CauseForConcernModal({ isOpen, setIsOpen, data }) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-2xl space-y-8 overflow-hidden rounded-2xl bg-white p-8 text-center align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <div>
                      <Heading heading="Reasons:" />
                    </div>
                    <div onClick={closeModal}>
                      <XMarkIcon className="h-7 w-7 cursor-pointer text-secondary-color" />
                    </div>
                  </div>

                  <div className="-mr-2 max-h-[70vh] space-y-8 divide-y overflow-auto pr-2">
                    {data?.user_response.map((item, index) => (
                      <div className="space-y-8 pt-8">
                        <div className="flex items-center gap-x-40">
                          <div className="col-span-3">
                            <h2 className="text-base text-grayColor300">Date:</h2>
                          </div>
                          <div>
                            <h2 className="text-lg font-medium text-secondary">{moment.unix(item?.created_date_unix).format("D/MM/YYYY")}</h2>
                          </div>
                        </div>
                        <div className="flex items-center gap-x-[7.2rem]">
                          <div>
                            <h2 className="text-base text-grayColor300">Response: </h2>
                          </div>
                          <div className="flex items-center gap-x-2">
                            <img
                              src={
                                item?.response === "Great"
                                  ? images.GreatIcon
                                  : item?.response === "Okay"
                                    ? images.OkayIcon
                                    : item?.response === "Meh"
                                      ? images.MehIcon
                                      : item?.response === "Low"
                                        ? images.LowIcon
                                        : item?.response === "Struggling"
                                          ? images.StugglingIcon
                                          : images.placeholder
                              }
                              alt="logo"
                              className="h-6 w-6"
                            />
                            <h2 className="text-lg font-medium text-secondary">{item?.response}</h2>
                          </div>
                        </div>
                        <div className="flex items-center gap-x-[8.4rem]">
                          <div>
                            <h2 className="text-base text-grayColor300">Reason:</h2>
                          </div>
                          <div className=" flex items-center  gap-x-2">
                            <img
                              src={
                                item?.reason === "Relationship Problems"
                                  ? images.RelationIcon
                                  : item?.reason === "Poor Sleep"
                                    ? images.SleepIcon
                                    : item?.reason === "Unwell"
                                      ? images.UnWellIcon
                                      : item?.reason === "Work Related Stress"
                                        ? images.StressIcon
                                        : item?.reason === "Bereavement"
                                          ? images.BereavementIcon
                                          : item?.reason === "Home Life Difficulties"
                                            ? images.HomeLifeIcon
                                            : item?.reason === "Mental Health Rest Day"
                                              ? images.MentleHealth
                                              : images.placeholder
                              }
                              alt="logo"
                              className="h-6 w-6"
                            />
                            <h2 className="text-lg font-medium text-secondary">{item?.reason}</h2>
                          </div>
                        </div>
                        <div>
                          <h2 className="text-left text-base text-grayColor300">Additional Detail: </h2>
                          <p className="text-left text-base font-medium text-secondary">{item?.reason_detail || "N/A"}</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
