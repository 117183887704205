import {
  dashboard,
  supportTickets,
  employees,
  admins,
  notifications,
  reports,
  profile,
  causeForConcern,
  contactBook,
  recentEvaluations,
  campuses,
  department
} from "./routesDefined";

import { images } from "../assets/styles/Images";

const routes = [
  {
    id: "1",
    path: dashboard,
    icon: images.Dashboard_Icon,
    name: "Dashboard",
  },
  {
    id: "2",
    path: profile,
    icon: images.Subscription_Icon,
    name: "Profile",
  },
  {
    id: "3",
    path: campuses,
    icon: images.Campus_Icon,
    name: "Campuses",
  },
  {
    id: "4",
    path: department,
    icon: images.Department_Icon,
    name: "Departments",
  },
  {
    id: "5",
    path: admins,
    icon: images.Users_Icon,
    name: "Admins",
  },
  {
    id: "6",
    path: employees,
    icon: images.Employee_Icon,
    name: "Employees",
  },
  {
    id: "7",
    path: causeForConcern,
    icon: images.Settings_Icon,
    name: "Cause For Concern",
  },
  {
    id: "8",
    path: reports,
    icon: images.Report_Icon,
    name: "Reports",
  },
  {
    id: "9",
    path: recentEvaluations,
    icon: images.Evaluation,
    name: "Recent Evaluations",
  },

  {
    id: "10",
    path: contactBook,
    icon: images.ContactBook,
    name: "Referral List",
  },

  {
    id: "11",
    path: notifications,
    icon: images.Notification_Icon,
    name: "Broadcast Notifications",
  },
  {
    id: "12",
    path: supportTickets,
    icon: images.SupportTicket_Icon,
    name: "Support Tickets",
  },


  {
    id: "11",
    path: "",
    icon: "",
    name: "",
  },
];

export default routes;
