// components/CaseStudiesTabs.js
import React, { useState, useEffect } from "react";

const Tabs = ({ tabs, onTabChange, ticketTab, activeTab, setActiveTab }) => {
  const handleTabClick = (label) => {
    setActiveTab(label);
    // onTabChange(label);
  };

  return (
    <div className={`grid ${ticketTab ? "grid-cols-3" : "grid-cols-2"}  grid-flow-row`}>
      {tabs.map((tab, index) => (
        <div className="flex flex-col" key={tab.label}>
          <div
            className={`md:text-base text-10  flex cursor-pointer items-center justify-center py-3 px-3 md:min-w-[90px] md:py-1.5 ${
              tab.label === activeTab ? "text-secondary " : " text-grayColor200 transition-all duration-300 hover:text-secondary "
            }`}
            onClick={() => handleTabClick(tab.label)}
          >
            {ticketTab ? (
              tab.label === activeTab ? (
                <>
                  <img src={tab.activeIcon} className="pr-2" alt={tab.label} />
                  {tab.label}
                </>
              ) : (
                <>
                  <img src={tab.icon} className="pr-2" alt={tab.label} />
                  {tab.label}
                </>
              )
            ) : (
              tab.label
            )}
          </div>
          <div
            className={`rounded-full border-b-4 ${tab.label === activeTab ? "border-secondary" : "border-grayColor200"} ${
              tab.label === activeTab ? (activeTab === "Monthly" ? "border-animation-right" : "border-animation-left") : ""
            }`}
          ></div>
        </div>
      ))}
    </div>
  );
};

export default Tabs;
