import moment from "moment";
import Lottie from "react-lottie";
import { useEffect } from "react";
import React, { useState } from "react";
import { Tab } from "@headlessui/react";
import ApiServices from "../api/services";
import Button from "../components/Button";
import { baseUrl } from "../utils/config";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import ReactDatePicker from "react-datepicker";
import { useMediaQuery } from "react-responsive";
import RangePicker from "../components/RangePicker";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import reportAnimationData from "../assets/lotties/report";
import { useSortableData } from "../hooks/useSortableData";
import ShareEmailModal from "../components/ShareEmailModal";
import { Page, Text, View, Document, PDFDownloadLink, pdf, StyleSheet, Image } from "@react-pdf/renderer";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon } from "@heroicons/react/24/solid";
import Spinner from "../components/Spinner";
import ReportTable from "../components/ReportTable";
import { toUTCUnixTimestamp } from "../helperFunctions/helper";
import { images } from "../assets/styles/Images";
import { HonestWeekPicker } from "../components/WeekDatePicker";
import { useRef } from "react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

// table head
const TABLE_HEAD_HISTORY = ["ID", "Date", "Action"];

const tabs = ["Yearly", "Monthly", "Single Month", "Weekly", "Daily"];

const Reports = () => {
  const { btnLoading, setBtnLoading, loading, setLoading, user } = useLoggedContext();
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const datePickerRef = useRef(null); // Create a ref for the DatePicker

  const [openShareModal, setOpenShareModal] = useState(false);
  const [historyListing, setHistoryListing] = useState([]);
  const [totalPageAllHistory, setTotalPageAllHistory] = useState(0);
  const [currentPageAllHistory, setCurrentPageAllHistory] = useState(1);
  const [saveHistoryLoading, setSaveHistoryLoading] = useState(false);

  // Yearly
  const [yearly, setYearly] = useState("");
  const [yearTimestamps, setYeartimestamps] = useState({ startdate: null, enddate: null });
  // Multiple Months
  const [startMonth, setStartMonth] = useState("");
  const [endMonth, setEndMonth] = useState("");
  // Single Month
  const [singleMonth, setSingleMonth] = useState("");
  // Weekly
  const [weekly, setWeekly] = useState("");
  // Daily
  const [dailyDate, setDailyDate] = useState("");
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const [startEndMonthTimestamps, setStartEndMonthTimestamps] = useState({ startdate: null, enddate: null });
  const [singleMonthTimestamps, setSingleMonthTimestamps] = useState({ startdate: null, enddate: null });

  const [reportData, setReportData] = useState({});
  const [reportDataLoading, setReportDataLoading] = useState(false);

  // Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(historyListing);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 5 : isLaptopMedium ? 6 : isLaptop ? 6 : 10;

  // Pagination
  const handleReportsHisttoryChange = ({ selected }) => {
    setCurrentPageAllHistory(selected + 1);
  };

  const [selectedTabIndex, setSelectedTabIndex] = useState("yearly");

  const handleTabChange = (index) => {
    setSelectedTabIndex(index);
    const clickedTabLabel = tabs[index];

    setSelectedTabIndex(clickedTabLabel);

    setYearly(null);
    setStartMonth(null);
    setEndMonth(null);
    setSingleMonth(null);
    setWeekly(null);
    setDailyDate(null);
  };

  // const onChange = (week) => {
  //   setWeekly(week)
  // };

  const getReportData = () => {
    if (yearTimestamps.startdate || startEndMonthTimestamps.startdate || singleMonthTimestamps.startdate || weekly || dailyDate) {
      let payload = {
        group_by:
          selectedTabIndex === "Yearly"
            ? "yearly"
            : selectedTabIndex === "Monthly"
              ? "multiMonth"
              : selectedTabIndex === "Single Month"
                ? "monthly"
                : selectedTabIndex === "Weekly"
                  ? "weekly"
                  : selectedTabIndex === "Daily"
                    ? "daily"
                    : selectedTabIndex,
      };

      switch (selectedTabIndex) {
        case "yearly":
          payload.start_date = yearTimestamps?.startdate;
          payload.end_date = yearTimestamps?.enddate;
          break;
        case "Yearly":
          payload.start_date = yearTimestamps?.startdate;
          payload.end_date = yearTimestamps?.enddate;
          break;
        case "Monthly":
          payload.start_date = startEndMonthTimestamps?.startdate;
          payload.end_date = startEndMonthTimestamps?.enddate;
          break;
        case "Single Month":
          payload.start_date = singleMonthTimestamps?.startdate;
          payload.end_date = singleMonthTimestamps?.enddate;
          break;
        case "Weekly":
          payload.start_date = localToGMTUnixTimestamp(weekly?.firstDay);
          payload.end_date = localToGMTUnixTimestamp(weekly?.lastDay);
          break;
        case "Daily":
          payload.start_date = toUTCUnixTimestampfunction(dailyDate);
          payload.end_date = toUTCUnixTimestampfunctionEndDate(dailyDate);
          break;

        default:
          // Handle other cases if needed
          break;
      }

      setReportDataLoading(true);

      ApiServices.report
        .getReport(payload)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            setReportDataLoading(false);
            setReportData(data?.data);
          }
        })
        .catch((err) => {
           
        });
    }
  };

  function localToGMTUnixTimestamp(localDateString) {
    // Create a Date object from the local date string
    const localDateTime = new Date(localDateString);

    // Convert local time to GMT
    const gmtDateTime = new Date(localDateTime.getTime() - localDateTime.getTimezoneOffset() * 60000);

    // Set time to 00:00
    gmtDateTime.setUTCHours(0);
    gmtDateTime.setUTCMinutes(0);
    gmtDateTime.setUTCSeconds(0);
    gmtDateTime.setUTCMilliseconds(0);

    // Convert GMT time to Unix timestamp
    const unixTimestamp = Math.floor(gmtDateTime.getTime() / 1000);

    return unixTimestamp;
  }

  const getHistoryData = () => {
    let payload = {
      page: currentPageAllHistory,
      records_no: 5,
      start_date: dateRange.startDate,
      end_date: dateRange.endDate,
    };

    setLoading(true);

    ApiServices.report
      .getHistoryList(payload)
      .then((res) => {
        const { data, message } = res;

        if (data?.code === 200) {
          setLoading(false);
          setHistoryListing(data?.data?.data);
          setCurrentPageAllHistory(data?.data?.current_page);
          setTotalPageAllHistory(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  useEffect(() => {
    getHistoryData();
  }, [currentPageAllHistory, dateRange]);

  const handleView = (item) => {
    window.open(`${baseUrl}images/report_pdf/` + item?.file, "_blank");
  };

  const handleDownload = (item) => {
    window.open(`${baseUrl}images/report_pdf/` + item?.file);
  };

  function stripHtml(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  // reasons table percentage
  const poorSleepPercentage = reportData?.ReasonPercentage?.poorSleep_percentage;
  const relationshipProblemsPercentage = reportData?.ReasonPercentage?.relationship_problems_percentage;
  const unwellPercentage = reportData?.ReasonPercentage?.unwell_percentage;

  // reasons table count
  const poorSleepTotalCount = reportData?.ReasonPercentage?.poorSleepTotalCount;
  const relationshipProblemsCount = reportData?.ReasonPercentage?.relationshipProblemsCount;
  const unwellTotalCount = reportData?.ReasonPercentage?.unwellTotalCount;

  // entry table count
  const greateOkValue = reportData?.ResponsePercentage?.greate_ok;
  const mehLowStrugglingValue = reportData?.ResponsePercentage?.meh_low_struggling;

  let highestEntryValue, lowestEntryValue;

  // Determine the highest,lowest and equal values for entry table count
  if (greateOkValue >= mehLowStrugglingValue) {
    highestEntryValue = greateOkValue;
    lowestEntryValue = mehLowStrugglingValue;
  } else {
    highestEntryValue = mehLowStrugglingValue;
    lowestEntryValue = greateOkValue;
  }

  const allEqualEntryValue = greateOkValue == mehLowStrugglingValue;

  // entry table percentage
  const greatOkPercentage = reportData?.ResponsePercentage?.greate_ok_percentage;
  const mehLowStrugglingPercentage = reportData?.ResponsePercentage?.meh_low_struggling_percentage;

  let highestEntryPercentage, lowestEntryPercentage;

  // Determine the highest,lowest and equal values for entry table percentage
  if (greatOkPercentage >= mehLowStrugglingPercentage) {
    highestEntryPercentage = greatOkPercentage;
    lowestEntryPercentage = mehLowStrugglingPercentage;
  } else {
    highestEntryPercentage = mehLowStrugglingPercentage;
    lowestEntryPercentage = greatOkPercentage;
  }

  const allEqualEntryPercentage = greatOkPercentage == mehLowStrugglingPercentage;

  const getColorClass = (value, allValues) => {
    const sortedValues = allValues.sort((a, b) => b - a);
    if (value === sortedValues[0]) {
      return [styles.tableCol, { backgroundColor: "#88FA4E" }]; // Green
    } else if (value === sortedValues[1]) {
      return [styles.tableCol, { backgroundColor: "#FED477" }]; // Yellow
    } else if (value === sortedValues[5]) {
      return [styles.tableCol, { backgroundColor: "#FF968D" }]; // Red
    } else {
      return [styles.tableCol];
    }
  };

  const getBackgroundColorClass = (value, allValues) => {
    const sortedValues = allValues.sort((a, b) => b - a);
    if (value === sortedValues[0]) {
      return [styles.tableCol, { backgroundColor: "#88FA4E" }]; // Green
    } else if (value === sortedValues[1]) {
      return [styles.tableCol, { backgroundColor: "#FED477" }]; // Yellow
    } else if (value === sortedValues[4]) {
      return [styles.tableCol, { backgroundColor: "#FF968D" }]; // Red
    } else {
      return [styles.tableCol];
    }
  };

  const counts = reportData?.Response?.map((row) => row.Count) || [];
  const maxCount = Math.max(...counts);
  const minCount = Math.min(...counts);

  const MyDocument = () => (
    <Document>
      <Page size="A3" style={styles.page}>
        <View style={[styles?.padding]}>
          <View style={[styles?.table]}>
            <View style={styles.tableRow}>
              <View style={[styles.colSpan7, styles.tableColHeader]}>
                <Text style={styles.tableCellHeader}>Entries</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  {reportData?.type === "daily"
                    ? "Hour"
                    : reportData?.type === "monthly"
                      ? "Date"
                      : reportData?.type === "weekly"
                        ? "weekly"
                        : "Month"}
                </Text>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/yFB8LST/great.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Great</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/ydf31w2/okay.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Okay</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/D9D9pwW/meh.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Meh</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/M2SnY2k/low.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Low</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/P16N2mQ/struggling.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Struggling</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>Entries</Text>
              </View>
            </View>

            {reportData?.Response?.map((row, index) => (
              <View key={index} style={index % 2 === 0 ? styles.tableRowEven : styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.type === "daily"
                      ? moment(row.hour).format("MMM D YYYY h:mm A ")
                      : reportData?.type === "monthly"
                        ? moment(row?.date).format("MMM D YYYY")
                        : reportData?.type === "weekly"
                          ? moment(row?.date).format("MMM D YYYY")
                          : row?.month}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.Great === null ? "0" : row.Great}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.Okay === null ? "0" : row.Okay}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.Meh === null ? "0" : row.Meh}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.Low === null ? "0" : row.Low}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row.Struggling === null ? "0" : row.Struggling}</Text>
                </View>
                <View
                  style={
                    row.Count === maxCount
                      ? [styles.tableCol, { backgroundColor: "#88FA4E" }]
                      : row.Count === minCount
                        ? [styles.tableCol, { backgroundColor: "#FF968D" }]
                        : styles.tableCol
                  }
                >
                  <Text style={styles.tableCell}>{row.Count}</Text>
                </View>
              </View>
            ))}

            <View style={styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.total]}>Total</Text>
              </View>
              <View
                style={getBackgroundColorClass(reportData?.ResponsePercentage?.greatTotal, [
                  reportData?.ResponsePercentage?.greatTotal,
                  reportData?.ResponsePercentage?.okTotal,
                  reportData?.ResponsePercentage?.mehTotal,
                  reportData?.ResponsePercentage?.lowTotal,
                  reportData?.ResponsePercentage?.strugglingTotal,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ResponsePercentage?.greatTotal}</Text>
              </View>
              <View
                style={getBackgroundColorClass(reportData?.ResponsePercentage?.okTotal, [
                  reportData?.ResponsePercentage?.greatTotal,
                  reportData?.ResponsePercentage?.okTotal,
                  reportData?.ResponsePercentage?.mehTotal,
                  reportData?.ResponsePercentage?.lowTotal,
                  reportData?.ResponsePercentage?.strugglingTotal,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ResponsePercentage?.okTotal}</Text>
              </View>
              <View
                style={getBackgroundColorClass(reportData?.ResponsePercentage?.mehTotal, [
                  reportData?.ResponsePercentage?.greatTotal,
                  reportData?.ResponsePercentage?.okTotal,
                  reportData?.ResponsePercentage?.mehTotal,
                  reportData?.ResponsePercentage?.lowTotal,
                  reportData?.ResponsePercentage?.strugglingTotal,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ResponsePercentage?.mehTotal}</Text>
              </View>
              <View
                style={getBackgroundColorClass(reportData?.ResponsePercentage?.lowTotal, [
                  reportData?.ResponsePercentage?.greatTotal,
                  reportData?.ResponsePercentage?.okTotal,
                  reportData?.ResponsePercentage?.mehTotal,
                  reportData?.ResponsePercentage?.lowTotal,
                  reportData?.ResponsePercentage?.strugglingTotal,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ResponsePercentage?.lowTotal}</Text>
              </View>
              <View
                style={getBackgroundColorClass(reportData?.ResponsePercentage?.strugglingTotal, [
                  reportData?.ResponsePercentage?.greatTotal,
                  reportData?.ResponsePercentage?.okTotal,
                  reportData?.ResponsePercentage?.mehTotal,
                  reportData?.ResponsePercentage?.lowTotal,
                  reportData?.ResponsePercentage?.strugglingTotal,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ResponsePercentage?.strugglingTotal}</Text>
              </View>
              <View style={styles.tableCol}></View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableColHidden}></View>
              <View
                style={
                  allEqualEntryValue
                    ? [styles.colSpan2, styles.tableColCenter, { backgroundColor: "#8EE1C8" }]
                    : lowestEntryValue === greateOkValue
                      ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#FF968D" }]
                      : [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                }
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ResponsePercentage?.greate_ok}</Text>
              </View>

              <View
                style={
                  allEqualEntryValue
                    ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                    : highestEntryValue === mehLowStrugglingValue
                      ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                      : [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#FF968D" }]
                }
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ResponsePercentage?.meh_low_struggling}</Text>
              </View>
              <View style={styles.tableColHidden}></View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHidden}></View>

              <View
                style={
                  allEqualEntryPercentage
                    ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                    : lowestEntryPercentage === greatOkPercentage
                      ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#FF968D" }]
                      : [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                }
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ResponsePercentage?.greate_ok_percentage}%</Text>
              </View>

              <View
                style={
                  allEqualEntryPercentage
                    ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                    : highestEntryPercentage === mehLowStrugglingPercentage
                      ? [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#88FA4E" }]
                      : [styles.colSpan3, styles.tableColCenter, { backgroundColor: "#FF968D" }]
                }
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ResponsePercentage?.meh_low_struggling_percentage}%</Text>
              </View>
              <View style={styles.tableColHidden}></View>
            </View>
          </View>
        </View>

        <View style={styles.padding}>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              <View style={[styles.colSpan7, styles.tableColHeader]}>
                <Text style={styles.tableCellHeader}>Reasons ( meh , low , struggling )</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableColHeader}>
                <Text style={styles.tableCellHeader}>
                  {reportData?.type === "daily"
                    ? "Hour"
                    : reportData?.type === "monthly"
                      ? "Date"
                      : reportData?.type === "weekly"
                        ? "weekly"
                        : "Month"}
                </Text>
              </View>

              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/zf9xC48/sleep.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Poor Sleep</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/4Ftv0cX/relation.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Relationships</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/x1Q5zvk/bereavement.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Bereavement</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/BwsgvVH/home.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Home Life</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/fCYy3XQ/unwell.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Unwell</Text>
                </View>
              </View>
              <View style={styles.tableColHeader}>
                <View style={styles.flexContainer}>
                  <Image src="https://i.ibb.co/wcmJq9H/stress.png" style={styles.image} />
                  <Text style={styles.tableCellHeader}>Work Related</Text>
                </View>
              </View>
            </View>

            {reportData?.Reason?.map((row, index) => (
              <View key={index} style={index % 2 === 0 ? styles.tableRowEven : styles.tableRow}>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>
                    {reportData?.type === "daily"
                      ? moment(row.hour).format("MMM D YYYY h:mm A ")
                      : reportData?.type === "monthly"
                        ? moment(row?.date).format("MMM D YYYY")
                        : reportData?.type === "weekly"
                          ? moment(row?.date).format("MMM D YYYY")
                          : row?.month}
                  </Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.poor_sleep === null ? "0" : row?.poor_sleep}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.relationship_problems === null ? "0" : row?.relationship_problems}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.bereavement === null ? "0" : row?.bereavement}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.home_life_difficulties === null ? "0" : row?.home_life_difficulties}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.unwell === null ? "0" : row?.unwell}</Text>
                </View>
                <View style={styles.tableCol}>
                  <Text style={styles.tableCell}>{row?.work_stress === null ? "0" : row?.work_stress}</Text>
                </View>
              </View>
            ))}

            <View style={styles.tableRowEven}>
              <View style={styles.tableCol}>
                <Text style={[styles.tableCell, styles.total]}>Total</Text>
              </View>
              <View
                style={getColorClass(poorSleepTotalCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.poorSleepTotalCount}</Text>
              </View>
              <View
                style={getColorClass(relationshipProblemsCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.relationshipProblemsCount}</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.bereavementTotalCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.bereavementTotalCount}</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount}</Text>
              </View>
              <View
                style={getColorClass(unwellTotalCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.unwellTotalCount}</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.workStressTotalCount, [
                  poorSleepTotalCount,
                  relationshipProblemsCount,
                  reportData?.ReasonPercentage?.bereavementTotalCount,
                  reportData?.ReasonPercentage?.homeLifeDifficultyTotalCount,
                  unwellTotalCount,
                  reportData?.ReasonPercentage?.workStressTotalCount,
                ])}
              >
                <Text style={[styles.tableCell, styles.percentage]}>{reportData?.ReasonPercentage?.workStressTotalCount}</Text>
              </View>
            </View>

            <View style={styles.tableRowEven}>
              <View style={styles.tableCol}></View>
              <View
                style={getColorClass(poorSleepPercentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.poorSleep_percentage}%</Text>
              </View>
              <View
                style={getColorClass(relationshipProblemsPercentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.relationship_problems_percentage}%</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.bereavement_percentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.bereavement_percentage}%</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.homeLifeDifficulty_percentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.homeLifeDifficulty_percentage}%</Text>
              </View>
              <View
                style={getColorClass(unwellPercentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.unwell_percentage}%</Text>
              </View>
              <View
                style={getColorClass(reportData?.ReasonPercentage?.workStress_percentage, [
                  poorSleepPercentage,
                  relationshipProblemsPercentage,
                  reportData?.ReasonPercentage?.bereavement_percentage,
                  reportData?.ReasonPercentage?.homeLifeDifficulty_percentage,
                  unwellPercentage,
                  reportData?.ReasonPercentage?.workStress_percentage,
                ])}
              >
                <Text style={[styles.tableCell, styles.value]}>{reportData?.ReasonPercentage?.workStress_percentage}%</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <View style={styles.tableRow}>
            <View style={styles.tableColHeader}>
              <Text style={styles.tableCellHeader}>Date</Text>
            </View>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader}>Evaluation of data</Text>
            </View>
            <View style={styles.tableColHeader2}>
              <Text style={styles.tableCellHeader}>Actions</Text>
            </View>
          </View>

          {reportData?.Evaluation?.map((row, index) => (
            <View key={index} style={index % 2 === 0 ? styles.tableRowEven : styles.tableRow}>
              <View style={styles.tableCol}>
                <Text style={styles.tableCell}>{moment.unix(row?.created_at_unix).format("D MMM YYYY")}</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{stripHtml(row?.evaluation)}</Text>
              </View>
              <View style={styles.tableCol2}>
                <Text style={styles.tableCell}>{stripHtml(row?.action)}</Text>
              </View>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );

  const uploadFile = async (pdfBlob) => {
    const formData = new FormData();
    formData.append("file", pdfBlob, "report.pdf");

    try {
      const res = await ApiServices.report.uploadReport(formData);

      if (res.code === 200) {
        return `${baseUrl}images/report_pdf/` + res.data;
      }
    } catch (err) {
      throw err; // Re-throwing the error for the caller to handle
    }
  };

  const sendEmailWithUrl = (url) => {
    const emailBody = `Report URL: ${url}`;
    window.location.href = `mailto:?body=${encodeURIComponent(emailBody)}`;
  };

  const generatePDFBlob = async () => {
    try {
      const doc = <MyDocument />; // Replace with your document component
      const asPDF = pdf([]); // Create an empty PDF placeholder
      asPDF.updateContainer(doc); // Update with your document
      const blob = await asPDF.toBlob(); // Convert to blob
      return blob;
    } catch (error) {
      console.error("Error generating PDF blob:", error);
    }
  };

  const handleGenerateAndUploadPDF = async () => {
    try {
      // Generate PDF Blob
      setBtnLoading(true);
      const blob = await generatePDFBlob();

      // Upload PDF
      const fileURL = await uploadFile(blob);

      sendEmailWithUrl(fileURL);
      setBtnLoading(false);
    } catch (error) {
      console.error("Error in handleGenerateAndUploadPDF:", error);
    }
  };

  const handleGenerateAndUploadPDFInSaveHistory = async () => {
    try {
      // Generate PDF Blob
      setSaveHistoryLoading(true);
      const blob = await generatePDFBlob();

      // Upload PDF
      const fileURL = await uploadFile(blob);

      getHistoryData();
      // sendEmailWithUrl(fileURL);
      setSaveHistoryLoading(false);
    } catch (error) {
      console.error("Error in handleGenerateAndUploadPDF:", error);
    }
  };

  function getYearStartAndEndDates(startDateString) {
    // Convert start date to GMT
    const startDate = new Date(startDateString);
    const year = startDate.getFullYear(); // Extract the year from the start date

    // Set start date to the beginning of the year in GMT
    const yearStartDateGMT = new Date(Date.UTC(year, 0, 1, 0, 0, 0, 0));

    // Set end date to the end of the year in local time zone (December 31st)
    const yearEndDateLocal = new Date(year, 11, 31, 23, 59, 59, 999);

    // Convert end date to GMT
    const yearEndDateGMT = new Date(Date.UTC(year, 11, 31, 0, 0, 0, 0));

    // Convert to UNIX timestamps
    const startTimestamp = Math.floor(yearStartDateGMT.getTime() / 1000);
    const endTimestamp = Math.floor(yearEndDateGMT.getTime() / 1000);

    return {
      startdate: startTimestamp,
      enddate: endTimestamp,
    };
  }

  useEffect(() => {
    const { startdate, enddate } = getYearStartAndEndDates(yearly);
    setYeartimestamps({ startdate, enddate });
  }, [yearly]);

  const handleStartMonthChange = (date) => {
    setStartMonth(date);
    if (endMonth && date && date <= endMonth) {
      setEndMonth(null);
    }
  };

  const handleEndMonthChange = (date) => {
    if (startMonth && date && date < startMonth) {
      return;
    }
    setEndMonth(date);
  };

  const getMonthStartDate = (month) => {
    const startDate = new Date(month);
    const startDateGMT = new Date(Date.UTC(startDate.getFullYear(), startDate.getMonth(), 1)); // Construct date in GMT
    return Math.floor(startDateGMT.getTime() / 1000); // Return start date in GMT as UNIX timestamp
  };

  const getMonthEndDate = (startDateString) => {
    const startDate = new Date(startDateString);
    const endDate = new Date(startDate);
    endDate.setUTCMonth(endDate.getUTCMonth() + 2);
    endDate.setUTCDate(0);
    endDate.setUTCHours(0, 0, 0, 0);
    return Math.floor(endDate.getTime() / 1000);
  };

  // const getMonthEndDate = (startDateString) => {
  //   const startDate = new Date(startDateString);
  //   const endDate = new Date(startDate);
  //   endDate.setUTCMonth(endDate.getUTCMonth() + 2);
  //   endDate.setUTCDate(0);
  //   endDate.setUTCHours(23, 59, 59, 0); // Set time to 23:59:59
  //   return Math.floor(endDate.getTime() / 1000);
  // };

  const toggleDatePicker = () => {
    setIsDatePickerOpen(!isDatePickerOpen); // Toggle the visibility state
  };

  useEffect(() => {
    if (startMonth && endMonth) {
      const startdate = getMonthStartDate(startMonth);
      const enddate = getMonthEndDate(endMonth);
      setStartEndMonthTimestamps({ startdate, enddate });
    }
  }, [startMonth, endMonth]);

  useEffect(() => {
    if (singleMonth) {
      const { startdate, enddate } = getMonthStartAndEndDate(singleMonth);
      setSingleMonthTimestamps({ startdate, enddate });
    }
  }, [singleMonth]);

  const getMonthStartAndEndDate = (month) => {
    const date = new Date(month);
    const startdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0));
    const enddate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0, 0));

    // Adjust for timezone offset to get GMT time
    const timezoneOffsetInMinutes = startdate.getTimezoneOffset();
    startdate.setMinutes(startdate.getMinutes() - timezoneOffsetInMinutes);
    startdate.setUTCHours(0, 0, 0, 0);

    enddate.setMinutes(enddate.getMinutes() - timezoneOffsetInMinutes);
    enddate.setUTCHours(0, 0, 0, 0);

    return {
      startdate: startdate.getTime() / 1000,
      enddate: enddate.getTime() / 1000,
    };
  };

  const toUTCUnixTimestampfunction = (dailyDate) => {
    const date = new Date(dailyDate);
    const localOffset = date.getTimezoneOffset(); // Get the local timezone offset in minutes
    date.setMinutes(date.getMinutes() - localOffset); // Adjust the date to UTC

    date.setUTCHours(0, 0, 0); // Set time to 23:59:59 in UTC
    return Math.floor(date.getTime() / 1000); // Convert to UNIX timestamp in seconds
  };

  const toUTCUnixTimestampfunctionEndDate = (dailyDate) => {
    const date = new Date(dailyDate);
    const localOffset = date.getTimezoneOffset(); // Get the local timezone offset in minutes
    date.setMinutes(date.getMinutes() - localOffset); // Adjust the date to UTC

    date.setUTCHours(23, 59, 59, 0); // Set time to 23:59:59 in UTC
    return Math.floor(date.getTime() / 1000); // Convert to UNIX timestamp in seconds
  };

  //   const getMonthStartAndEndDate = (month) => {
  //     const date = new Date(month);
  //     const startdate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0));
  //     const enddate = new Date(Date.UTC(date.getFullYear(), date.getMonth() + 1, 0, 0, 0, 0, 0));

  //     // Adjust for timezone offset to get GMT time
  //     const timezoneOffsetInMinutes = startdate.getTimezoneOffset();
  //     startdate.setMinutes(startdate.getMinutes() - timezoneOffsetInMinutes);
  //     enddate.setMinutes(enddate.getMinutes() - timezoneOffsetInMinutes);

  //     return {
  //       startdate: startdate.getTime() / 1000,
  //       enddate: enddate.getTime() / 1000,
  //     };
  // };

  // const getMonthStartAndEndDate = (month) => {
  //   const date = new Date(month);
  //   const startdate = new Date(date.getFullYear(), date.getMonth(), 1, 0, 0, 0, 0);
  //   const enddate = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59, 999);
  //   return {
  //     startdate: Math.floor(startdate.getTime() / 1000),
  //     enddate: Math.floor(enddate.getTime() / 1000),
  //   };
  // };

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const reportAnimation = {
    loop: true,
    autoplay: true,
    animationData: reportAnimationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <>
      <div className="grid min-h-[83vh] grid-cols-12 gap-4">
        <div className="col-span-12 flex flex-col gap-y-7  xl:col-span-5 xl:gap-y-5 ">
          {(user?.user?.permissions?.includes("report-generate") || user?.user?.role === "setting_user") && (
            <div className="card flex-grow">
              <div className="pt-4">
                <Heading
                  heading="Generate Report"
                  subheading="Long established fact that a reader will be distracted by the readable content of a page when looking at its layout."
                />
              </div>
              <div className="space-y-2 ">
                <div className="w-full">
                  <Tab.Group>
                    <Tab.List className="mt-8  flex">
                      {tabs.map((tabLabel, index) => (
                        <Tab
                          key={index}
                          onClick={() => handleTabChange(index)}
                          className={({
                            selected,
                          }) => `flex w-full justify-center border-b-2 py-2.5 text-left text-sm font-medium leading-5 focus:outline-none xl:text-xs 2xl:px-2 2xl:text-sm
                           ${selected ? "border-secondary font-semibold text-secondary" : "text-primary-color-300 hover:text-secondary"}`}
                        >
                          {tabLabel}
                        </Tab>
                      ))}
                    </Tab.List>
                    <Tab.Panels className="mt-6">
                      <Tab.Panel className="relative w-[50%] 3xl:w-[30%]">
                        <p className="mb-4 text-sm font-medium text-primary-color-100">Select Year</p>
                        <ReactDatePicker
                          ref={datePickerRef}
                          placeholderText="Select"
                          showYearPicker
                          dateFormat="yyyy"
                          selected={yearly}
                          onChange={(date) => {
                            setYearly(date);
                            setIsDatePickerOpen(false);
                          }}
                          onClickOutside={() => setIsDatePickerOpen(false)}
                          open={isDatePickerOpen}
                          yearItemNumber={6}
                          onFocus={() => setIsDatePickerOpen(true)}
                        />
                        <div className="absolute right-5 bottom-3" onClick={toggleDatePicker}>
                          <ChevronDownIcon className="inline-block h-5 w-5" />
                        </div>
                      </Tab.Panel>

                      <Tab.Panel className="flex  gap-x-5 3xl:w-[70%] ">
                        <div className="relative">
                          <p className="mb-4 text-sm font-medium text-primary-color-100">Start Month</p>
                          <ReactDatePicker
                            ref={datePickerRef}
                            onClickOutside={() => setIsDatePickerOpen(false)}
                            open={isDatePickerOpen}
                            onFocus={() => setIsDatePickerOpen(true)}
                            placeholderText="Select"
                            showMonthYearPicker
                            dateFormat="MMM/yyyy"
                            selected={startMonth}
                            onChange={(date) => {
                              handleStartMonthChange(date);
                              setIsDatePickerOpen(false);
                            }}
                          />
                          <div className="absolute right-5 bottom-3" onClick={toggleDatePicker}>
                            <ChevronDownIcon className="inline-block h-5 w-5" />
                          </div>
                        </div>
                        <div className="relative">
                          <p className="mb-4 text-sm font-medium text-primary-color-100">End Month</p>
                          <ReactDatePicker
                            filterDate={(date) => date > startMonth}
                            minDate={startMonth}
                            placeholderText="Select"
                            showMonthYearPicker
                            dateFormat="MMM/yyyy"
                            selected={endMonth}
                            onChange={handleEndMonthChange}
                          />
                          <div className="absolute right-5 bottom-3">
                            <ChevronDownIcon className="inline-block h-5 w-5" />
                          </div>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel className="relative w-[50%] 3xl:w-[30%]">
                        <p className="mb-4 text-sm font-medium text-primary-color-100">Select Month</p>
                        <ReactDatePicker
                          ref={datePickerRef}
                          onClickOutside={() => setIsDatePickerOpen(false)}
                          open={isDatePickerOpen}
                          onFocus={() => setIsDatePickerOpen(true)}
                          placeholderText="Select"
                          showMonthYearPicker
                          dateFormat="MMM/yyyy"
                          selected={singleMonth}
                          onChange={(date) => {
                            setSingleMonth(date);
                            setIsDatePickerOpen(false);
                          }}
                        />
                        <div className="absolute right-5 bottom-3" onClick={toggleDatePicker}>
                          <ChevronDownIcon className="inline-block h-5 w-5" />
                        </div>
                      </Tab.Panel>
                      <Tab.Panel className="relative w-[30%]">
                        <p className="mb-4 text-sm font-medium text-primary-color-100">Select Week</p>
                        {/* <ReactDatePicker
                          selected={weekly}
                          placeholderText="Select"
                          onChange={(date) => setWeekly(date)}
                          dateFormat="I/R"
                          locale="en-GB"
                          showWeekPicker
                          showWeekNumbers
                        /> */}
                        <HonestWeekPicker onChange={(week) => setWeekly(week)} />
                        {/* <div className="absolute right-5 bottom-3">
                          <ChevronDownIcon className="inline-block h-5 w-5" />
                        </div> */}
                      </Tab.Panel>
                      <Tab.Panel className="relative w-[50%] 3xl:w-[30%]">
                        <p className="mb-4 text-sm font-medium text-primary-color-100">Select Date</p>
                        <ReactDatePicker
                          ref={datePickerRef}
                          onClickOutside={() => setIsDatePickerOpen(false)}
                          open={isDatePickerOpen}
                          onFocus={() => setIsDatePickerOpen(true)}
                          selected={dailyDate}
                          dateFormat="dd/MMM/yyyy"
                          onChange={(date) => {
                            setDailyDate(date);
                            setIsDatePickerOpen(false);
                          }}
                          placeholderText="Select"
                        />
                        <div className="absolute right-5 bottom-3" onClick={toggleDatePicker}>
                          <ChevronDownIcon className="inline-block h-5 w-5" />
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </Tab.Group>
                </div>

                <div className="flex cursor-pointer justify-end">
                  <span
                    className={`text-xs font-medium text-secondary ${
                      yearly || dailyDate || weekly || singleMonth || startMonth || endMonth ? "opacity-100" : "cursor-not-allowed opacity-50"
                    } underline 3xl:text-base`}
                    onClick={yearly || dailyDate || weekly || singleMonth || startMonth || endMonth ? getReportData : undefined}
                  >
                    Generate
                  </span>
                </div>
              </div>
            </div>
          )}
          {(user?.user?.permissions?.includes("report-view-history") || user?.user?.role === "setting_user") && (
            <div className="card flex-grow-[2]">
              <div className="flex items-center justify-between pt-4">
                <div className="flex items-center gap-x-2">
                  <Heading
                    heading="History"
                    icon
                    handleRefresh={() => {
                      getHistoryData();
                    }}
                  />
                </div>
                <RangePicker setRangePicker={setDateRange} width="3xl:w-80 2xl:w-60 xl:w-76" />
              </div>
              <div className="pt-4">
                <div className="-mx-6 mb-8 overflow-x-auto">
                  <table className="w-full text-left">
                    <thead>
                      <tr>
                        {TABLE_HEAD_HISTORY?.map((head) => (
                          <th
                            key={head}
                            className="border-b border-gray-100 bg-white p-4 first:pl-6"
                            onClick={() => {
                              let sortKey;
                              if (head === "ID") {
                                sortKey = "transaction_id";
                              } else if (head === "Report Type") {
                                sortKey = "report_type";
                              } else if (head === "Date") {
                                sortKey = "start_date";
                              } else {
                                sortKey = head.toLowerCase();
                              }
                              requestSort(sortKey);
                            }}
                          >
                            <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                              {head}
                              {sortConfig.key ===
                                (head === "ID"
                                  ? "transaction_id"
                                  : head === "Report Type"
                                    ? "report_type"
                                    : head === "Date"
                                      ? "start_date"
                                      : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                                <>{head === "Action" ? "" : <ChevronUpIcon className="inline-block h-4 w-3.5" />}</>
                              ) : (
                                <>{head === "Action" ? "" : <ChevronDownIcon className="inline-block h-4 w-3.5" />}</>
                              )}
                            </p>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {loading ? (
                        <tr>
                          <td colSpan="6">
                            <Skeleton count={itemsPerPage} height={55} />
                          </td>
                        </tr>
                      ) : items.length > 0 ? (
                        items.map((item, index) => (
                          <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                            <td className="py-5 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.id}</p>
                            </td>
                            {/* <td className="py-5 pl-4 3xl:px-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.report_type}</p>
                      </td> */}
                            <td className="py-5 pl-4 3xl:px-4">
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {moment.unix(item?.created_at_unix).format("D MMM YYYY")}
                              </p>
                            </td>
                            <td>
                              <div className="flex items-center gap-x-3">
                                <span
                                  className="cursor-pointer text-xs font-normal text-accent underline 3xl:text-sm"
                                  onClick={() => handleView(item)}
                                >
                                  View
                                </span>
                                <span
                                  className="cursor-pointer text-xs font-normal text-secondary underline 3xl:text-sm"
                                  onClick={() => handleDownload(item)}
                                >
                                  Download
                                </span>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className=" h-[300px]">
                          <td colSpan="6">
                            <Lottie options={emptyFolderAnimation} width={200} height={200} />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>

                <div className="absolute bottom-4">
                  <ReactPaginate
                    breakLabel="..."
                    pageRangeDisplayed={5}
                    forcePage={currentPageAllHistory - 1}
                    marginPagesDisplayed={2}
                    activeClassName="active"
                    nextClassName="item next"
                    renderOnZeroPageCount={null}
                    breakClassName="item break-me "
                    containerClassName="pagination"
                    onPageChange={handleReportsHisttoryChange}
                    previousClassName="item previous"
                    pageClassName="item pagination-page "
                    pageCount={totalPageAllHistory}
                    nextLabel={<ChevronRightIcon className="h-5 w-5" />}
                    previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
        {(user?.user?.permissions?.includes("report-view-history") || user?.user?.role === "setting_user") && (
          <div className="card col-span-12 xl:col-span-7">
            {reportDataLoading ? (
              <>
                <div className="flex items-center justify-between">
                  <Heading heading="Preview" />
                  <div className="flex items-center space-x-3">
                    <Skeleton count={1} height={30} width={100} />
                    <Skeleton count={1} height={30} width={100} />
                    <Skeleton count={1} height={30} width={100} />
                  </div>
                </div>
                <div className="mt-4">
                  <Skeleton count={1} height={700} />
                </div>
              </>
            ) : Object.keys(reportData).length > 0 ? (
              <>
                <div className="flex items-center justify-between pb-4">
                  <Heading heading="Preview" />
                  <div className="flex items-center space-x-3">
                    <Button
                      title={saveHistoryLoading ? <Spinner /> : "Save in History"}
                      btnSmall
                      buttonColor="bg-[#8EE1C8] text-white"
                      className="w-100"
                      onClick={() => handleGenerateAndUploadPDFInSaveHistory()}
                    />

                    {(user?.user?.permissions?.includes("report-download-share") || user?.user?.role === "setting_user") && (
                      <>
                        <Button
                          title={btnLoading ? <Spinner /> : "Share via email"}
                          btnSmall
                          buttonColor="bg-[#FF9D86] text-white"
                          className="w-100"
                          onClick={() => handleGenerateAndUploadPDF()}
                        />
                        <div>
                          <PDFDownloadLink document={<MyDocument />} fileName="Report.pdf">
                            {/* {({ blob, url, loading, error }) => ( */}
                            <Button title="Download" btnSmall className="w-100" />
                            {/* )} */}
                          </PDFDownloadLink>
                        </div>{" "}
                      </>
                    )}
                  </div>
                </div>

                <div className="-mr-5 h-[83vh] overflow-y-auto">
                  <div className="pr-5">
                    <ReportTable reportData={reportData} />
                  </div>
                </div>
              </>
            ) : (
              <div className="flex h-full items-center justify-center">
                <div className="flex flex-col">
                  <Lottie options={reportAnimation} width={200} height={200} />
                  <p className="text-center text-lg font-medium text-gray-400">Please Select a Date Range</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      <ShareEmailModal isOpen={openShareModal} setIsOpen={setOpenShareModal} />
    </>
  );
};

export default Reports;

{
  /* { ((user?.user?.permissions?.includes("report-generate")) || (user?.user?.role === "setting_user")) && (
        <Button loading={exportReportLoading} title="Export Report" className="w-60" onClick={handleExportReport} />
        )} */
}
{
  /* { ((user?.user?.permissions?.includes("report-download-share")) || (user?.user?.role === "setting_user")) && (
        <Button loading={exportReportLoading} title="Download" className="w-60" onClick={handleExportReport} />
        )}
        { ((user?.user?.permissions?.includes("report-download-share")) || (user?.user?.role === "setting_user")) && (
        <Button loading={exportReportLoading} title="Share" className="w-60" onClick={handleExportReport} />
        )} */
}

{
  /* <div className="space-y-2">
              <h2 className="text-sm font-medium text-primary-color-500">Report Type</h2>
              <StatusFilter
                selected={selectedReportTypeOption}
                setSelected={setSelectedReportTypeOption}
                options={reportTypeOption}
                className="border py-3.5"
              />
            </div> */
}

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#FFFFFF",
    padding: "30px",
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },

  padding: {
    paddingBottom: "50px",
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#F6F7F8",
  },
  tableRowEven: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
  },
  tableColHeader: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#F6F7F8",
  },
  tableColHeader2: {
    width: "42.84%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    backgroundColor: "#F6F7F8",
  },
  tableCol: {
    width: "14.28%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColHidden: {
    width: "17%",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCol2: {
    width: "42.84%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableColCenter2: {
    width: "28.56%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },

  tableCellHeader: {
    margin: 5,
    fontSize: 12,
    fontWeight: "bold",
  },
  tableCell: {
    margin: 5,
    fontSize: 10,
  },
  colSpan7: {
    flex: 7, // This will span two columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  colSpan2: {
    width: "28.56%", // This will span two columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  colSpan3: {
    width: "42.84%", // This will span two columns
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
    textAlign: "center",
  },
  image: {
    width: 20,
    height: 20,
    objectFit: "contain",
  },
  flexContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "2px",
  },
  total: {
    fontWeight: "bold",
  },
  percentage: {
    fontWeight: "semibold",
  },
  value: {
    fontWeight: "extrabold",
  },
});
