import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";

const SuccessModal = () => {
  // Context
  const { openModal, closeSuccessModel } = useLoggedContext();

  return (
    <>
      <Transition appear show={openModal.open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => closeSuccessModel()}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-md p-8 overflow-hidden text-center align-middle transition-all bg-white shadow-xl rounded-2xl">
                  <img src={images.GreatEmoji} alt="question mark icon" className="mx-auto" />
                  <Dialog.Title as="h3" className="max-w-xs mx-auto mt-12 text-2xl font-semibold leading-7 font-inter text-secondary">
                    {openModal?.title}
                  </Dialog.Title>
                  <p className="max-w-xs mx-auto mt-2 text-base font-medium xl:text-lg text-secondary">{openModal?.message}</p>

                  <div className="flex justify-center px-8 mt-12 gap-x-6">
                    <button
                      onClick={() => {
                        openModal.onPress ? openModal.onPress(closeSuccessModel) : closeSuccessModel();
                      }}
                      type="button"
                      className="flex items-center justify-center w-full h-12 px-12 font-semibold text-white rounded-full bg-secondary focus:outline-none focus:ring-offset-slate-50 sm:w-max"
                    >
                      <span>Done</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default SuccessModal;
