import Lottie from "react-lottie";
import ApiServices from "../api/services";
import Button from "../components/Button";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import { useMediaQuery } from "react-responsive";
import InputField from "../components/InputField";
import React, { useEffect, useState } from "react";
import FilterSelect from "../components/FilterSelect";
import { useLoggedContext } from "../context/provider";
import { validateForm } from "../helperFunctions/helper";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import RangePicker from "../components/RangePicker";
import Skeleton from "react-loading-skeleton";
import Spinner from "../components/Spinner";
import moment from "moment";
import EllipsisText from "../components/EllipsisText";
import ReadMoreModal from "../components/ReadMoreModal";
import { ArrowPathIcon } from "@heroicons/react/24/outline";

const Notifications = () => {
  // Context
  const { openSuccessModal, setBtnLoading, btnLoading, user } = useLoggedContext();

  // useStates
  const [errors, setErrors] = useState({});
  const [notificationloading, setNotificationloading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [questionnireBtnLoading, setQuestionnireBtnLoading] = useState(false);
  const [broadcastNotificationList, setBroadcastNotification] = useState(null);
  const [openReadMoreMessageModal, setOpenReadMoreMessageModal] = useState({ open: false, data: null });
  const [formData, setFormData] = useState({ title: "", url: "", description: "" });
  // Destructure Values From formData
  const { title, url, description } = formData;

  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });

  const MAX_MESSAGE_LENGTH = 500;

  // Input Values Handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    let trimmedValue = value.trimStart();
    let error = "";

    // Validate URL
    if (name === "url" && trimmedValue.length > 0) {
      const urlRegex = /^((ftp|http|https):\/\/)?(www\.)?[^ "]+(\.[^ "]+)+$/;
      if (!urlRegex.test(trimmedValue)) {
        error = "Please enter a valid URL.";
      }
    }

    // Validate description length
    if (name === "description" && trimmedValue.length > MAX_MESSAGE_LENGTH) {
      error = `Message must be at least ${MAX_MESSAGE_LENGTH} characters long.`;
    }

    setErrors({ ...errors, [name]: error });
    setFormData({ ...formData, [name]: trimmedValue });
  };

  // Submit Function
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm(formData);
    const { title, description, url } = errors;
    setErrors(errors);

    if (!title && !description) {
      setBtnLoading(true);
      const notificationData = new FormData();
      notificationData.append("title", formData.title);
      notificationData.append("message", formData.description);
      notificationData.append("url", formData.url);

      ApiServices.broadcastNotification
        .addBroadcastNotification(notificationData)
        .then((res) => {
          const { data, message } = res;

          if (data.code === 200) {
            setBtnLoading(false);
            getNotifications();
            openSuccessModal({
              title: "Success!",
              message: "Broadcast Notification Sent!",
              onPress: (close) => {
                close();
                getNotifications();
              },
            });
            setFormData({ title: "", url: "", description: "" });
          }
        })
        .catch((err) => {
           
        });
    }
  };

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 4 : isLaptopMedium ? 3 : isLaptop ? 3 : 4;

  const shortDescription = isLargeScreenLaptop ? 80 : isLaptopMedium ? 40 : isLaptop ? 60 : 60;

  // Pending Settings Listing
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // get Notifications listing
  const getNotifications = async () => {
    setNotificationloading(true);
    let payload = {
      page: currentPage,
      records_no: itemsPerPage,
      start_date: dateRange.startDate ? dateRange.startDate : null,
      end_date: dateRange.endDate ? dateRange.endDate : null,
    };

    ApiServices.broadcastNotification
      .getBroadcastNotifications(payload)
      .then((res) => {
        const { data, message } = res;
         
        if (data.code === 200) {
          setNotificationloading(false);
          setBroadcastNotification(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  // send questionnire form
  const sendQuestionnaireForm = () => {
    setQuestionnireBtnLoading(true);
    ApiServices.broadcastNotification
      .sendQuestionnaireForm()
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setQuestionnireBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "The Well-being Questionnaire form was successfully sent to all!",
            onPress: (close) => {
              close();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Use Effects
  useEffect(() => {
    getNotifications();
  }, [currentPage, dateRange]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <>
      <div className="grid grid-cols-1 gap-4 xl:grid-cols-2">
        {(user?.user?.permissions?.includes("broadcast-notification-send") || user?.user?.role === "setting_user") && (
          <div className="card min-h-[82vh] 3xl:h-[84vh]">
            <div className="flex justify-between">
              <Heading heading="Broadcast Notification" />
              <button
                className="flex h-11 items-center justify-center rounded-full border border-accent bg-white px-4 text-xs font-medium text-accent transition-all hover:bg-accent hover:text-white hover:shadow-lg 2xl:text-base 3xl:h-12 3xl:w-84 3xl:px-8 3xl:text-sm"
                onClick={sendQuestionnaireForm}
              >
                {questionnireBtnLoading ? <Spinner /> : "Send a Well-being Questionnaire to all"}
              </button>
            </div>

            <form onSubmit={handleSubmit} className="mt-8 space-y-6">
              <div className="grid grid-cols-2 gap-6">
                <InputField
                  type="text"
                  name="title"
                  label="Title"
                  value={title}
                  error={errors.title}
                  onChange={handleChange}
                  placeholder="Add a notification title"
                  maxLength={50}
                  required
                />
                <InputField
                  type="text"
                  name="url"
                  label="URL"
                  value={url}
                  error={errors.url}
                  onChange={handleChange}
                  placeholder="Add a url"
                  maxLength={50}
                />
              </div>

              <div className="mt-6 space-y-2">
                <label htmlFor="description" className="pl-1 text-sm font-medium text-primary-color-100">
                  Description <span className="text-red-500">*</span>{" "}
                  {errors.description && <span className="text-xs text-red-500 ">{errors.description}</span>}
                </label>
                <textarea
                  rows="15"
                  onChange={handleChange}
                  name="description"
                  value={description}
                  placeholder="Write your message here"
                  className={` w-full rounded-xl border px-6 py-3 text-sm font-normal text-primary-color-200 placeholder:text-primary-color-300 ${errors.description ? "!border-red-500 focus:ring-red-500" : "border-primary-color-300"}`}
                />
                <p className="text-right text-sm text-primary-color-100">{description?.length || 0}/500</p>
              </div>

              <Button loading={btnLoading} title="Send Notification" className={btnLoading && "w-44 xl:w-48"} />
            </form>
          </div>
        )}
        <div className="card min-h-[82vh] 3xl:h-[84vh]">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-2">
              <Heading
                heading="History"
                icon
                handleRefresh={() => {
                  getNotifications();
                }}
              />
            </div>

            <RangePicker setRangePicker={setDateRange} width="xl:w-80" />
          </div>

          <div className="mt-4 space-y-5 lg:mb-12 xl:mb-0">
            {notificationloading ? (
              <Skeleton count={itemsPerPage} className="h-36 w-full !rounded-xl" />
            ) : broadcastNotificationList?.length > 0 ? (
              broadcastNotificationList?.map((item, index) => (
                <div key={index} className="space-y-2 rounded-xl border border-primary-color-300 p-6">
                  <div className="flex items-center justify-between">
                    <h2 className="max-w-[300px] text-lg font-semibold text-secondary-color 3xl:max-w-[400px]">{item?.title}</h2>
                    <p className="text-sm text-primary-color-100">{moment.unix(item?.created_date_unix).format("D MMM, YYYY")}</p>
                  </div>
                  <a className="text-sm text-sky-600 underline" href={item?.url} target="_black">
                    {item?.url}
                  </a>
                  <p className="text-sm text-primary-color-100">
                    {" "}
                    {item?.message ? (
                      <>
                        {item?.message?.length > shortDescription ? (
                          <div className="cursor-pointer" onClick={() => setOpenReadMoreMessageModal({ open: true, data: item?.message })}>
                            <EllipsisText text={item?.message} maxLength={shortDescription} />
                          </div>
                        ) : (
                          <EllipsisText text={item?.message} maxLength={shortDescription} />
                        )}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </p>
                </div>
              ))
            ) : (
              <Lottie options={emptyFolderAnimation} height={500} width={250} />
            )}
          </div>

          <div className="absolute bottom-2">
            <ReactPaginate
              breakLabel="..."
              pageRangeDisplayed={5}
              marginPagesDisplayed={2}
              activeClassName="active"
              nextClassName="item next"
              renderOnZeroPageCount={null}
              breakClassName="item break-me "
              containerClassName="pagination"
              previousClassName="item previous"
              pageCount={totalPages}
              pageClassName="item pagination-page"
              forcePage={currentPage - 1}
              onPageChange={handlePageChange}
              nextLabel={<ChevronRightIcon className="h-5 w-5" />}
              previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
            />
          </div>
        </div>
      </div>
      <ReadMoreModal
        data={openReadMoreMessageModal.data}
        isOpen={openReadMoreMessageModal.open}
        setIsOpen={(isOpen) => setOpenReadMoreMessageModal({ ...openReadMoreMessageModal, open: isOpen })}
        name="Broadcast Notfication Description"
      />
    </>
  );
};

export default Notifications;
