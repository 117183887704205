import moment from "moment";
import Lottie from "react-lottie";
import { Tooltip } from "react-tooltip";
import Button from "../components/Button";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import React, { useEffect, useState } from "react";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import AddContactModal from "../components/AddContactModal";
import ConfirmationModal from "../components/ConfirmationModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// Table Head
const TABLE_HEAD = ["Name", "Email", "Website", "Phone Number", "Action"];
const TABLE_HEAD_WITH_OUT_ACTION = ["Name", "Email", "Website", "Phone Number"];

const ContactBook = () => {
  // Context
  const { loading, setLoading, openSuccessModal, setBtnLoading, employeesListing, user } = useLoggedContext();

  // useStates
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [contactBookList, setContactBookList] = useState([]);

  const [searchText, setSearchText] = useState("");
  const [openDeleteContactModal, setOpenDeleteContactModal] = useState({ open: false, data: null });
  const [openNewContactModal, setOpenNewContactModal] = useState({ open: false, data: null, editMode: false });

  const [forcePage, setForcePage] = useState(0);

  const [isSearchAction, setIsSearchAction] = useState(false);

  // <------- Table Sorting ----------->

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(contactBookList);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 12 : isLaptopMedium ? 8 : isLaptop ? 8 : 6;

  // Get contact book list
  const getContactBookList = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPage,
      records_no: itemsPerPage,
    };

    const res = await ApiServices.contactBook
      .getContactBook(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setLoading(false);
          setContactBookList(data?.data?.data);
          setCurrentPage(data?.data?.current_page);
          setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
        }
      })
      .catch((err) => {
         
      });
  };

  // Delete contact
  const deleteContact = () => {
    setBtnLoading(true);
    let id = openDeleteContactModal?.data;

    const formData = new FormData();
    formData.append("id", id);

    ApiServices.contactBook
      .deleteContact(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          getContactBookList();
          setOpenDeleteContactModal({ open: false, data: null });
          openSuccessModal({
            title: "Success!",
            message: "Contact has been Deleted",
            onPress: (close) => {
              close();
              getContactBookList();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // Handle Page Change
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getContactBookList();
        setCurrentPage(1);
      }
    }
  };

  // useEffects

  useEffect(() => {
    getContactBookList();
  }, [currentPage]);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // "constact-book-add-new-contact",
  // "contact-book-edit-delete",

  return (
    <>
      <div className="card h-[82vh] 3xl:h-[84vh]">
        <div className="mb-6">
          <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
            <div className="flex gap-x-3">
              <Heading
                heading="All Contacts"
                icon
                handleRefresh={() => {
                  getContactBookList();
                }}
              />
            </div>
            {/* Search */}

            <div className="flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
              {(user?.user?.permissions?.includes("constact-book-add-new-contact") || user?.user?.role === "setting_user") && (
                <Button
                  title="Add new Contact"
                  btnSmall
                  onClick={() => {
                    setOpenNewContactModal({ open: true, data: null, editMode: false });
                  }}
                />
              )}

              <div className="relative flex items-center ">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>

                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  autocomplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      getContactBookList(true);
                    }
                  }}
                  onKeyDown={handleSearch}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>
              {/* select */}
            </div>
          </div>
        </div>

        <div className="-mx-6 mb-8 overflow-x-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                {(user?.user?.permissions?.includes("contact-book-edit-delete") || user?.user?.role === "setting_user"
                  ? TABLE_HEAD
                  : TABLE_HEAD_WITH_OUT_ACTION
                ).map((head) => (
                  <th
                    key={head}
                    className="w-38 border-b border-gray-100 bg-white p-4 first:pl-6"
                    onClick={() => {
                      let sortKey;
                      if (head === "Name") {
                        sortKey = "name";
                      } else if (head === "Email") {
                        sortKey = "email";
                      } else if (head === "Website") {
                        sortKey = "website";
                      } else if (head === "Phone Number") {
                        sortKey = "phone_number";
                      } else {
                        sortKey = head.toLowerCase();
                      }
                      requestSort(sortKey);
                    }}
                  >
                    <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                      {head}
                      {sortConfig.key ===
                        (head === "Name"
                          ? "name"
                          : head === "Email"
                            ? "email"
                            : head === "Website"
                              ? "website"
                              : head === "Phone Number"
                                ? "phone_number"
                                : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                        <ChevronUpIcon className="inline-block h-4 w-3.5" />
                      ) : (
                        <ChevronDownIcon className="inline-block h-4 w-3.5" />
                      )}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Skeleton count={itemsPerPage} height={50} />
                  </td>
                </tr>
              ) : items.length > 0 ? (
                items.map((item, index) => (
                  <tr key={item?.id} className="even:bg-gray-50">
                    <td className="py-3 pl-4 3xl:px-4 3xl:first:pl-6">
                      <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.name ? item?.name : "N/A"}</p>
                    </td>
                    <td className="py-3 pl-4 pr-3 3xl:px-4">
                      {item?.email ? (
                        <a href={`mailto:${item.email}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {item.email}
                        </a>
                      ) : (
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">N/A</p>
                      )}
                    </td>
                    <td className="py-3 pl-4 pr-3 3xl:px-4">
                      {item?.website ? (
                        <a
                          href={item?.website}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-xs font-normal text-primary-color-200 3xl:text-sm"
                        >
                          {item.website}
                        </a>
                      ) : (
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">N/A</p>
                      )}
                    </td>
                    <td className="py-3 pl-4 pr-3 3xl:px-4">
                      {item?.phone_number ? (
                        <a href={`tel:${item.phone_number}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {item.phone_number}
                        </a>
                      ) : (
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">N/A</p>
                      )}
                    </td>

                    {(user?.user?.permissions?.includes("contact-book-edit-delete") || user?.user?.role === "setting_user") && (
                      <td className="py-3 pl-4 pr-3 3xl:px-4">
                        <div className="flex gap-x-3">
                          <span
                            onClick={() => {
                              setOpenNewContactModal({ open: true, data: item, editMode: true });
                            }}
                            className="cursor-pointer text-xs font-normal text-secondary underline underline-offset-4 3xl:text-sm"
                          >
                            Edit Contact
                          </span>

                          <span
                            onClick={() => setOpenDeleteContactModal({ open: true, data: item?.id })}
                            className="undreline cursor-pointer text-xs font-normal text-red-500 underline underline-offset-4 3xl:text-sm"
                          >
                            Delete
                          </span>
                        </div>
                      </td>
                    )}
                  </tr>
                ))
              ) : (
                // Render "No Data" message for active driver
                <tr className="h-[500px]">
                  <td colSpan="6">
                    <Lottie options={emptyFolderAnimation} width={200} height={200} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="absolute bottom-4">
          <ReactPaginate
            breakLabel="..."
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            activeClassName="active"
            nextClassName="item next"
            renderOnZeroPageCount={null}
            breakClassName="item break-me "
            containerClassName="pagination"
            previousClassName="item previous"
            pageCount={totalPages}
            pageClassName="item pagination-page"
            forcePage={currentPage - 1}
            onPageChange={handlePageChange}
            nextLabel={<ChevronRightIcon className="h-5 w-5" />}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      {/* Delete Modal */}
      <ConfirmationModal
        handleSubmit={deleteContact}
        label="Delete this contact"
        isOpen={openDeleteContactModal.open}
        setIsOpen={(open) => setOpenDeleteContactModal((prev) => ({ ...prev, open }))}
      />

      {/* Add Contact Modal */}
      <AddContactModal
        isOpen={openNewContactModal.open}
        data={openNewContactModal.data}
        editMode={openNewContactModal.editMode}
        setIsOpen={(open) => setOpenNewContactModal((prev) => ({ ...prev, open }))}
        onSuccess={getContactBookList}
      />
    </>
  );
};

export default ContactBook;
