import moment from "moment";
import Lottie from "react-lottie";
import { Link } from "react-router-dom";
import ApiServices from "../api/services";
import Button from "../components/Button";
import { useNavigate } from "react-router";
import Heading from "../components/Heading";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { images } from "../assets/styles/Images";
import { useMediaQuery } from "react-responsive";
import { dashboard } from "../routes/routesDefined";
import RangePicker from "../components/RangePicker";
import { useLoggedContext } from "../context/provider";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { useSortableData } from "../hooks/useSortableData";
import animationData from "../assets/lotties/empty-folder";
import { employeeMediaUrl, mediaUrl } from "../utils/config";
import AddEvaluatedModal from "../components/AddEvaluatedModal";
import EvaluateReadMoreModal from "../components/EvaluateReadMoreModal";
import SubscriptionPlansModal from "../components/SubscriptionPlansModal";
import { ArrowPathIcon, ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, PieChart, Pie, Cell, ResponsiveContainer, Bar, BarChart } from "recharts";
import { AdjustmentsHorizontalIcon } from "@heroicons/react/24/solid";
import Drawer from "../components/Drawer";
import { getLocalDateFromUnixTimestamp } from "../helperFunctions/helper";
import ViewConsultationFormModal from "../components/ViewConsultationFormModal";
import CheckInFormFillModal from "../components/CheckInFormFillModal";
// Table Head
const TABLE_HEAD_RECENT_EVALUATIONS = ["Created at", "Last updated at", "Created by", "Evaluated Data"];
const TABLE_HEAD_RECENT_PENDING_FORMS = ["Emp ID", "Name", "Forms", "Action", "Date"];

const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const Dashboard = () => {
  // Context
  const { loading, setLoading, user } = useLoggedContext();

  const allCampusStatus = user?.user?.employee[0]?.all_campus_status;
  const allCampusStatusByRole = user?.user?.role;

  // React Router Dom Hooks
  const navigate = useNavigate();

  const [filterEmployee, setFilterEmployee] = useState(null);
  const [showFilterData, setShowFilterData] = useState(false);
  const [appliedFiltersCount, setAppliedFiltersCount] = useState(0);

  // Recent and Pending Forms States
  const todayEndDate = moment.utc().endOf("day");
  const todayStartDate = moment.utc().startOf("day");
  const [categoryName, setCategoryName] = useState("");
  const [evaluatedData, setEvaluatedData] = useState([]);
  const [activeCategory, setActiveCategory] = useState("");
  const [barChartLoading, setBarChartLoading] = useState(false);
  const [recentResponsesData, setRecentResponsesData] = useState([]);
  const [employeeReasonsData, setEmployeeReasonsData] = useState(null);
  const [recentPendingFormData, setRecentPendingFormData] = useState([]);
  const [employeeAnalyticsData, setEmployeeAnalyticsData] = useState(null);
  const [openCheckInFormFillModal, setOpenCheckInFormFillModal] = useState({ open: false, data: null });
  const [openConsultationFormModal, setOpenConsultationFormModal] = useState({ open: false, data: null });

  const [recentResponseLoading, setRecentResponseLoading] = useState(false);
  const [recentPendingFormLoading, setRecentPendingFormLoading] = useState(false);
  const [openUserDrawerModal, setOpenUserDrawerModal] = useState({ open: false, data: null });
  const [openReadMoreMessageModal, setOpenReadMoreMessageModal] = useState({ open: false, data: null });
  const [openEvaluatedModal, setOpenEvaluatedModal] = useState({ open: false, data: null, editMode: false });
  // const [employeeAnalyticDateRange, setEmployeeAnalyticDateRange] = useState({ startDate: todayStartDate.unix(), endDate: todayEndDate.unix() });
  const [allCampuses, setAllCampuses] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [generalCampusList, setGeneralCampusList] = useState([]);
  const [generalDepartmentList, setGeneralDepartmentList] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [dateError, setDateError] = useState("");

  const [selectedFilter, setSelectedFilter] = useState("today");
  const [selectedCampus, setSelectedCampus] = useState({ label: "All", value: "All" });
  const [selectedDepartment, setSelectedDepartment] = useState({ label: "All", value: "All" });

  const [dateRangeForm, setDateRangeForm] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  const getCurrentDate = () => {
    const todayStart = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 0, 0, 0));
    const todayEnd = new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate(), 23, 59, 59));

    return { start: todayStart, end: todayEnd };
  };

  const today = getCurrentDate();

  const [selectedCampusOption, setSelectedCampusOption] = useState(null);
  const [selectedSettingOption, setSelectedSettingOption] = useState(null);
  const [selectedDepartmentOption, setSelectedDepartmentOption] = useState(null);
  const [selectedValue, setSelectedValue] = useState("today");
  const [dateRange, setDateRange] = useState({
    startDate: Math.floor(today.start.getTime() / 1000),
    endDate: Math.floor(today.end.getTime() / 1000),
  });

  // Support Ticket States
  const [supportTicketLoading, setSupportTicketLoading] = useState(false);
  const [supportTicketData, setSupportTicketData] = useState(null);
  const [dateRangeSupportTicket, setDateRangeSupportTicket] = useState({
    startDate: todayStartDate.unix(),
    endDate: todayEndDate.unix(),
  });

  // Forms Graph States
  const [formDataLine, setFormDataLine] = useState([]);
  const [lineGraphLoading, setLineGraphLoading] = useState(false);
  const [formCounts, setFormCounts] = useState({
    total: 0,
    fill: 0,
    pending: 0,
  });

  // Table Sorting
  const slicedEvaluatedData = evaluatedData.slice(0, 5);
  const slicedPendingFormResponses = recentPendingFormData;
  const { items: items, requestSort: requestSort, sortConfig: sortConfig } = useSortableData(slicedEvaluatedData);
  const { items: items2, requestSort: requestSort2, sortConfig: sortConfig2 } = useSortableData(slicedPendingFormResponses);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 5 : isLaptopMedium ? 5 : isLaptop ? 5 : 5;

  // Support Ticket
  const outerRadius = isLargeScreenLaptop ? 100 : isLaptopMedium ? 100 : isLaptop ? 100 : 100;
  const innerRadius = isLargeScreenLaptop ? 50 : isLaptopMedium ? 50 : isLaptop ? 60 : 60;

  const opentTickets = supportTicketData ? parseFloat(supportTicketData.opent_tickets) : 0;
  const closedTickets = supportTicketData ? parseFloat(supportTicketData.closed_tickets) : 0;

  const dataPie2 = [
    {
      name: "Open",
      value: opentTickets !== 0 ? opentTickets : opentTickets == 0 && closedTickets == 0 ? 100 : 0,
      color: opentTickets !== 0 ? "#FFBF9D" : "#d1d5db",
    },
    {
      name: "Closed",
      value: closedTickets !== 0 ? closedTickets : 0,
      color: closedTickets !== 0 ? "#264254" : "#9ca3af",
    },
  ];

  // custom tooltip for employee reasons graph
  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].name;
      const value = payload[0].value;

      let color;

      const data2Item = data2.find((item) => item.name === name);
      if (data2Item) {
        color = data2Item ? data2Item.color : "#000000";
      } else {
        const dataPieItem = dataPie2.find((item) => item.name === name);
        color = dataPieItem ? dataPieItem.color : "#000000";
      }

      return (
        <div className="relative rounded-20 bg-white p-4 shadow-card">
          <div className="h-6 w-6 rounded-full ">
            <img
              src={
                name === "Poor Sleep "
                  ? images.SleepIcon
                  : name === "Home Life Difficulties"
                    ? images.HomeLifeIcon
                    : name === "Relationship Problems"
                      ? images.RelationIcon
                      : name === "Unwell"
                        ? images.UnWellIcon
                        : name === "Bereavement"
                          ? images.BereavementIcon
                          : name === "Work Related Stress"
                            ? images.StressIcon
                            : name === "Mental Health Rest Day"
                              ? images.MentleHealth
                              : images.placeholder
              }
              alt="reason"
              className="h-full w-full rounded-full object-contain"
            />
          </div>
          <p className="label" style={{ color }}>{`${name} : ${value}%`}</p>
        </div>
      );
    }
    return null;
  };
  const CustomTooltip2 = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const name = payload[0].name;
      const value = payload[0].value;

      let color;

      const data2Item = data2.find((item) => item.name === name);
      if (data2Item) {
        color = data2Item ? data2Item.color : "#000000";
      } else {
        const dataPieItem = dataPie2.find((item) => item.name === name);
        color = dataPieItem ? dataPieItem.color : "#000000";
      }

      return (
        <div className="relative rounded-20 bg-white p-4 shadow-card">
          <p className="label" style={{ color }}>{`${name} : ${value}`}</p>
        </div>
      );
    }
    return null;
  };
  // employees reasons data handling
  const poorSleep = employeeReasonsData ? parseFloat(employeeReasonsData?.poor_sleep_count_percentage) : 0;
  const Unwell = employeeReasonsData ? parseFloat(employeeReasonsData?.unwell_count_percentage) : 0;
  const relationshipProblems = employeeReasonsData ? parseFloat(employeeReasonsData?.relationship_problem_count_percentage) : 0;
  const workRelatedStres = employeeReasonsData ? parseFloat(employeeReasonsData?.work_related_stress_count_percentage) : 0;
  const homeLifeDifficulties = employeeReasonsData ? parseFloat(employeeReasonsData?.home_life_diffuculties_count_percentage) : 0;
  const bereavement = employeeReasonsData ? parseFloat(employeeReasonsData?.bereavement_count_percentage) : 0;
  const mentleHealthAbsense = employeeReasonsData ? parseFloat(employeeReasonsData?.mars_absence_count_percentage) : 0;

  const data2 = [
    {
      name: "Poor Sleep ",
      value:
        poorSleep !== 0
          ? poorSleep
          : poorSleep == 0 &&
              Unwell == 0 &&
              relationshipProblems == 0 &&
              workRelatedStres == 0 &&
              homeLifeDifficulties == 0 &&
              bereavement == 0 &&
              mentleHealthAbsense == 0
            ? 100
            : 0,
      color: poorSleep !== 0 ? "#264254" : "#d1d5db",
    },
    {
      name: "Unwell",
      value: Unwell !== 0 ? Unwell : 0,
      color: Unwell !== 0 ? "#FEDEBC" : "#d1d5db",
    },
    {
      name: "Relationship Problems",
      value: relationshipProblems !== 0 ? relationshipProblems : 0,
      color: relationshipProblems !== 0 ? "#FCEBB0" : "#d1d5db",
    },
    {
      name: "Work Related Stress",
      value: workRelatedStres !== 0 ? workRelatedStres : 0,
      color: workRelatedStres !== 0 ? "#F0D2C9" : "#d1d5db",
    },
    {
      name: "Home Life Difficulties",
      value: homeLifeDifficulties !== 0 ? homeLifeDifficulties : 0,
      color: homeLifeDifficulties !== 0 ? "#FFBF9D" : "#d1d5db",
    },
    {
      name: "Bereavement",
      value: bereavement !== 0 ? bereavement : 0,
      color: bereavement !== 0 ? "#B6E7CF" : "#d1d5db",
    },
    {
      name: "Mental Health Rest Day",
      value: mentleHealthAbsense !== 0 ? mentleHealthAbsense : 0,
      color: mentleHealthAbsense !== 0 ? "#e8c7fd" : "#d1d5db",
    },
  ];

  // Individual employees analytics data handling
  const categories = [
    { id: "Great", classNameColor: "text-[#FFAE9B]", label: "Great", logo: images.GreatIcon, count: employeeReasonsData?.great_count },
    { id: "Okay", classNameColor: "text-[#FFBC96]", label: "Okay", logo: images.OkayIcon, count: employeeReasonsData?.okay_count },
    { id: "Meh", classNameColor: "text-[#FFDCB8]", label: "Meh", logo: images.MehIcon, count: employeeReasonsData?.meh_count },
    { id: "Low", classNameColor: "text-[#AAE9CD]", label: "Low", logo: images.LowIcon, count: employeeReasonsData?.low_count },
    {
      id: "Struggling",
      classNameColor: "text-[#264254]",
      label: "Struggling",
      logo: images.StugglingIcon,
      count: employeeReasonsData?.struggling_count,
    },
  ];

  // const dataline = months.map((month) => ({
  //   name: month,
  //   Great: 0,
  //   Okay: 0,
  //   Meh: 0,
  //   Low: 0,
  //   Struggling: 0,
  // }));

  // if (activeCategory) {
  //   const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
  //   filteredData?.forEach((data) => {
  //     const date = new Date(data.created_date_unix * 1000);
  //     const monthIndex = date.getMonth();
  //     const response = data.response;
  //     dataline[monthIndex][response] += 1;
  //   });
  // } else {
  //   employeeAnalyticsData?.forEach((data) => {
  //     const date = new Date(data.created_date_unix * 1000);
  //     const monthIndex = date.getMonth();
  //     const response = data.response;
  //     dataline[monthIndex][response] += 1;
  //   });
  // }

  const getFormattedDataline = (selectedValue, employeeAnalyticsData, startDate, endDate, activeCategory, isFilterApplied) => {
    console.log("🚀 ~ getFormattedDataline ~ selectedValue:", selectedValue);
    let dataline = [];
    let xAxisLabels = [];

    const getDateString = (date) => moment(date).format("MM/DD/YYYY");
    // if (selectedValue !== "today") {
    // selectedValue = "today";
    // }
    switch (selectedValue) {
      case "today":
      case "yesterday":
        xAxisLabels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
        dataline = xAxisLabels.map((hour) => ({
          name: hour,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const hourIndex = date.hour();
            if (dataline[hourIndex]) {
              const response = data.response;
              dataline[hourIndex][response] += 1;
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const hourIndex = date.hour();
            if (dataline[hourIndex]) {
              const response = data.response;
              dataline[hourIndex][response] += 1;
            }
          });
        }
        break;

      case "lastMonth":
        const startOfLastMonth = moment().subtract(1, "month").startOf("month");
        const endOfLastMonth = moment().subtract(1, "month").endOf("month");

        xAxisLabels = Array.from({ length: endOfLastMonth.date() }, (_, i) => `Day ${i + 1}`);
        dataline = xAxisLabels.map((day) => ({
          name: day,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);

          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            // if (date.isBetween(startOfLastMonth, endOfLastMonth, 'day', '[]')) {
            const dayIndex = date.date() - 1;
            if (dataline[dayIndex]) {
              const response = data.response;
              dataline[dayIndex][response] += 1;
            }
            // }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            // if (date.isBetween(startOfLastMonth, endOfLastMonth, 'day', '[]')) {
            const dayIndex = date.date() - 1;
            if (dataline[dayIndex]) {
              const response = data.response;
              dataline[dayIndex][response] += 1;
            }
            // }
          });
        }
        break;
      case "thisYear":
      case "lastYear":
        xAxisLabels = Array.from({ length: 12 }, (_, i) => moment().utc().month(i).format("MMM"));
        dataline = xAxisLabels.map((month) => ({
          name: month,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const targetYear =
              selectedValue === "thisYear"
                ? moment().utc().year()
                : selectedValue === "lastYear"
                  ? moment().utc().subtract(1, "year").year()
                  : moment().utc().subtract(1, "year").year();
            if (date.year() === targetYear) {
              const monthIndex = date.month();
              if (dataline[monthIndex]) {
                const response = data.response;
                dataline[monthIndex][response] += 1;
              }
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const targetYear =
              selectedValue === "thisYear"
                ? moment().utc().year()
                : selectedValue === "lastYear"
                  ? moment().utc().subtract(1, "year").year()
                  : moment().utc().subtract(1, "year").year();
            if (date.year() === targetYear) {
              const monthIndex = date.month();
              if (dataline[monthIndex]) {
                const response = data.response;
                dataline[monthIndex][response] += 1;
              }
            }
          });
        }
        break;

      case "lastSevenDays":
        const end = moment().utc();
        const start = moment().utc().subtract(7, "days");

        xAxisLabels = Array.from({ length: 8 }, (_, i) => getDateString(start.clone().add(i, "days")));
        dataline = xAxisLabels.map((day) => ({
          name: day,
          Great: 0,
          Okay: 0,
          Meh: 0,
          Low: 0,
          Struggling: 0,
        }));
        if (activeCategory) {
          const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
          filteredData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const dateString = getDateString(date);
            const index = xAxisLabels.indexOf(dateString);
            if (index !== -1) {
              const response = data.response;
              dataline[index][response] += 1;
            }
          });
        } else {
          employeeAnalyticsData?.forEach((data) => {
            const date = moment.unix(data.created_date_unix);
            const dateString = getDateString(date);
            const index = xAxisLabels.indexOf(dateString);
            if (index !== -1) {
              const response = data.response;
              dataline[index][response] += 1;
            }
          });
        }
        break;

      case "custom":
        if (startDate && endDate) {
          const startMoment = moment.unix(startDate).utc();
          const endMoment = moment.unix(endDate).utc();

          if (startMoment.isSame(endMoment, "day")) {
            // Render hourly data if the start and end dates are the same day
            xAxisLabels = Array.from({ length: 24 }, (_, i) => `${i}:00`);
            dataline = xAxisLabels.map((hour) => ({
              name: hour,
              Great: 0,
              Okay: 0,
              Meh: 0,
              Low: 0,
              Struggling: 0,
            }));
            if (activeCategory) {
              const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
              filteredData?.forEach((data) => {
                const date = moment.unix(data.created_date_unix);
                if (date.isSame(startMoment, "day")) {
                  const hourIndex = date.hour();
                  if (dataline[hourIndex]) {
                    const response = data.response;
                    dataline[hourIndex][response] += 1;
                  }
                }
              });
            } else {
              employeeAnalyticsData?.forEach((data) => {
                const date = moment.unix(data.created_date_unix);
                if (date.isSame(startMoment, "day")) {
                  const hourIndex = date.hour();
                  if (dataline[hourIndex]) {
                    const response = data.response;
                    dataline[hourIndex][response] += 1;
                  }
                }
              });
            }
          } else {
            const diff = endMoment.diff(startMoment, "days");

            if (diff >= 0) {
              xAxisLabels = Array.from({ length: diff + 1 }, (_, i) => getDateString(startMoment.clone().add(i, "days")));
              dataline = xAxisLabels.map((label) => ({
                name: label,
                Great: 0,
                Okay: 0,
                Meh: 0,
                Low: 0,
                Struggling: 0,
              }));
              if (activeCategory) {
                const filteredData = employeeAnalyticsData?.filter((data) => data.response === activeCategory);
                filteredData?.forEach((data) => {
                  const date = moment.unix(data.created_date_unix);
                  const dateString = getDateString(date);
                  const index = xAxisLabels.indexOf(dateString);
                  if (index !== -1) {
                    const response = data.response;
                    dataline[index][response] += 1;
                  }
                });
              } else {
                employeeAnalyticsData?.forEach((data) => {
                  const date = moment.unix(data.created_date_unix);
                  const dateString = getDateString(date);
                  const index = xAxisLabels.indexOf(dateString);
                  if (index !== -1) {
                    const response = data.response;
                    dataline[index][response] += 1;
                  }
                });
              }
            }
          }
        }
        break;

      default:
        console.error("Invalid selectedValue:", selectedValue);
        break;
    }

    return { dataline, xAxisLabels };
  };

  const { dataline, xAxisLabels } = getFormattedDataline(
    selectedValue,
    employeeAnalyticsData,
    dateRange?.startDate,
    dateRange?.endDate,
    activeCategory,
    isFilterApplied,
  );

  const formatYAxis = (value) => `${value}`;
  const formatTooltip = (value) => {
    return value;
  };
  // handle employee analytical categories
  const handleCategoryClick = (label) => {
    if (activeCategory === label) {
      setActiveCategory("");
    } else {
      setActiveCategory(label);
    }
  };

  // Get contact book list
  const getRecentEvaluations = () => {
    setLoading(true);
    let payload = {};

    ApiServices.recentEvaluations
      .getRecentEvaluations(payload)
      .then((res) => {
        const { data, message } = res;

        if (data?.code === 200) {
          setLoading(false);
          setEvaluatedData(data?.data?.data);
        }
      })
      .catch((err) => {});
  };

  // Recent Responses and Pending Forms
  const getRecentResponseAndPendingForms = (type = true) => {
    let payload = {};
    if (type) {
      setRecentResponseLoading(true);
    } else {
      setRecentPendingFormLoading(true);
    }
    ApiServices.dashboard
      .getRecentResponseAndPendingForms(payload)
      .then(({ data }) => {
        if (data.code === 200) {
          setRecentPendingFormData(data?.data?.consultationForms);
          setRecentResponsesData(data?.data?.latestUserResponse);

          if (type) {
            setRecentResponseLoading(false);
          } else {
            setRecentPendingFormLoading(false);
          }
        } else {
          setRecentPendingFormData([]);
          setRecentResponsesData([]);

          setRecentResponseLoading(false);
          setRecentPendingFormLoading(false);
        }
      })
      .catch(({ response }) => {
        setRecentPendingFormData([]);
        setRecentResponsesData([]);

        setRecentResponseLoading(false);
        setRecentPendingFormLoading(false);
      });
  };

  // Get Support Ticket Count
  const getSupportTicket = () => {
    setSupportTicketLoading(true);

    let payload = {
      start_date: dateRangeSupportTicket.startDate,
      end_date: dateRangeSupportTicket.endDate,
    };

    ApiServices.dashboard
      .getSupportTicketsCount(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          setSupportTicketData(data.data);
          setSupportTicketLoading(false);
        } else {
          setSupportTicketLoading(false);
        }
      })
      .catch((err) => {
        setSupportTicketLoading(false);
      });
  };

  const getMonthName = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000);
    return date.toLocaleString("default", { month: "short" }).replace(".", "");
  };

  // Forms Line Creation
  const initializeMonthlyData = () => {
    const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthlyData = {};
    months.forEach((month) => {
      monthlyData[month] = { pending: 0, filled: 0 };
    });
    return monthlyData;
  };

  // Forms Line Creation
  const lineGraphDataCreation = (apiData) => {
    const monthlyCounts = initializeMonthlyData();

    apiData?.forEach((item) => {
      const monthName = getMonthName(item.created_date_unix);

      if (item.is_pending === 1) {
        monthlyCounts[monthName].pending += 1;
      } else {
        monthlyCounts[monthName].filled += 1;
      }
    });

    // Convert the monthlyCounts object into an array format suitable for the graph
    const graphData = Object.keys(monthlyCounts).map((month) => {
      return {
        name: month,
        pending: monthlyCounts[month].pending,
        filled: monthlyCounts[month].filled,
      };
    });

    return graphData;
  };

  // Get Forms
  const getForms = () => {
    let payload = {
      start_date: dateRangeForm.startDate ? dateRangeForm.startDate : null,
      end_date: dateRangeForm.endDate ? dateRangeForm.endDate : null,
    };

    setLineGraphLoading(true);
    ApiServices.dashboard
      .getForms(payload)
      .then(({ data }) => {
        setFormCounts({
          total: data.data.total,
          fill: data.data.fill_count,
          pending: data.data.pending_count,
        });

        const graphData = lineGraphDataCreation(data?.data?.forms);
        setLineGraphLoading(false);
        setFormDataLine(graphData);
      })
      .catch(({ response }) => {});
  };

  // Use Effects
  // useEffect(() => {
  //   getEmployeeStatsDashboard();
  // }, [employeeAnalyticDateRange]);

  useEffect(() => {
    getSupportTicket();
  }, [dateRangeSupportTicket]);

  useEffect(() => {
    getForms();
  }, [dateRangeForm]);

  useEffect(() => {
    getRecentEvaluations();
  }, []);

  useEffect(() => {
    getRecentResponseAndPendingForms();
  }, []);

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const getDataByFilter = async (filters) => {
    const filterCount = Object.values(filters).reduce((count, filter) => {
      if (filter !== undefined) {
        if (typeof filter === "object" && filter !== null) {
          const innerCount = Object.values(filter).reduce((innerCount, innerFilter) => (innerFilter !== undefined ? innerCount + 1 : innerCount), 0);
          return count + (innerCount > 0 ? 1 : 0);
        } else {
          return count + 1;
        }
      }
      return count;
    }, 0);

    setAppliedFiltersCount(filterCount);
  };

  const getCampusList = () => {
    ApiServices.campus
      .getCampuses()
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          let settingsData = data?.data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];
          setGeneralCampusList(data?.data?.data);
          setAllCampuses(settingsData);
        }
      })
      .catch((err) => {});
  };

  const getDepartmentList = (campusValue) => {
    // const campusValue = selectedCampusOption?.value;

    // if (campusValue && campusValue !== "All") {
    let payload = {
      mode: 1,
      campus_id: campusValue,
    };

    ApiServices.department
      .getDepartments(payload)
      .then((res) => {
        const { data, message } = res;
        if (data.code === 200) {
          let settingsData = data?.data?.map((item) => ({
            label: item.name,
            value: item.id,
          }));

          settingsData = [{ label: "All", value: "All" }, ...settingsData];
          setGeneralDepartmentList(data?.data);
          setAllDepartments(settingsData);
        }
      })
      .catch((err) => {
        // Handle error here, if necessary
      });
    // }
  };

  // useEffect(()=>{

  //    if(selectedDepartmentOption?.label && selectedCampusOption?.label){
  //     const isDepartmentFounded = allCampuses
  //    }

  // },[selectedCampusOption, selectedDepartmentOption])

  // get employee stats by id
  const getEmployeeStatsDashboard = (sDate, eDate, sCampus, sDepartment) => {
    // console.log("dateRange.startDate",dateRange.startDate)
    setBarChartLoading(true);
    let payload = {
      start_date: sDate !== null ? sDate : sDate,
      end_date: eDate !== null ? eDate : eDate,
      department_id: sDepartment?.value === "All" ? "" : sDepartment?.value,
      campus_id: sCampus?.value === "All" ? "" : sCampus?.value,
    };

    ApiServices.dashboard
      .getEmployeeDashboardStats(payload)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBarChartLoading(false);
          setEmployeeReasonsData(data?.data);
          setEmployeeAnalyticsData(data?.data?.users_response);
          setFilterEmployee(data?.data?.employees);
        }
        setBarChartLoading(false);
      })
      .catch((err) => {
        setBarChartLoading(false);
      });
  };

  useEffect(() => {
    getCampusList();
    if (allCampusStatus !== 1 && allCampusStatusByRole !== "setting_user") {
      getDepartmentList(user?.user?.employee[0]?.campus?.id);
    } else {
      getDepartmentList();
    }
  }, []);

  // useEffect(() => {
  //   // if (selectedCampusOption?.value && selectedCampusOption?.value !== "All") {
  //   // getDepartmentList(selectedCampusOption?.value);
  //   // }if (selectedCampusOption?.value === "All") {
  //   //   getDepartmentList();
  //   //   } else {
  //   //   setAllDepartments([]);
  //   //   setSelectedDepartmentOption(null);
  //   // }
  // }, [selectedCampusOption]);

  useEffect(() => {
    // Trigger the API call when filters are cleared
    if (selectedCampusOption === null && selectedDepartmentOption === null && appliedFiltersCount === 0) {
      getEmployeeStatsDashboard(dateRange.startDate, dateRange.endDate, selectedCampusOption, selectedDepartmentOption);
    }
  }, [selectedCampusOption, selectedDepartmentOption, appliedFiltersCount]);

  const clearFilter = () => {
    setSelectedFilter("today");
    setSelectedCampus({ label: "All", value: "All" });
    setSelectedDepartment({ label: "All", value: "All" });
    setSelectedCampusOption(null);
    setSelectedDepartmentOption(null);
    setAppliedFiltersCount(0);
    setDateRange({
      startDate: Math.floor(today.start.getTime() / 1000),
      endDate: Math.floor(today.end.getTime() / 1000),
    });
    setSelectedValue("today");
    setDateError("");
    // setIsFilterApplied(false);
  };

  // const clearDraweFilters=(setSelectedFilter,setSelectedCampus,setSelectedDepartment)=>{
  //   // setSelectedDepartmentOption(null);
  //   setSelectedFilter("today");
  //   setSelectedCampus({ label: "All", value: "All" });
  //   setSelectedDepartment({ label: "All", value: "All" });
  // }

  return (
    <>
      <div className="grid min-h-[83vh] grid-cols-12 gap-4">
        <div className="card col-span-12 space-y-8">
          {/* <div className="flex items-center justify-between">
            <div className="w-[53%] space-y-2">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-secondary-color">
                  {employeeReasonsData?.healthy_percentage} <span className="font-medium">Settled</span>{" "}
                </h2>
                <h2 className="text-xl font-semibold text-secondary-color">
                  {employeeReasonsData?.unhealthy_percentage} <span className="font-medium">Unsettled</span>{" "}
                </h2>
              </div>
              <div className="w-full">
                <div className="relative mb-4 flex h-10 overflow-hidden rounded-full bg-[#F5F5F5] text-xs">
                  <div
                    className={`${employeeReasonsData?.healthy_percentage == "100%" ? "" : "clip-healthy"} `}
                    style={{ width: employeeReasonsData?.healthy_percentage, backgroundColor: "#8EE1C8" }}
                  ></div>
                  <div
                    className={` ${employeeReasonsData?.unhealthy_percentage == "100%" ? "" : "clip-unhealthy"} `}
                    style={{ width: employeeReasonsData?.unhealthy_percentage, backgroundColor: "#FF968D" }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="flex gap-x-3">
              <Button
                title="Evaluate Data"
                btnSmall
                onClick={() => {
                  setOpenEvaluatedModal({ open: true, data: null, editMode: false });
                }}
              />
              <RangePicker setRangePicker={setEmployeeAnalyticDateRange} width="xl:w-80" />
            </div>
          </div> */}

          <div className="mx-6 flex flex-row items-center justify-between gap-4 border-b-[1px] border-b-grayColor200 pb-5">
            <div className="flex flex-wrap items-center gap-5 self-start pt-1.5">
              <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
                <img src={images.AllCampusIcon} alt="filter icon" />
                <h2 className="text-sm font-medium text-secondary">
                  {selectedCampusOption ? (selectedCampusOption?.label === "All" ? "All Campuses" : selectedCampusOption?.label) : "All Campuses"}
                </h2>
              </div>
              <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
                <img src={images.AllDepartmentIcon} alt="filter icon" />
                <h2 className="text-sm font-medium text-secondary">
                  {selectedDepartmentOption
                    ? selectedDepartmentOption?.label === "All"
                      ? "All Departments"
                      : selectedDepartmentOption?.label
                    : "All Departments"}
                </h2>
              </div>
              <div className="flex items-center gap-x-2 rounded-full bg-secondary/10 px-4 py-1">
                <img src={images.CalenderIcon} alt="filter icon" />
                <h2 className="text-sm font-medium text-secondary">
                  {selectedValue === "today"
                    ? `Today (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                    : selectedValue === "yesterday"
                      ? `Yesterday (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                      : selectedValue === "lastSevenDays"
                        ? `Last 7 days (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")} - ${moment.unix(dateRange?.endDate).utc().format("MM/DD/YYYY")})`
                        : selectedValue === "lastMonth"
                          ? `Last month (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                          : selectedValue === "thisYear"
                            ? `This year (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                            : selectedValue === "lastYear"
                              ? `Last year (${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")})`
                              : selectedValue === "custom"
                                ? `(${moment.unix(dateRange?.startDate).utc().format("MM/DD/YYYY")} - ${moment.unix(dateRange?.endDate).utc().format("MM/DD/YYYY")})`
                                : `Today (${moment().utc().format("MM/DD/YYYY")})`}
                </h2>
              </div>
            </div>
            <div className="flex shrink-0 space-x-5 self-start">
              <Button
                title="Evaluate Data"
                btnSmall
                onClick={() => {
                  setOpenEvaluatedModal({ open: true, data: null, editMode: false });
                }}
              />
              {appliedFiltersCount > 0 ? (
                <button
                  type="button"
                  className="font-poppins flex h-9 w-56 items-center justify-start gap-x-3 rounded-full border border-lightGreenColor px-4 text-base font-medium focus:outline-none"
                >
                  <h2
                    className="shrink-0 text-base font-medium text-lightGreenColor"
                    onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                  >
                    {appliedFiltersCount} Active Filters
                  </h2>
                  <div className="h-3/4 border-l-2 border-grayColor200"></div>
                  <div className="flex items-center gap-x-1">
                    <img src={images.REDCLOSE} alt="Close" className="h-4 w-4" />
                    <h2 className="text-sm text-red-500" onClick={clearFilter}>
                      Clear
                    </h2>
                  </div>
                </button>
              ) : (
                <button
                  onClick={() => setOpenUserDrawerModal({ open: true, data: null })}
                  type="button"
                  className="font-poppins inline-flex h-9 w-40 items-center justify-start gap-x-5 rounded-full border border-transparent bg-lightGreenColor px-4 text-base font-medium text-white hover:bg-lightGreenColor/75 focus:outline-none"
                >
                  <AdjustmentsHorizontalIcon className="mr-2 h-5 w-5 text-white" /> Filter
                </button>
              )}
            </div>
          </div>
          <div className="flex items-center justify-between px-6">
            <div className="w-full space-y-2">
              <div className="flex items-center justify-between">
                <h2 className="text-xl font-semibold text-secondary-color">
                  {employeeReasonsData?.healthy_percentage} <span className="font-medium">Settled</span>{" "}
                </h2>
                <h2 className="text-xl font-semibold text-secondary-color">
                  {employeeReasonsData?.unhealthy_percentage} <span className="font-medium">Unsettled</span>{" "}
                </h2>
              </div>
              <div className="w-full">
                <div className="relative mb-4 flex h-10 overflow-hidden rounded-full bg-[#F5F5F5] text-xs">
                  <div
                    className={`${employeeReasonsData?.healthy_percentage == "100%" ? "" : "clip-healthy"} `}
                    style={{ width: employeeReasonsData?.healthy_percentage, backgroundColor: "#8EE1C8" }}
                  ></div>
                  <div
                    className={` ${employeeReasonsData?.unhealthy_percentage == "100%" ? "" : "clip-unhealthy"} `}
                    style={{ width: employeeReasonsData?.unhealthy_percentage, backgroundColor: "#FF968D" }}
                  ></div>
                </div>
              </div>
            </div>

            <div className="flex gap-x-3"></div>
          </div>
          <div className="grid grid-cols-12 gap-5 xl:divide-x">
            <div className="col-span-12 space-y-8 xl:col-span-7">
              <div className="flex items-center gap-x-4 pl-6">
                <div className="relative ">
                  <Heading
                    heading="Employee Wellness Analytics"
                    subheading={
                      moment.unix(dateRange?.startDate).utc().format("ddd, D MMM YYYY") +
                      " ~ " +
                      moment.unix(dateRange?.endDate).utc().format("ddd, D MMM YYYY")
                    }
                    icon
                    handleRefresh={() => {
                      setActiveCategory("");
                      getEmployeeStatsDashboard(dateRange.startDate, dateRange.endDate, selectedCampusOption, selectedDepartmentOption);
                    }}
                  />
                </div>
              </div>

              <div className="space-y-8">
                {barChartLoading ? (
                  <div className="grid grid-cols-5 gap-x-4">
                    <Skeleton count={1} height={150} borderRadius={16} />
                    <Skeleton count={1} height={150} borderRadius={16} />
                    <Skeleton count={1} height={150} borderRadius={16} />
                    <Skeleton count={1} height={150} borderRadius={16} />
                    <Skeleton count={1} height={150} borderRadius={16} />
                  </div>
                ) : (
                  <>
                    <div className="-ml-8 grid grid-cols-5 items-center">
                      {categories.map((category) => (
                        <div className="relative" key={category.id} onClick={() => handleCategoryClick(category.label)}>
                          <div
                            onMouseEnter={() => {
                              setShowFilterData(true);
                              setCategoryName(category.label);
                            }}
                            onMouseLeave={() => setShowFilterData(false)}
                            className={`flex cursor-pointer flex-col items-center gap-y-2 ${activeCategory && activeCategory !== category.label ? "opacity-50" : ""}`}
                          >
                            <img src={category?.logo} alt="logo" className="h-12 w-12" />
                            <h2 className="text-2xl font-medium text-secondary">{category?.count}</h2>
                            <h2 className={`${category?.classNameColor} text-sm font-medium uppercase 2xl:text-base`}>{category?.label}</h2>
                          </div>
                        </div>
                      ))}

                      {showFilterData &&
                        filterEmployee !== null &&
                        filterEmployee?.filter((employee) => employee?.name === categoryName)?.length > 0 && (
                          <div
                            onMouseEnter={() => setShowFilterData(true)}
                            onMouseLeave={() => setShowFilterData(false)}
                            className={`absolute ${
                              categoryName === "Great"
                                ? "top-[20rem] xl:left-16 2xl:left-24"
                                : categoryName === "Okay"
                                  ? "top-[20rem] xl:left-[13rem] 2xl:left-[17rem] "
                                  : categoryName === "Meh"
                                    ? "top-[20rem] xl:left-[20rem] 2xl:left-[29rem]"
                                    : categoryName === "Low"
                                      ? "top-[20rem] xl:left-[27rem] 2xl:left-[40rem]"
                                      : categoryName === "Struggling"
                                        ? " top-[20rem] xl:left-[37rem] 2xl:left-[53rem]"
                                        : ""
                            }  z-20 max-h-52 w-40 overflow-y-auto rounded-md bg-white py-4 px-3 shadow-filter-employee`}
                          >
                            {filterEmployee
                              ?.filter((employee) => employee?.name === categoryName)
                              .map(
                                (item, index) =>
                                  item?.members?.length > 0 &&
                                  item?.members?.map((member) => (
                                    <div
                                      onClick={() =>
                                        navigate(member?.user_id ? `/employees/${member?.user_id}` : "", {
                                          replace: true,
                                        })
                                      }
                                      className="mb-2 flex cursor-pointer items-center gap-x-1.5"
                                      key={index}
                                    >
                                      <div className="h-8 w-8 shrink-0 rounded-full">
                                        <img
                                          src={member?.profile_image_url ? employeeMediaUrl + member?.profile_image_url : images.placeholder}
                                          alt="employee profile"
                                          className="h-full w-full rounded-full object-cover"
                                        />
                                      </div>
                                      <h2 className="truncate text-sm text-primary-color-200">{member?.name}</h2>
                                    </div>
                                  )),
                              )}
                          </div>
                        )}
                    </div>
                  </>
                )}
                {barChartLoading ? (
                  <div className="grid grid-cols-1 gap-4">
                    {[...Array(1)].map((_, index) => (
                      <Skeleton key={index} count={1} height={350} borderRadius={16} />
                    ))}
                  </div>
                ) : (
                  <>
                    <div className="-ml-10">
                      <ResponsiveContainer width="100%" height={420}>
                        <BarChart
                          width={500}
                          height={300}
                          data={dataline}
                          margin={{
                            top: 5,
                            right: 30,
                            left: 20,
                            bottom: 5,
                          }}
                          barSize={30}
                          barGap={5}
                        >
                          <CartesianGrid strokeDasharray="3 3" vertical={false} />
                          <XAxis dataKey="name" tickLine={false} />
                          <YAxis tickLine={false} tickFormatter={formatYAxis} />
                          <Tooltip formatter={formatTooltip} />
                          {/* <Legend /> */}
                          <Bar dataKey="Great" fill="#FFAE9B" radius={[12, 12, 0, 0]} />
                          <Bar dataKey="Okay" fill="#FFBC96" radius={[12, 12, 0, 0]} />
                          <Bar dataKey="Meh" fill="#FFDCB8" radius={[12, 12, 0, 0]} />
                          <Bar dataKey="Low" fill="#AAE9CD" radius={[12, 12, 0, 0]} />
                          <Bar dataKey="Struggling" fill="#264254" radius={[12, 12, 0, 0]} />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="col-span-12 xl:col-span-5">
              <div className="relative flex items-center justify-between pl-6 2xl:pl-12">
                <div className="relative">
                  <Heading
                    heading="Reasons"
                    subheading="Meh, Low, Struggling"
                    icon
                    handleRefresh={() => {
                      getEmployeeStatsDashboard(dateRange.startDate, dateRange.endDate, selectedCampusOption, selectedDepartmentOption);
                    }}
                  />
                </div>
              </div>
              {barChartLoading ? (
                <div className="px-12 pt-4">
                  <Skeleton count={1} height={250} borderRadius={16} />
                </div>
              ) : (
                <>
                  <div className="flex flex-col items-center gap-y-3 ">
                    <div>
                      <ResponsiveContainer width={260} height={320}>
                        <PieChart
                          margin={{
                            top: 5,
                            right: 5,
                            bottom: 5,
                            left: 0,
                          }}
                        >
                          <Pie
                            margin={{
                              top: 5,
                              right: 5,
                              bottom: 5,
                              left: 0,
                            }}
                            data={data2}
                            height={220}
                            width={300}
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            fill="#d6d6d6"
                            dataKey="value"
                          >
                            {data2.map((entry, index) => (
                              <Cell key={`cell-${index}`} fill={entry.color} stroke="none" className="border-none outline-none" />
                            ))}
                          </Pie>

                          {(poorSleep !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (Unwell !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (relationshipProblems !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (workRelatedStres !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (homeLifeDifficulties !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (bereavement !== 0 && <Tooltip content={<CustomTooltip />} />) ||
                            (mentleHealthAbsense !== 0 && <Tooltip content={<CustomTooltip />} />)}
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </>
              )}
              {barChartLoading ? (
                <div className="px-12 pt-4">
                  <Skeleton count={1} height={250} borderRadius={16} />
                </div>
              ) : (
                <>
                  <div className="space-y-6 pl-12">
                    <div className="grid w-full grid-cols-12 items-center justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.SleepIcon} alt="poor sleep" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.poor_sleep_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Poor Sleep</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.HomeLifeIcon} alt="Home Life Difficulties" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.home_life_diffuculties_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Home Life Difficulties</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.RelationIcon} alt="Relationship Problems" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.relationship_problem_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Relationship Problems</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.UnWellIcon} alt="Unwell" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.unwell_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Unwell</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-6 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.BereavementIcon} alt="Bereavement" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.bereavement_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Bereavement</p>
                          </div>
                        </div>
                      </div>
                      <div className="col-span-6">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.StressIcon} alt="Work Related Stress" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.work_related_stress_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Work Related Stress</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid w-full grid-cols-12 justify-between gap-x-1 ">
                      <div className="col-span-12 ">
                        <div className="flex items-center gap-x-1">
                          <div>
                            <img src={images.MentleHealth} alt="Mental Health Rest Day" className="h-10 w-10" />
                          </div>
                          <div>
                            <h2 className="font-bold text-secondary ">{employeeReasonsData?.mars_absence_count_percentage}</h2>
                            <p className="text-sm font-medium text-grayColor300">Mental Health Rest Day</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="card col-span-5 space-y-10 md:col-span-12 xl:col-span-5 2xl:col-span-5">
          <div className="flex justify-between">
            <div className="relative">
              <Heading
                heading="Support Tickets"
                subheading="Highlights of open & closed tickets"
                icon
                handleRefresh={() => {
                  getSupportTicket("");
                }}
              />
            </div>
            <RangePicker setRangePicker={setDateRangeSupportTicket} width="2xl:w-80 xl:w-76" />
          </div>

          {supportTicketLoading ? (
            <div className="grid grid-cols-2 gap-4">
              {[...Array(2)].map((_, index) => (
                <Skeleton key={index} count={1} height={300} borderRadius={16} />
              ))}
            </div>
          ) : (
            <>
              <div className="flex justify-evenly">
                <div>
                  <ResponsiveContainer width={220} height={200}>
                    <PieChart margin={{ top: 5, right: 5, bottom: 5, left: 0 }}>
                      <Pie data={dataPie2} cx="50%" cy="50%" innerRadius={innerRadius} outerRadius={outerRadius} fill="#d6d6d6" dataKey="value">
                        {dataPie2.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.color} stroke="none" className="border-none outline-none" />
                        ))}
                      </Pie>
                      {supportTicketData && // Checks if supportTicketData exists and is not null or undefined
                        parseFloat(supportTicketData.open_tickets) !== 0 &&
                        parseFloat(supportTicketData.closed_tickets) !== 0 && <Tooltip content={<CustomTooltip2 />} />}
                    </PieChart>
                  </ResponsiveContainer>
                </div>

                <div className="flex flex-col justify-center">
                  <div className="flex items-center ">
                    <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#FFBF9D] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                    <div className="">
                      <span className="text-base text-grayColor300 ">Open</span>
                      <span className="text-black opacity-30"> ---------- </span>
                      <span className="text-sm font-medium text-secondary">{supportTicketData ? supportTicketData.open_percentage : "0%"}</span>{" "}
                    </div>
                  </div>
                  <div className="flex items-center ">
                    <div className="mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#264254] lg:mr-3 2xl:mr-4 2xl:h-4 2xl:w-4"></div>
                    <div className="">
                      <span className="text-base text-grayColor300 ">Closed</span>
                      <span className="text-black opacity-30"> -------- </span>
                      <span className="text-sm font-medium text-secondary">
                        {supportTicketData ? supportTicketData.closed_percentage : "0%"}
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>

        <div className="card col-span-7 w-full space-y-10 md:col-span-12 xl:col-span-7">
          <div className="flex items-center justify-between">
            <div className="relative">
              <Heading
                heading="Forms"
                subheading="Highlights of filled & pending forms"
                icon
                handleRefresh={() => {
                  getForms("");
                }}
              />
            </div>
            <RangePicker setRangePicker={setDateRangeForm} width="xl:w-80" />
          </div>
          <div className="flex items-center justify-center">
            <div className="flex cursor-pointer items-center justify-center">
              <div
                className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#264254] lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                style={{ backgroundColor: "#264254" }}
              ></div>
              <span className={"text-sm text-grayColor300"}>Total ({formCounts.total})</span>
            </div>
            <div className="flex cursor-pointer items-center justify-center">
              <div
                className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#B6E7CF] lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                style={{ backgroundColor: "#B6E7CF" }}
              ></div>
              <span className={"text-sm text-grayColor300"}>Filled ({formCounts.fill})</span>
            </div>
            <div className="flex cursor-pointer items-center justify-center">
              <div
                className={`ml-3 mr-3 block h-3.5 w-3.5 shrink-0 rounded-full bg-[#FFBF9D] lg:mr-3 2xl:mr-2 2xl:h-4 2xl:w-4`}
                style={{ backgroundColor: "#FFBF9D" }}
              ></div>
              <span className={"text-sm text-grayColor300"}>Pending ({formCounts.pending})</span>
            </div>
          </div>
          {lineGraphLoading ? (
            <div className="grid grid-cols-1 gap-4">
              {[...Array(1)].map((_, index) => (
                <Skeleton key={index} count={1} height={250} borderRadius={16} />
              ))}
            </div>
          ) : (
            <>
              <div className="-ml-16 3xl:-ml-12">
                <ResponsiveContainer width="100%" height={250}>
                  <LineChart
                    width={500}
                    height={300}
                    data={formDataLine}
                    margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" vertical={false} />
                    <XAxis dataKey="name" tickLine={false} />
                    <YAxis tickLine={false} tickFormatter={formatYAxis} />
                    <Tooltip formatter={formatTooltip} />

                    <Line type="monotone" dataKey="pending" strokeWidth={2} dot={false} stroke="#FCEBB0" activeDot={{ r: 6 }} />
                    <Line type="monotone" dataKey="filled" strokeWidth={2} dot={false} stroke="#B6E7CF" activeDot={{ r: 6 }} />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </>
          )}
        </div>

        {(user?.user?.permissions?.includes("dashboard-view-recent-responses") || user?.user?.role === "setting_user") && (
          <div className="card col-span-12 xl:col-span-6">
            <div className="flex items-center justify-between gap-x-4">
              <Heading
                heading="Recent Evaluations "
                icon
                handleRefresh={() => {
                  getRecentEvaluations("");
                }}
              />

              <button
                className="border-b border-secondary text-sm font-medium text-secondary"
                type="button"
                onClick={() => {
                  navigate("/recent-evaluations");
                }}
              >
                View All
              </button>
            </div>
            <div className="pt-8">
              <div className="-mx-6 mb-8 overflow-x-auto">
                <table className="w-full text-left">
                  <thead>
                    <tr>
                      {TABLE_HEAD_RECENT_EVALUATIONS?.map((head) => (
                        <th
                          key={head}
                          className="border-b border-gray-100 bg-white p-4 first:pl-6"
                          onClick={() => {
                            let sortKey;
                            if (head === "Created at") {
                              sortKey = "created_at_unix";
                            } else if (head === "Last updated at") {
                              sortKey = "updated_at_unix";
                            } else if (head === "Created by") {
                              sortKey = "createdBy.evaluation";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            requestSort(sortKey);
                          }}
                        >
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {sortConfig.key ===
                              (head === "Created at"
                                ? "created_at_unix"
                                : head === "Last updated at"
                                  ? "updated_at_unix"
                                  : head === "Created by"
                                    ? "createdBy.evaluation"
                                    : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                            ) : (
                              <ChevronDownIcon className="inline-block h-4 w-3.5" />
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="4">
                          <Skeleton count={itemsPerPage} height={50} />
                        </td>
                      </tr>
                    ) : items?.length > 0 ? (
                      items?.map((item, index) => (
                        <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                          <td className="py-4 pl-6 pr-4">
                            <p id={`createdAtDate${index + 1}`} className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                              {moment.unix(item?.created_at_unix).format("MMM D YYYY")}
                            </p>
                            <ReactTooltip
                              anchorSelect={`#createdAtDate${index + 1}`}
                              id={`created-at-date-tooltip${index + 1}`}
                              offset={5}
                              className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                              classNameArrow="hidden"
                              effect="solid"
                              place="bottom"
                            >
                              <span>{moment.unix(item?.created_at_unix).format("MMM D YYYY h:mm A")}</span>
                            </ReactTooltip>
                          </td>

                          <td className="py-4 pl-4 3xl:px-4">
                            <p id={`updateAtDate${index + 1}`} className="cursor-pointer text-xs font-normal text-primary-color-200 3xl:text-sm">
                              {moment.unix(item?.updated_at_unix).format("MMM D YYYY")}
                            </p>
                            <ReactTooltip
                              anchorSelect={`#updateAtDate${index + 1}`}
                              id={`updated-at-date-tooltip${index + 1}`}
                              offset={5}
                              className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                              classNameArrow="hidden"
                              effect="solid"
                              place="bottom"
                            >
                              <span>{moment.unix(item?.updated_at_unix).format("MMM D YYYY h:mm A")}</span>
                            </ReactTooltip>
                          </td>

                          <td className="py-4 pl-4 3xl:px-4">
                            <div className="flex items-center gap-x-1">
                              <div className="h-5 w-5">
                                <img
                                  src={
                                    item?.user?.nursery?.length > 0 && item?.user?.nursery[0]?.logo !== null
                                      ? mediaUrl + item?.user?.nursery[0]?.logo
                                      : item?.user?.evaluation_employee[0]?.profile_image_url !== null
                                        ? employeeMediaUrl + item?.user?.evaluation_employee[0]?.profile_image_url
                                        : images.placeholder
                                  }
                                  alt="profile"
                                  className="h-full w-full rounded-full object-contain"
                                />
                              </div>
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                                {item?.user?.nursery?.length > 0 ? item?.user?.nursery[0]?.name : item?.user?.evaluation_employee[0]?.name}
                              </p>
                            </div>
                          </td>

                          <td className="py-4 pl-4 3xl:px-4">
                            <span
                              className="cursor-pointer text-xs font-normal text-accent underline underline-offset-4 3xl:text-sm"
                              onClick={() => setOpenReadMoreMessageModal({ open: true, data: item })}
                            >
                              View
                            </span>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className=" h-[300px]">
                        <td colSpan="4">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}

        {(user?.user?.permissions?.includes("dashboard-view-pending-forms") || user?.user?.role === "setting_user") && (
          <div className="card col-span-12 xl:col-span-6">
            <div className="flex items-center gap-x-4">
              <Heading
                heading="Recent Forms"
                icon
                handleRefresh={() => {
                  getRecentResponseAndPendingForms(false);
                }}
              />
            </div>
            <div className="pt-8">
              <div className="-mx-6  h-[37vh] overflow-auto xl:h-[50vh] 3xl:h-[37vh]">
                <table className="w-full text-left">
                  <thead className="sticky top-0 z-10 bg-white">
                    <tr>
                      {TABLE_HEAD_RECENT_PENDING_FORMS?.map((head) => (
                        <th
                          key={head}
                          className="border-b border-gray-100 bg-white p-4 first:pl-4"
                          onClick={() => {
                            let sortKey;
                            if (head === "Emp ID") {
                              sortKey = "employee.id";
                            } else if (head === "Name") {
                              sortKey = "employee.name";
                            } else if (head === "Forms") {
                              sortKey = "title";
                            } else if (head === "Date") {
                              sortKey = "created_date_unix";
                            } else {
                              sortKey = head.toLowerCase();
                            }
                            requestSort2(sortKey);
                          }}
                        >
                          <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                            {head}
                            {sortConfig2.key ===
                              (head === "Emp ID"
                                ? "employee.id"
                                : head === "Name"
                                  ? "employee.name"
                                  : head === "Forms"
                                    ? "title"
                                    : head === "Date"
                                      ? "created_date_unix"
                                      : head.toLowerCase()) && sortConfig2.direction === "asc" ? (
                              <ChevronUpIcon className="inline-block h-4 w-3.5" />
                            ) : (
                              <ChevronDownIcon className="inline-block h-4 w-3.5" />
                            )}
                          </p>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {recentPendingFormLoading ? (
                      <tr>
                        <td colSpan="5">
                          <Skeleton count={itemsPerPage} height={50} />
                        </td>
                      </tr>
                    ) : items2.length > 0 ? (
                      items2.map((item, index) => (
                        <tr key={item?.id} className="transition-all duration-300 even:bg-gray-50 hover:bg-secondary/10">
                          <td className="py-4 pl-4 3xl:px-4">
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.user?.employee[0]?.emp_id}</p>
                          </td>

                          <td className="py-4 pl-4 3xl:px-4">
                            <div
                              className="flex cursor-pointer items-center gap-x-1"
                              onClick={() =>
                                navigate(item?.user?.employee[0]?.user_id ? `/employees/${item?.user?.employee[0]?.user_id}` : "", {
                                  replace: true,
                                })
                              }
                            >
                              <div className="h-6 w-6">
                                <img
                                  src={
                                    item?.user?.employee[0]?.profile_image_url !== null
                                      ? employeeMediaUrl + item?.user?.employee[0]?.profile_image_url
                                      : images.placeholder
                                  }
                                  alt=""
                                  className="h-full w-full rounded-full object-contain"
                                />
                              </div>
                              <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.user?.employee[0]?.name}</p>
                            </div>
                          </td>
                          <td className="py-4 pl-4 3xl:px-6">
                            <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">{item?.title}</p>
                          </td>
                          <td className="py-3 pl-4 3xl:px-4">
                            {item?.is_pending === 1 ? (
                              <button
                                className="cursor-pointer rounded-md bg-secondary px-4 py-1.5 text-10 text-white 2xl:text-xs"
                                type="button"
                                onClick={() => setOpenCheckInFormFillModal({ open: true, data: item?.id })}
                              >
                                Fill
                              </button>
                            ) : (
                              <p
                                className="cursor-pointer pl-2 text-10 font-normal text-secondary underline 2xl:text-xs"
                                onClick={() => setOpenConsultationFormModal({ open: true, data: item })}
                              >
                                View
                              </p>
                            )}
                          </td>
                          <td className="py-4 pl-4 3xl:px-4">
                            <p id="responseDate" className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                              {moment.unix(item?.created_date_unix).format("D MMM YYYY ")}
                            </p>
                            <Tooltip
                              anchorSelect="#responseDate"
                              id="#responseDate-tooltip"
                              offset={5}
                              className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                              classNameArrow="hidden"
                              effect="solid"
                              place="bottom"
                            >
                              <span>{moment.unix(item?.created_date_unix).format("D MMM YYYY h:mm A")}</span>
                            </Tooltip>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr className=" h-[300px]">
                        <td colSpan="4">
                          <Lottie options={emptyFolderAnimation} width={200} height={200} />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        )}
      </div>

      <EvaluateReadMoreModal
        onSuccess={getRecentEvaluations}
        data={openReadMoreMessageModal.data}
        isOpenReadMore={openReadMoreMessageModal.open}
        setIsOpenReadMore={(isOpenReadMore) => setOpenReadMoreMessageModal({ ...openReadMoreMessageModal, open: isOpenReadMore })}
      />

      <AddEvaluatedModal
        editMode={openEvaluatedModal?.editMode}
        data={openEvaluatedModal?.data}
        onSuccess={getRecentEvaluations}
        isOpen={openEvaluatedModal.open}
        setIsOpen={(isOpen) => setOpenEvaluatedModal({ ...openEvaluatedModal, open: isOpen })}
      />

      <Drawer
        isOpen={openUserDrawerModal.open}
        setIsOpen={(open) => setOpenUserDrawerModal((prev) => ({ ...prev, open }))}
        onApplyFilters={getDataByFilter}
        allCampuses={allCampuses}
        allDepartments={allDepartments}
        setDateRange={setDateRange}
        dateRange={dateRange}
        setSelectedDepartmentOption={setSelectedDepartmentOption}
        selectedDepartmentOption={selectedDepartmentOption}
        setSelectedSettingOption={setSelectedSettingOption}
        selectedSettingOption={selectedSettingOption}
        setSelectedCampusOption={setSelectedCampusOption}
        selectedCampusOption={selectedCampusOption}
        selectedValue={selectedValue}
        setSelectedValue={setSelectedValue}
        api={getEmployeeStatsDashboard}
        clearFilter={clearFilter}
        setIsFilterApplied={setIsFilterApplied}
        dateError={dateError}
        setDateError={setDateError}
        isFilterApplied={isFilterApplied}
        setAllDepartments={setAllDepartments}
        getDepartmentList={getDepartmentList}
        // clearDraweFilters={clearDraweFilters}
        selectedFilter={selectedFilter}
        setSelectedFilter={setSelectedFilter}
        selectedCampus={selectedCampus}
        setSelectedCampus={setSelectedCampus}
        selectedDepartment={selectedDepartment}
        setSelectedDepartment={setSelectedDepartment}
      />

      <ViewConsultationFormModal
        isOpen={openConsultationFormModal?.open}
        data={openConsultationFormModal?.data}
        setIsOpen={(open) => setOpenConsultationFormModal((prev) => ({ ...prev, open }))}
      />

      <CheckInFormFillModal
        data={openCheckInFormFillModal.data}
        isOpen={openCheckInFormFillModal.open}
        setIsOpen={(open) => setOpenCheckInFormFillModal((prev) => ({ ...prev, open }))}
        onSuccess={() => getRecentResponseAndPendingForms(false)}
      />

      {/* <SubscriptionPlansModal
        isOpen={openSubscriptionPlansModal.open}
        setIsOpen={(open) => setOpenSubscriptionPlansModal((prev) => ({ ...prev, open }))}
      /> */}
    </>
  );
};

export default Dashboard;
