import moment from "moment";
import Lottie from "react-lottie";
import { Tooltip } from "react-tooltip";
import ApiServices from "../api/services";
import ReactPaginate from "react-paginate";
import Heading from "../components/Heading";
import ReactDatePicker from "react-datepicker";
import Skeleton from "react-loading-skeleton";
import { useMediaQuery } from "react-responsive";
import { images } from "../assets/styles/Images";
import { employeeMediaUrl } from "../utils/config";
import React, { useEffect, useState } from "react";
import RangePicker from "../components/RangePicker";
import { useLoggedContext } from "../context/provider";
import animationData from "../assets/lotties/empty-folder";
import { useSortableData } from "../hooks/useSortableData";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { HonestWeekPicker } from "../components/WeekDatePicker";
import CauseForConcernModal from "../components/CauseForConcernModal";
import { ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, ChevronUpIcon, MagnifyingGlassIcon } from "@heroicons/react/20/solid";

// Table Head
const TABLE_HEAD = ["Employee ID", "Employees", "Feeling", "Reason", "Date"];

const CauseForConcern = () => {
  // Context
  const { loading, setLoading, employeesListing } = useLoggedContext();

  // useStates
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [searchText, setSearchText] = useState("");
  const [causeForConcernList, setCauseForConcernList] = useState([]);

  const [openCauseForConcernModal, setOpenCauseForConcernModal] = useState({ open: false, data: null });

  // Change handler for date picker
  const onDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  // <------- Table Sorting ----------->

  // Active Table Sorting
  const { items, requestSort, sortConfig } = useSortableData(causeForConcernList);

  // Media Queries
  const isLaptop = useMediaQuery({ minWidth: 1024 });
  const isLaptopMedium = useMediaQuery({ minWidth: 1536 });
  const isLargeScreenLaptop = useMediaQuery({ minWidth: 1700 });
  const itemsPerPage = isLargeScreenLaptop ? 10 : isLaptopMedium ? 7 : isLaptop ? 7 : 11;

  // Get cause for concern list
  const getCauseForConcern = async (emptySearch) => {
    setLoading(true);
    let payload = {
      search: emptySearch ? "" : searchText,
      page: currentPage,
      start_date: dateRange.startDate ? dateRange.startDate : null,
      end_date: dateRange.endDate ? dateRange.endDate : null,
      records_no: itemsPerPage,
    };

    const res = await ApiServices.causeForConcern
      .getCauseForConcern(payload)
      .then((res) => {
        const { data, message } = res;
         
        if (data.code === 200) {
          setLoading(false);
          setCauseForConcernList(data?.data?.data);
          // setCurrentPage(data?.data?.current_page);
          // setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));

          // const hasLowFeelItems = data?.data?.data.some((item) => item.low_feel.length > 0);
          // if (hasLowFeelItems) {
          //   setCurrentPage(data?.data?.current_page);
          //   setTotalPages(Math.ceil(data?.data?.total / data?.data?.per_page));
          // }
        }
      })
      .catch((err) => {
         
      });
  };

  // Handle Search
  const handleSearch = async (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      if (searchText.trim() !== "") {
        await getCauseForConcern();
        setCurrentPage(1);
      }
    }
  };

  // useEffects

  // useEffect(() => {
  //   // Calculate the start and end dates for the current week
  //   const startOfWeek = moment().startOf("week");
  //   const endOfWeek = moment().endOf("week");

  //   // Set the state with the calculated dates
  //   setStartDate(startOfWeek.toDate());
  //   setEndDate(endOfWeek.toDate());
  // }, []);

  useEffect(() => {
      
      
    if(currentPage && dateRange.startDate && dateRange.endDate){

      getCauseForConcern();
    }
  }, [currentPage, dateRange]);

  // <-------- pagination ----------->

  // All Settings Listing
  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected + 1);
  };

  // Lottie Option
  const emptyFolderAnimation = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  // const [week, setWeek] = useState({});

  // const onChange = (week) => {
  //   setWeek(week);
  // };

  return (
    <>
      <div className="card h-[82vh] 3xl:h-[84vh]">
        <div className="mb-6">
          <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6 bg-white md:flex-row md:items-center">
            <div className="flex gap-x-3">
              <Heading
                heading="Employees with Multiple Low Check-ins"
                icon
                handleRefresh={() => {
                  getCauseForConcern();
                }}
              />
            </div>
            {/* Search */}

            <div className="flex items-center">
              <RangePicker setRangePicker={setDateRange} width="2xl:w-80 xl:w-76" />

              {/* <HonestWeekPicker onChange={onChange} /> */}

              <div className="relative ml-2 flex items-center 3xl:ml-4">
                <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                  <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                </div>

                <input
                  type="search"
                  id="search"
                  name="search"
                  placeholder="Search..."
                  autocomplete="off"
                  value={searchText}
                  onChange={(e) => {
                    setSearchText(e.target.value);
                    if (e.target.value.trim() === "") {
                      getCauseForConcern(true);
                    }
                  }}
                  onKeyDown={handleSearch}
                  className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 py-2 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100"
                />
              </div>
              {/* select */}
            </div>
          </div>
        </div>

        <div className="-mx-6 mb-8 overflow-x-auto">
          <table className="w-full text-left">
            <thead>
              <tr>
                {TABLE_HEAD.map((head) => (
                  <th
                    key={head}
                    className="w-38 border-b border-gray-100 bg-white p-4 first:pl-6"
                    onClick={() => {
                      let sortKey;
                      if (head === "Employee ID") {
                        sortKey = "employee.id";
                      } else if (head === "Employees") {
                        sortKey = "employee.name";
                      } else if (head === "Feeling") {
                        sortKey = "Low";
                      } else if (head === "Reason") {
                        sortKey = "reason";
                      } else if (head === "Date") {
                        sortKey = "reasonDate";
                      } else {
                        sortKey = head.toLowerCase();
                      }
                      requestSort(sortKey);
                    }}
                  >
                    <p className="cursor-pointer whitespace-nowrap font-inter text-xs font-semibold leading-5 3xl:text-sm">
                      {head}
                      {sortConfig.key ===
                        (head === "Employee ID"
                          ? "employee.id"
                          : head === "Employees"
                            ? "employee.name"
                            : head === "Feeling"
                              ? "Low"
                              : head === "Reason"
                                ? "reason"
                                : head === "Date"
                                  ? "reasonDate"
                                  : head.toLowerCase()) && sortConfig.direction === "asc" ? (
                        <ChevronUpIcon className="inline-block h-4 w-3.5" />
                      ) : (
                        <ChevronDownIcon className="inline-block h-4 w-3.5" />
                      )}
                    </p>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan="6">
                    <Skeleton count={itemsPerPage} height={50} />
                  </td>
                </tr>
              ) : items?.length > 0 ? (
                items
                  ?.filter((item) => item?.low_feel && item?.low_feel?.length > 0)
                  .map((item, index) => (
                    <tr key={item?.id} className="even:bg-gray-50">
                      <td className="py-3 pl-6 pr-4">
                        <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                          {item?.employee[0]?.emp_id ? item?.employee[0]?.emp_id : "N/A"}
                        </p>
                      </td>
                      <td className="py-3 pl-4 3xl:px-4">
                        <div className="flex items-center">
                          <div className="mr-3 h-8 w-8 rounded-full">
                            <img
                              className="mr-3 h-full w-full rounded-full"
                              src={
                                item?.employee[0]?.profile_image_url ? employeeMediaUrl + item?.employee[0]?.profile_image_url : images.placeholder
                              }
                              alt="Profile"
                            />
                          </div>
                          <p className="text-xs font-normal text-primary-color-200 3xl:text-sm">
                            {item?.employee[0]?.name ? item?.employee[0]?.name : "N/A"}
                          </p>
                        </div>
                      </td>
                      <td className="py-3 pl-4 3xl:px-4">
                        <div className="flex items-center gap-x-1">
                          <img src={images.LowIcon} alt="logo" className="h-6 w-6" />
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">Low</p>
                        </div>
                      </td>

                      <td className="py-3 pl-4 3xl:px-4">
                        <div className="flex cursor-pointer items-center gap-x-1">
                          <img
                            src={
                              item?.user_response[0]?.reason === "Relationship Problems"
                                ? images.RelationIcon
                                : item?.user_response[0]?.reason === "Poor Sleep"
                                  ? images.SleepIcon
                                  : item?.user_response[0]?.reason === "Unwell"
                                    ? images.UnWellIcon
                                    : item?.user_response[0]?.reason === "Work Related Stress"
                                      ? images.StressIcon
                                      : item?.user_response[0]?.reason === "Bereavement"
                                        ? images.BereavementIcon
                                        : item?.user_response[0]?.reason === "Home Life Difficulties"
                                          ? images.HomeLifeIcon
                                          : item?.user_response[0]?.reason === "Mental Health Rest Day"
                                            ? images.MentleHealth
                                            : images.placeholder
                            }
                            alt="logo"
                            className="h-6 w-6"
                          />
                          <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                            {item?.user_response[0]?.reason || "N/A"}
                            {item?.user_response.length > 1 && (
                              <span className="pl-1 underline" onClick={() => setOpenCauseForConcernModal({ open: true, data: item })}>
                                +more
                              </span>
                            )}
                          </p>
                        </div>
                      </td>
                      <td className="py-3 pl-4 3xl:px-4">
                        <p className="text-10 font-normal text-primary-color-200 2xl:text-xs ">
                          {moment.unix(parseInt(item?.low_feel[0]?.submittedAt)).format("D MMM YYYY h:mm A") || "N/A"}
                        </p>
                      </td>
                    </tr>
                  ))
              ) : (
                // Render "No Data" message
                <tr className="h-[500px]">
                  <td colSpan="6">
                    <Lottie options={emptyFolderAnimation} width={200} height={200} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <div className="absolute bottom-4">
          <ReactPaginate
            breakLabel="..."
            pageRangeDisplayed={5}
            marginPagesDisplayed={2}
            activeClassName="active"
            nextClassName="item next"
            renderOnZeroPageCount={null}
            breakClassName="item break-me "
            containerClassName="pagination"
            previousClassName="item previous"
            pageCount={totalPages}
            pageClassName="item pagination-page"
            forcePage={currentPage - 1}
            onPageChange={handlePageChange}
            nextLabel={<ChevronRightIcon className="h-5 w-5" />}
            previousLabel={<ChevronLeftIcon className="h-5 w-5" />}
          />
        </div>
      </div>

      {/* Cause for concern modal*/}
      <CauseForConcernModal
        data={openCauseForConcernModal.data}
        isOpen={openCauseForConcernModal.open}
        setIsOpen={(isOpen) => setOpenCauseForConcernModal({ ...openCauseForConcernModal, open: isOpen })}
      />
    </>
  );
};

export default CauseForConcern;
