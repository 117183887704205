import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getContactBook = async (data) => {
  try {
    let response = await axios.get(GET.CONTACT_BOOK_LIST, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const addContact = async (formData) => {
  try {
    let response = await axios.post(POST.ADD_CONTACT,formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateContact = async (formData) => {
  try {
    let response = await axios.post(POST.UPDATE_CONTACT, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteContact = async (formData) => {
  try {
    let response = await axios.post(POST.DELETE_CONTACT, formData);
    return response;
  } catch (err) {
    throw err;
  }
};
