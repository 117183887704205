const POST = {
  LOGIN: "employee/login",
  ADD_EMPLOYEE: "employee/create",
  CREATE_TICKET: "employee/ticket",
  NOTIFY_EMPLOYEE: "employee/notify",
  UPDATE_ADMIN: "employee/user/update",
  UPGRADE_PLAN: "employee/upgrade_plan",
  UPDATE_EMPLOYEE: "employee/user/update",
  ADD_CONTACT: "employee/add_contact_book",
  DELETE_ACTION_LOG: "employee/delete_log",
  UPDATE_ACTION_LOG: "employee/update_log",
  ADD_NEW_ADMIN: "employee/user/make_admin",
  UPDATE_PROFILE: "employee/update_nursery",
  CHANGE_PASSWORD: "employee/reset_password",
  ADD_USER_CARD: "employee/user_card/create",
  SUBSCRIBED_PLAN: "employee/subscribed_plan",
  FORGOT_PASSWORD: "employee/forget_password",
  RESEND_INVITE: "employee/resent_invitation",
  DELETE_EMPLOYEE: "employee/delete_employee",
  UPDATE_TICKET_RESPONSE: "employee/response",
  UPDATE_NEW_USER: "employee/super_panel_user",
  INVITE_EMPLOYEE: "employee/user/add_in_bulk",
  IMPORT_EMPLOYEE: "employee/import_from_file",
  ACCEPT_DECLINE: "/admin/nursery/accept_reject",
  DELETE_CONTACT: "employee/delete_contact_book",
  UPDATE_TICKET_STATUS: "employee/ticket_status",
  UPDATE_CONTACT: "employee/update_contact_book",
  ADD_NEW_USER: "employee/super_panel_user/create",
  ACCEPT_DECLINE: "employee/nursery/accept_reject",
  GET_DETAIL_BY_TOKEN:"employee/get_user_by_token",
  ADD_BROADCAST_NOTIFICATION: "employee/broadcast",
  CREATE_BY_ARRAY: "employee/create_users_by_array",
  ADD_ACTION_LOG_BY_EMPLOYEE_ID: "employee/add_log",
  UPDATE_ADD_NEW_SETTING: "employee/update_nursery",
  UPLOAD_FILE_REPORT: "employee/upload_report_file",
  EMPLOYEE_UPDATE_PROFILE: "employee/update_profile",
  UPDATE_EVALUATION_DATA: "employee/update_evaluation",
  ACTIVE_DEACTIVE_EMPLOYEE: "employee/active_deactive",
  CREATE_EVALUATION_DATA: "employee/create_evaluation",
  ADD_NEW_SETTING: "admin/nursery/create_by_super_admin",
  ADD_NEW_SETTING: "employee/nursery/create_by_super_admin",
  ADD_NEW_SETTING: "employee/nursery/create_by_super_admin",
  SEND_QUESTIONNAIRE_FORM: "employee/all-questionnaire-form",
  SEND_EMPLOYEE_CHECK_IN_FORM: "employee/submit_questionaires",
  REQUEST_WELL_BEING_CHECK_IN_FORM: "employee/notify-checkin-form",
  UPDATE_SUBSCRIPTION_PER_USER_PRICE: "employee/subscription_plans",
  MARK_MENTAL_HEALTH_ABSENCE_BY_EMPLOYEE_ID: "employee/save_response",
  INVITATION_RESEND_SELECTED: "employee/invitation_sent/by_selection",
  INVITATION_CANCEL_SELECTED: "employee/invitation_cancel_by_selection",
  SEND_QUESTIONNAIRE_FORM_TO_EMPLOYEE_BY_ID: "employee/single-questionnaire",
  ADD_CAMPUS:"employee/campus",
  ADD_DEPARTMENT_CAMPUS: "employee/campus/add_department",
  REMOVE_DEPARTMENT_CAMPUS: "employee/campus/remove_department",

  ADD_DEPARMENT: "employee/department",
  ADD_CAMPUS_DEPARTMENT: "employee/department/add_campus",
  REMOVE_CAMPUS_DEPARTMENT: "employee/department/remove_campus"

};

const GET = {
  GET_ROLE: "admin/role",
  GET_ROLE: "employee/role",
  GET_EMP_ID: "employee/get_emp_id",
  GET_PROFILE: "employee/get-profile",
  GET_EMPLOYEE_ID: "employee/get_emp_id",
  GET_TICKET_DETAILS: "/employee/ticket",
  GET_REPORT: "employee/responses_report",
  GET_PAYMENT_HISTORY: "/employee/history",
  GET_ADMINS: "employee/listing_employees",
  GET_REMOVE_ADMIN: "employee/remove_admin",
  HISTORY_LIST: "employee/report_file_list",
  GET_ALL_USERS: "employee/super_panel_user",
  CAUSE_FOR_CONCERN: "employee/low-response",
  GET_SETTINGS: "employee/listing_nurseries",
  GET_EMPLOYEE_STATS_BY_ID: "employee/states",
  INVITE_LIST: "employee/pending_employee_list",
  GET_ADMIN_COUNTS: "employee/admin_user_count",
  INVITATOIN_CANCEL: "employee/invitation_cancel",
  GET_EMPLOYEE_DETAIL: "employee/employee_detail",
  BLOCK_SETTING: "employee/nursery_block_unblock",
  GET_CURRENT_BALANCE: "employee/current_balance",
  GET_EMPLOYEE_LIST: "employee/listing_employees",
  GET_SUPPORT_TICKETS: "employee/ticket_by_nursery",
  GET_ACTION_LOG_BY_EMPLOYEE_ID: "employee/get_Log",
  GET_SUPPORT_TICKET_COUNTS: "employee/ticket_count",
  CONTACT_BOOK_LIST: "employee/listing_contact_book",
  GET_EVALUATION_LIST: "employee/get_evaluation_list",
  GET_SUBSCRIPTION_PLANS: "employee/subscription_plans",
  RENEWAL_STATUS_CHANGE: "employee/renwal_status_change",
  GET_NURSERY_TRANSACTION: "employee/transaction_history",
  RESEND_INVITATION_ALL: "employee/invitation_sent_to_all",
  CANCEL_INVITATION_ALL: "employee/invitation_cancel_to_all",
  GET_EMPLOYEE_RESPONSES_LIST: "employee/get_response_by_id",
  GET_RECENT_RESPONSE_AND_PENDING_FORMS: "employee/dashboard",
  GET_DASHBOARD_FORMS: "employee/admin_dashboard/forms_states",
  GET_OVERVIEW: "employee/super_panel/over_view_section_stats",
  CANCEL_SUBSCRIPTION_PLAN:"employee/cancel_subscription_plan",
  GET_BROADCAST_NOTIFICATION_LIST: "employee/notification_list",
  GET_ALL_ACTIVE_NURSURIES: "employee/get_all_active_nurseries",
  GET_DASHBOARD_USERS_STATS: "employee/admin_dashboard/user_states",
  GET_EMPLOYEE_DASHBOARD_STATS: "employee/admin_dashboard/user_states",
  GET_DASHBOARD_SUBSCRIPTION: "employee/super_panel/subscription_stats",
  GET_DASBOARD_SUPPORT_TICKET: "employee/super_panel/support_ticket_count",
  GET_DASHBOARD_SUBSCRIPTION_SALES: "employee/super_panel/subscription_sale",
  GET_EMPLOYEE_CONSULTATION_FORM_LIST: "employee/employee_consultation_form",
  GET_DASHBOARD_SUPPORT_TICKET_COUNTS: "employee/admin_dashboard/support_ticket_count",
  GET_DEPARTMENTS_LIST:"employee/department",
  GET_CAMPUSES_LIST: "employee/campus",
  GET_DEPARTMENT_LIST : "employee/department"

};

const PUT = {
  UPDATE_PASSWORD: "employee/update_password",
};

const DELETE = {
  DELETE_USER: "employee/super_panel_user",
  DELETE_EVALUATION: "employee/delete_evaluation",
  DELETE_CAMPUS: "employee/campus",
  DELETE_DEPARTMENT : "employee/department"
};

export { POST, GET, PUT, DELETE };
