import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ChevronDownIcon } from "@heroicons/react/24/solid";

export default function StatusFilter({ options, selected, setSelected, className, maxWidth, selectedLabelColor, isDisabled }) {
  return (
    <Listbox value={selected} onChange={setSelected} disabled={isDisabled}>
      <div className={`relative mt-1 -mr-3 ${maxWidth ? maxWidth : "w-28"}`}>
        <Listbox.Button
          className={`relative h-full w-full cursor-pointer rounded-full ${className ? className : "border border-primary-color-300 bg-white"}   py-2 pl-4 pr-10 text-left text-sm focus:outline-none ${
            isDisabled ? "cursor-not-allowed border-gray-300 bg-gray-200 text-gray-400" : ""
          }`}
          disabled={isDisabled}
        >
          <span className="block  pl-3">{selected ? selected.label : <span className="font-normal text-primary-color-100">All</span>}</span>
          <span className="pointer-events-none absolute inset-y-0 right-1 flex items-center pr-2">
            <ChevronDownIcon className="h-4 w-4 text-gray-400" aria-hidden="true" />
          </span>
        </Listbox.Button>
        {!isDisabled && (
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm">
              {options.map((status, statusIdx) => (
                <Listbox.Option
                  key={statusIdx}
                  className={({ active }) =>
                    `relative z-10 cursor-pointer select-none py-2 pl-10 pr-4 ${active ? "bg-gray-200 text-secondary" : "text-primary-color-100"}`
                  }
                  value={status}
                >
                  {({ selected }) => (
                    <>
                      <span
                        className={`block  ${selectedLabelColor ? selectedLabelColor : "font-normal"} ${selected ? "font-medium" : "font-normal"}`}
                      >
                        {status.label}
                      </span>
                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-accent">
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        )}
      </div>
    </Listbox>
  );
}
