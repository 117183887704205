import axios from "axios";
import { baseUrl } from "../utils/config";


const instance = axios.create({ baseURL: baseUrl });

instance.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem("token");
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

instance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const {
      response: { status },
    } = error;
    
    if (status === 401) {      
      localStorage.setItem("loginStatus", JSON.stringify(false));
      localStorage.clear();
      window.location.href = "/login";
    }

    return Promise.reject(error);
  },
);

export { instance as axios };
