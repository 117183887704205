import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getSubscriptionPlans = async (data) => {
  try {
    let response = await axios.get(GET.GET_SUBSCRIPTION_PLANS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateSubscriptionPerUserPrice = async (data, id) => {
  try {
    let response = await axios.post(`${POST.UPDATE_SUBSCRIPTION_PER_USER_PRICE}/${id}`, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const subscribedPlan = async (formData) => {
  try {
    let response = await axios.post(POST.SUBSCRIBED_PLAN, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const profileSubscribedPlan = async (formData) => {
  try {
    let response = await axios.post(POST.UPGRADE_PLAN, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
