import Button from "./Button";
import InputField from "./InputField";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { useLoggedContext } from "../context/provider";
import { validateForm } from "../helperFunctions/helper";
import ApiServices from "../api/services";
import SuccessModal from "./SuccessModal";
import { useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { images } from "../assets/styles/Images";
const NotifyUserModal = ({ isOpen, setIsOpen, createTicket, data, onSuccess }) => {
  //  

  // Context
  const { openSuccessModal } = useLoggedContext();

  // Use State
  const [ticketBtnLoading, setTicketBtnLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedReplyImages, setSelectedReplyImages] = useState([]);
  const [previewReplyImages, setPreviewReplyImages] = useState([]);
  const [formData, setFormData] = useState({ title: "", url: "", description: "" });

  // Destructure Values From formData
  const { title, url, description } = formData;

  const MAX_MESSAGE_LENGTH = 500;

  // Input Values Handler
  const handleChange = (event) => {
    const { name, value } = event.target;
    let trimmedValue = value.trimStart();
    let error = "";

    // Validate URL
    if (name === "url" && trimmedValue.length > 0) {
      const urlRegex = /^((ftp|http|https):\/\/)?(www\.)?[^ "]+(\.[^ "]+)+$/;
      if (!urlRegex.test(trimmedValue)) {
        error = "Please enter a valid URL.";
      }
    }

    // Validate description length
    if (name === "description" && trimmedValue.length > MAX_MESSAGE_LENGTH) {
      error = `Message must be at least ${MAX_MESSAGE_LENGTH} characters long.`;
    }

    setErrors({ ...errors, [name]: error });
    setFormData({ ...formData, [name]: trimmedValue });
  };

  // Handle Select Images
  const handleReplyImageChange = (event) => {
    const files = event.target.files;

    if (files.length > 0) {
      const newImages = Array.from(files)?.map((file) => URL.createObjectURL(file));
      setPreviewReplyImages((prevImages) => [...prevImages, ...newImages]);

      const filesArray = Array.from(files);
      setSelectedReplyImages((prevImages) => [...prevImages, ...filesArray]);
    }
  };

  // Handle Cancel Selection
  const handleCancelSelection = (index) => {
    setPreviewReplyImages((prevImages) => prevImages?.filter((_, i) => i !== index));
    setSelectedReplyImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  // Submit Function
  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const errors = validateForm(formData);
  //   const { title, description, selectedReplyImages } = errors;
  //   setErrors(errors);

  //   if (!title && !description && !selectedReplyImages) {
  //     // If No Error
  //     if (createTicket) {
  //       createNewTicket();
  //     } else {
  //       sendNotificationMessageToEmployee();
  //     }
  //   }
  // };

  // Submit Function
  const handleSubmit = async (event) => {
    event.preventDefault();

    const errors = validateForm(formData);
    const { title, description, selectedReplyImages } = errors;
    setErrors(errors);

    if (!title && !description && !selectedReplyImages) {
      if (createTicket) {
        createNewTicket();
      } else {
        if (formData.description.length > MAX_MESSAGE_LENGTH) {
          setErrors({
            ...errors,
            description: `Message must be at most ${MAX_MESSAGE_LENGTH} characters long.`,
          });
        } else {
          sendNotificationMessageToEmployee();
        }
      }
    }
  };

  const createNewTicket = () => {
    let payload = new FormData();
    payload.append("title", formData?.title);
    payload.append("description", formData?.description);
    selectedReplyImages.forEach((image, index) => {
      payload.append(`images[${index}]`, image);
    });
    setTicketBtnLoading(true);
    ApiServices.supportTickets
      .createTicket(payload)
      .then((res) => {
        if (res?.data?.code === 200) {
          setTicketBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "Ticket has been Created",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
          setFormData({ title: "", description: "" });
          setIsOpen(false);
        }
      })
      .catch(({ response }) => {
         
      });
  };

  const sendNotificationMessageToEmployee = () => {
    setTicketBtnLoading(true);
    const messageData = new FormData();
    messageData.append("employee_id", data?.id);
    messageData.append("title", formData?.title);
    messageData.append("message", formData?.description);
    messageData.append("url", formData?.url);
    ApiServices.employees
      .sendNotificationMessage(messageData)
      .then((res) => {
        if (res?.data?.code === 200) {
          setTicketBtnLoading(false);
          openSuccessModal({
            title: "Success!",
            message: "Notification has been Sent!",
            onPress: (close) => {
              close();
            },
          });
          setFormData({ title: "", description: "" });
          setIsOpen(false);
        }
      })
      .catch(({ response }) => {
         
      });
  };

  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setErrors({});
    setTicketBtnLoading(false);
    setFormData({ title: "", description: "" });
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-2xl overflow-hidden rounded-2xl bg-white p-8 align-middle shadow-xl transition-all">
                  <div className="flex items-center justify-between">
                    <Dialog.Title as="h3" className="font-poppins text-lg font-semibold leading-7 text-secondary-color">
                      {createTicket ? "Create Ticket" : "Send Notification"}
                    </Dialog.Title>
                    <XMarkIcon onClick={closeModal} className="h-8 w-8 cursor-pointer text-primary-color-200" />
                  </div>
                  {/* {!createTicket && (
                    <p className="text-xs text-primary-color-100 3xl:text-sm">
                      Sending notification to<span className="pl-1 font-medium text-secondary">{data?.employee[0]?.name}</span>
                    </p>  
                  )} */}

                  <form onSubmit={handleSubmit} className="mt-8 space-y-6">
                    <div className={`grid ${createTicket ? "grid-cols-1" : "grid-cols-2"}  gap-6`}>
                      <InputField
                        type="text"
                        name="title"
                        label="Title"
                        value={title}
                        error={errors.title}
                        onChange={handleChange}
                        placeholder="Add a notification title"
                        maxLength={50}
                        required
                      />
                      {!createTicket && (
                        <InputField
                          type="text"
                          name="url"
                          label="URL"
                          value={url}
                          error={errors.url}
                          onChange={handleChange}
                          placeholder="Add a URL"
                          maxLength={50}
                        />
                      )}
                    </div>

                    <div className="mt-6 space-y-2">
                      <label htmlFor="description" className="pl-1 text-sm font-medium text-primary-color-100">
                        Description <span className="text-red-500">*</span>{" "}
                        {errors.description && <span className="text-xs text-red-500 ">{errors.description}</span>}
                      </label>
                      <div
                        className={`rounded-xl border p-1 ${errors.description ? "!border-red-500 focus:ring-red-500" : "border-primary-color-300"}`}
                      >
                        <textarea
                          rows="10"
                          onChange={handleChange}
                          name="description"
                          value={description}
                          placeholder="Write your message here"
                          className={` w-full border-none  px-6 py-3 text-sm font-normal text-primary-color-200 placeholder:text-primary-color-300 focus:outline-none focus:ring-0 `}
                        />
                        {createTicket && (
                          <div className={`flex items-center px-4 pb-4 pt-1 ${previewReplyImages ? "justify-between" : "justify-end"}`}>
                            {/* {previewReplyImages && (
                              <div className="flex flex-wrap gap-2">
                                {previewReplyImages.map((image, index) => (
                                  <div key={index} className="relative h-12 w-12 cursor-pointer">
                                    <div
                                      className="absolute top-0 right-0 flex h-4 w-4 items-center rounded-full bg-white shadow-tool-tip"
                                      onClick={() => handleCancelSelection(index)}
                                    >
                                      <XMarkIcon className="h-4 w-4 text-black" />
                                    </div>
                                    <img src={image} alt={`Selected Image ${index}`} className="h-full w-full rounded-sm" />
                                  </div>
                                ))} */}
                            {previewReplyImages && (
                              <div className="flex flex-wrap gap-2">
                                {previewReplyImages.map((image, index) => (
                                  <div key={index} className="relative h-12 w-12 cursor-pointer">
                                   
                                    <div
                                      className="absolute top-0 right-0 flex h-4 w-4 items-center rounded-full bg-white shadow-tool-tip"
                                      onClick={() => handleCancelSelection(index)}
                                    >
                                      <XMarkIcon className="h-4 w-4 text-black" />
                                    </div>
                                    {selectedReplyImages[index].type === "image/jpeg" || selectedReplyImages[index].type === "image/png" ? (
                                      <img src={image} alt={`Selected Image ${index}`} className="h-full w-full rounded-sm" />
                                    ) : (
                                      <div className="flex h-full w-full items-center justify-center rounded-sm bg-gray-200">
                                        <span>
                                          <img src={images.PDF} className="h-8 w-8" alt="pdf-icon" />
                                        </span>
                                      </div>
                                    )}
                                  </div>
                                ))}
                              </div>
                            )}

                            <div className="flex flex-col gap-y-2">
                              {/* {showToolTip && <div className="p-2 bg-white rounded-lg text-primary-color-100 shadow-tool-tip">Upload Attachments</div>} */}

                              <div className="self-end">
                                <label htmlFor="attachments" id="attachment">
                                  <img src={images.Attachments} alt="Attachments" style={{ cursor: "pointer" }} />
                                </label>
                                <input
                                  type="file"
                                  multiple
                                  name="attachments"
                                  id="attachments"
                                  accept="image/*,.pdf"
                                  style={{ display: "none" }}
                                  onChange={handleReplyImageChange}
                                />
                              </div>
                              <Tooltip
                                anchorSelect="#attachment"
                                id="attachment-tooltip"
                                offset={5}
                                className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                                classNameArrow="hidden"
                                effect="solid"
                                place="top-start"
                              >
                                {/* Tooltip Content */}
                                <span>Upload Attachments</span>
                              </Tooltip>
                            </div>
                          </div>
                        )}
                      </div>
                      <p className="text-right text-sm text-primary-color-100">{description?.length || 0}/500</p>
                    </div>

                    <div className="flex items-center justify-end gap-x-6">
                      <h3 onClick={closeModal} className="cursor-pointer text-lg font-medium text-secondary">
                        Cancel
                      </h3>
                      <Button loading={ticketBtnLoading} title={createTicket ? "Submit" : "Send"} />
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default NotifyUserModal;
