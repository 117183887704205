import { axios } from "../axios";
import { GET, POST, PUT } from "../endpoints";

export const getProfile = async (data) => {
  try {
    let response = await axios.get(GET.GET_PROFILE, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateProfile = async (formData) => {
  try {
    let response = await axios.post(POST.EMPLOYEE_UPDATE_PROFILE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getNurseryTransaction = async (data) => {
  try {
    let response = await axios.get(GET.GET_NURSERY_TRANSACTION, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const renewalStatusChange = async () => {
  try {
    let response = await axios.get(GET.RENEWAL_STATUS_CHANGE);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addUserCard = async (data) => {
  try {
    let response = await axios.post(POST.ADD_USER_CARD, data);
    return response;
  } catch (err) {
    throw err;
  }
};



export const cancelSubscriptionPlan = async () => {
  try {
    let response = await axios.get(GET.CANCEL_SUBSCRIPTION_PLAN);
    return response;
  } catch (err) {
    throw err;
  }
};