import React, { useEffect, useState } from "react";

// ===================== React Select ====================== //
import Select from "react-select";

import { images } from "../assets/styles/Images";

// ===================== Context & Provider ====================== //
import { useLoggedContext } from "../context/provider";

import Skeleton from "react-loading-skeleton";
import axios from "axios";
import { baseUrl } from "../utils/config";
import { mediaUrl } from "../utils/config";
import Table from "../components/Table";
import ConfirmationModal from "../components/ConfirmationModal";
import AddNewSettingModal from "../components/AddNewSettingModal";
import NotificationCircle from "../components/NotificationCircle";
import Heading from "../components/Heading";
import Badge from "../components/Badge";
import TitleValue from "../components/TitleValue";
import { MagnifyingGlassIcon } from "@heroicons/react/24/solid";
import TransactionHistoryModal from "../components/TransactionHistoryModal";
import { Tooltip } from "react-tooltip";

import ApiServices from "../api/services";

const tableHeader = [
  {
    id: 1,
    image: images.Setting2,
    SettingName: "Quantum Innovations",
    ContactPerson: "John",
    NoOfEmployees: "12",
    Email: "john@gmail.com",
    Phone: "+34 456 456 75",
    JoinedAt: "Dec 5,2022",
    LastUpdateBy: "aleena",
    Adress: "49 Featherstone Street",
    transactionHistory: [
      {
        id: 1,
        InvoiceID: "QA12341",
        Amount: "$350 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 2,
        InvoiceID: "QA12341",
        Amount: "$150 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 1,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
    ],
    status: "Active",
  },
  {
    id: 2,
    image: images.Setting1,
    SettingName: "PixelCraft Studios",
    ContactPerson: "Emily's",
    NoOfEmployees: "21",
    Email: "Emily@gmail.com",
    Phone: "+44 123 456 75",
    JoinedAt: "Dec 8,2023",
    LastUpdateBy: "Katt",
    Adress: "56 Featherstone Street",
    transactionHistory: [
      {
        id: 1,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 2,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
    ],
    status: "Block",
  },
  {
    id: 3,
    image: images.Setting2,
    SettingName: "Zenith Robotics",
    ContactPerson: "david",
    NoOfEmployees: "23",
    Email: "david@gmail.com",
    Phone: "+34 456 426 75",
    JoinedAt: "Dec 5,2022",
    LastUpdateBy: "aleena",
    Adress: "49 Featherstone Street",
    transactionHistory: [
      {
        id: 1,
        InvoiceID: "QA12341",
        Amount: "$350 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 2,
        InvoiceID: "QA12341",
        Amount: "$150 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
    ],
    status: "Active",
  },
  {
    id: 4,
    image: images.Setting1,
    SettingName: "EcoFusion Energy",
    ContactPerson: "johnson",
    NoOfEmployees: "11",
    Email: "johnson@gmail.com",
    Phone: "+44 123 456 75",
    JoinedAt: "Dec 8,2023",
    LastUpdateBy: "Katt",
    Adress: "56 Featherstone Street",
    transactionHistory: [
      {
        id: 1,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 2,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 3,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
      {
        id: 4,
        InvoiceID: "QA12341",
        Amount: "$250 USD",
        SubscriptionPackage: "Premium / Monthly",
        Status: "Received",
        ReceivedAt: "Dec 8,2023",
      },
    ],
    status: "Block",
  },
];

const columnsAll = [
  { header: "Setting Name", path: "name", hasImage: true, imageKey: "logo" },
  { header: "Contact Person", path: "contact_person_name" },
  { header: "Employees", path: "employees" },
  { header: "Joined at", path: "created_at", tooltip: true },
  { header: "Status", path: "status" },
  { header: "Actions", actions: ["edit", "block"] },
];

const columnsPending = [
  { header: "Setting Name", path: "name", hasImage: true },
  { header: "Contact Person", path: "contact_person_name" },
  { header: "Employees", path: "employees" },
  { header: "Joined at", path: "created_at", tooltip: true },
  { header: "Actions", actions: ["accept", "decline"] },
];

const transactionHistoryColumns = [
  { header: "Amount", path: "Amount" },
  { header: "Package", path: "SubscriptionPackage" },
];

const employeeType = [
  { value: "", label: "All" },
  { value: "unblock", label: "Blocked Settings" },
  { value: "block", label: "Active Settings" },
];

export default function SuperAdminDashboard() {
  const { openSuccessModal } = useLoggedContext();

  // Use States
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [openStatusModal, setOpenStatusModal] = useState({ open: false, data: null, label: "" });
  const [openTransactionHistoryModal, setOpenTransactionHistoryModal] = useState({ open: false, data: null });
  const [settingEditData, setSettingEditData] = useState("");
  const [addNewModal, setAddNewModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({
    value: "",
    label: "All",
  });
  const [userStatus, setUserStatus] = useState({
    all: true,
    pending: false,
  });

  const [pendingSettings, setPendingSettings] = useState([]);
  const [allSettings, setAllSettings] = useState([]);

  const getAllSettings = (searchText, id) => {
    setLoading(true);
    let data = {};

    ApiServices.settings
      .getSettings(data)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          const sortedArray = data?.data?.data?.sort((a, b) => {
            return b.created_at ?? 0 - a.created_at ?? 0;
          });
          setAllSettings(data?.data?.data);
          // setCurrentPage(data?.data?.current_page);
          // setTotalPageCount(Math.ceil(data?.data?.total / data?.data?.per_page));

          setLoading(false);
        } else {
          setLoading(false);
           
        }
      })
      .catch((err) => {
         
      });
  };

  // const getPendingSettings = (searchText, id) => {
  //   setLoading(true);
  //   let data = {
  //     status: "Pending",
  //   };

  //   // status: "Pending",
  //   // page: page ?? 1,
  //   // limit: itemsPerPage,

  //   ApiServices.settings
  //     .getSettings(data)
  //     .then((res) => {
  //       const { data, message } = res;

  //       if (data.code === 200) {
  //         const sortedArray = data?.data?.data?.sort((a, b) => {
  //           return b.created_at ?? 0 - a.created_at ?? 0;
  //         });
  //         setPendingSettings(sortedArray);
  //         setSelectedRow(sortedArray[0]);
  //         setCurrentPage(data?.data?.current_page);
  //         setTotalPageCount(Math.ceil(data?.data?.total / data?.data?.per_page));
  //         // setPageCount(data.data.last_page);
  //         const newUser = sortedArray?.find((data) => data?.id);
  //         if (!newUser && id) {
  //           setUserStatus({ pending: false, all: true });
  //         }
  //         //   setUserDetails(newUser);
  //         setLoading(false);
  //         // } else {
  //         setLoading(false);
  //          
  //       }
  //     })
  //     .catch((err) => {
  //        
  //     });
  // };

  const acceptSetting = () => {
    let id = selectedRow?.id;
    ApiServices.settings
      .acceptSetting(id)
      .then((res) => {
        const { data, message } = res;
      })
      .catch((err) => {
         
      });
  };

  const blockSetting = () => {
    let id = selectedRow?.id;

    ApiServices.settings
      .blockSetting(id)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          openSuccessModal({
            title: "Success!",
            message: "Nursery has been blocked",
            onPress: (close) => {
              close();
              getAllSettings();
            },
          });
        }
      })
      .catch((err) => {
         
      });
  };

  // On Change User State
  const onDropdownSelect = (data) => {
    setSelectedEmployee(data);
    setPageNumber(0);
  };

  const changeSearch = (e) => {
    setSearchText(e.target.value);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row);
  };

  const selectedTableHeader = tableHeader?.find((item) => item.id === selectedRow?.id);

  // ======================== Use Effects ============================ //
  useEffect(() => {
    // getPendingSettings();
    getAllSettings();
  }, []);

  // useEffect(() => {
  //   if (searchText?.length > 0) {
  //     if (userStatus.pending) {
  //       getPendingSettings(searchText);
  //     } else {
  //       getUsers(searchText);
  //     }
  //   } else {
  //     if (userStatus.pending) {
  //       getPendingUsers();
  //     } else {
  //       getUsers();
  //     }
  //   }
  // }, [searchText, selectedEmployee]);

  const buttonClick = () => {
    openSuccessModal({
      title: "Password Reset Link Sent!",
      message: "Password reset link has been sent to your given email address. Please check and reset your password",
    });
  };

  return (
    <>
      <div className="grid grid-cols-12 gap-4 ">
        <div className="col-span-12 xl:col-span-8 2xl:col-span-9">
          <div className="card h-[80vh] space-y-4">
            {/* <button onClick={buttonClick}>hello</button> */}
            <div>
              <div className="sticky top-0 z-[2] flex w-full flex-col items-start justify-between gap-x-6  bg-white pt-2  md:flex-row md:items-center">
                {/* Tabs */}

                <div className="users flex items-center gap-x-2 2xl:gap-x-4">
                  <button
                    className={
                      "border-b-2 text-base text-slate-800 transition-all " +
                      (userStatus.all ? "border-slate-800 font-medium" : "border-transparent font-normal")
                    }
                    onClick={() => {
                      setUserStatus((prevState) => {
                        return { ...prevState, all: true, pending: false };
                      });
                      setPageNumber(0);
                      setSearchText("");
                    }}
                  >
                    All
                  </button>
                  <div className="flex items-center gap-x-1 2xl:gap-x-2">
                    <button
                      className={
                        "text-base text-slate-800 transition-all " +
                        (userStatus.pending ? "border-b-2 border-slate-800 font-medium" : "border-b-0 font-normal")
                      }
                      onClick={() => {
                        setUserStatus((prevState) => {
                          return { ...prevState, all: false, pending: true };
                        });
                        setPageNumber(0);
                        setSearchText("");
                      }}
                    >
                      Pending
                    </button>
                    <NotificationCircle notificationCount={pendingSettings?.length} />
                  </div>
                </div>

                {/* Search */}
                <form>
                  <div className="mt-2 flex items-center justify-center gap-x-2 md:mt-0 2xl:gap-x-4">
                    <button className="w-32 rounded-full bg-secondary py-2.5 text-white" type="button" onClick={() => setAddNewModal(true)}>
                      Add New
                    </button>

                    <div className="relative flex items-center ">
                      <div className="pointer-events-none absolute inset-y-0 left-0 z-10 flex items-center pl-4">
                        <MagnifyingGlassIcon className="h-5 w-5 text-primary-color-100" />
                      </div>
                      <input
                        type="text"
                        id="search"
                        name="search"
                        placeholder="Search..."
                        value={searchText}
                        onChange={(e) => setSearchText(e.target.value)}
                        className="focus:ring-primary-color block w-full rounded-full border border-grayColor200 px-4 
                 py-2.5 pl-11 text-sm text-primary-color-100 focus:border-primary-color-100 2xl:py-3"
                      />
                    </div>
                    {!userStatus.pending === true && (
                      <div className="relative w-32 2xl:w-48">
                        <Select
                          value={selectedEmployee}
                          onChange={onDropdownSelect}
                          options={employeeType}
                          placeholder={selectedEmployee}
                          classNamePrefix="bg-red"
                          isSearchable={false}
                          components={{
                            IndicatorSeparator: () => null,
                            valueContainer: () => null,
                          }}
                          styles={dropdownStyles}
                          className="w-full rounded-full border border-[#C5C5C5] px-6 py-2 text-sm font-normal text-grayColor300 2xl:py-2.5"
                        />
                      </div>
                    )}
                  </div>
                </form>
              </div>
              <Heading subheading="Details of active settings & pending for approval.  " />
            </div>
            {userStatus.pending === true ? (
              <>
                <Table
                  firstColWidth="first:w-60"
                  data={pendingSettings}
                  columns={columnsPending}
                  loading={false}
                  selectedRow={selectedRow}
                  onRowClick={handleRowClick}
                  onDecline={() => setOpenStatusModal({ open: true, data: tableHeader, label: "Decline" })}
                  onAccept={() => setOpenStatusModal({ open: true, data: tableHeader, label: "Accept" })}
                />
              </>
            ) : (
              <>
                <Table
                  firstColWidth="first:w-60"
                  data={allSettings}
                  columns={columnsAll}
                  loading={false}
                  selectedRow={selectedRow}
                  onRowClick={handleRowClick}
                  onBlock={() => setOpenStatusModal({ open: true, data: allSettings })}
                  onEdit={() => setAddNewModal(true)}
                />
              </>
            )}
          </div>
        </div>

        <div className="col-span-12 xl:col-span-4 2xl:col-span-3">
          <div className="card h-full space-y-8 overflow-auto xl:h-[80vh]">
            <div className="flex items-baseline justify-between ">
              <Heading heading="Detail" />
              {!userStatus.pending === true && (
                <div className="flex items-center gap-x-2">
                  <button
                    className="border-b border-secondary text-sm font-medium text-secondary"
                    type="button"
                    onClick={() => {
                      setAddNewModal(true);
                      setSettingEditData(selectedRow);
                    }}
                  >
                    Edit
                  </button>
                  <button
                    className="border-b border-red-500 text-sm font-medium text-red-500 "
                    type="button"
                    onClick={() => setOpenStatusModal({ open: true, label: "Block" })}
                  >
                    Block
                  </button>
                </div>
              )}
            </div>
            <div className="space-y-5">
              <div className="flex flex-col items-center gap-y-2">
                <div className="mx-auto h-40 w-40 rounded-2xl">
                  <img src={images.placeholder} className="h-full w-full rounded-2xl object-cover" alt="icon" />
                </div>
                <h2 className="text-lg font-semibold text-black">{selectedRow?.SettingName}</h2>
                <Badge status={selectedRow?.status} />
              </div>
              <div className="grid grid-cols-12 gap-y-4 rounded-lg bg-detailProfileColor py-8 pl-3">
                <div className="col-span-6">
                  <TitleValue title="Contact Person" value={selectedRow?.contact_person_name} />
                </div>
                <div className="col-span-6">
                  <TitleValue title="No. of Employees" value={selectedRow?.employees} />
                </div>
                <div className="col-span-6">
                  <TitleValue title="Email" value={selectedRow?.email} />
                </div>
                <div className="col-span-6">
                  <TitleValue title="Phone" value={selectedRow?.phone_number} />
                </div>

                {!userStatus.pending === true && (
                  <>
                    <div id="date" className="col-span-6">
                      <TitleValue title="Joined at " value={selectedRow?.JoinedAt} />
                    </div>
                    <Tooltip
                      anchorSelect="#date"
                      id="date-tooltip"
                      offset={5}
                      className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
                      classNameArrow="hidden"
                      effect="solid"
                      place="bottom"
                    >
                      {/* Tooltip Content */}
                      <span>{selectedRow?.JoinedAt}</span>
                    </Tooltip>
                    <div className="col-span-6">
                      <TitleValue title="Last update by " value={selectedRow?.LastUpdateBy} lastUpdated />{" "}
                    </div>
                  </>
                )}
                <div className="col-span-12">
                  <TitleValue title="Address" value={selectedRow?.address} />
                </div>
              </div>
              {userStatus.pending === true ? (
                <div className="space-y-3 py-10">
                  <button
                    className="w-full rounded-full bg-accent py-3 text-sm font-medium text-white"
                    type="button"
                    onClick={() => setOpenStatusModal({ open: true, data: selectedRow?.id, label: "Accept" })}
                  >
                    Accept
                  </button>
                  <button
                    className="w-full rounded-full border border-red-500 py-3 text-sm font-medium text-red-500 "
                    type="button"
                    onClick={() => setOpenStatusModal({ open: true, label: "Decline" })}
                  >
                    Decline
                  </button>
                </div>
              ) : (
                <div className="space-y-4">
                  <div className="flex items-baseline justify-between">
                    <Heading heading="Transaction History" />
                    <button
                      className="border-b border-secondary text-sm font-medium text-secondary"
                      type="button"
                      onClick={() => setOpenTransactionHistoryModal({ open: true, data: selectedRow })}
                    >
                      View All
                    </button>
                  </div>
                  {selectedTableHeader && (
                    <div>
                      <Table
                        firstColWidth="xl:first:w-36 2xl:first:w-40 3xl:first:w-44"
                        usePagination={false}
                        data={selectedTableHeader?.transactionHistory}
                        columns={transactionHistoryColumns}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <ConfirmationModal
        isOpen={openStatusModal.open}
        setIsOpen={(open) => setOpenStatusModal((prev) => ({ ...prev, open }))}
        data={openStatusModal.data}
        label={openStatusModal.label}
        handleSubmit={blockSetting}
      />

      <TransactionHistoryModal
        isOpen={openTransactionHistoryModal.open}
        setIsOpen={(open) => setOpenTransactionHistoryModal((prev) => ({ ...prev, open }))}
        data={openTransactionHistoryModal.data}
      />
    </>
  );
}

const dropdownStyles = {
  control: (provided, state) => {
    const transition = "opacity 300ms";
    const border = "none";
    const minHeight = "auto";
    const cursor = "pointer";
    return { ...provided, transition, border, minHeight, cursor };
  },

  singleValue: (provided, state) => {
    const margin = "0 !important";
    const textAlign = "left";
    return { ...provided, margin, textAlign };
  },

  valueContainer: (provided, state) => {
    const padding = "0 !important";
    return { ...provided, padding };
  },

  input: (provided, state) => {
    const padding = "0 !important";
    const margin = "0 !important";
    return { ...provided, padding, margin };
  },

  dropdownIndicator: () => ({
    padding: 0, // <--- Color of your choice
  }),

  menu: (provided, state) => {
    const left = "0px";
    const cursor = "cursor: pointer";
    return { ...provided, left, cursor };
  },

  placeholder: (provided, state) => {
    const margin = "0px";
    const textAlign = "left";
    return { ...provided, margin, textAlign };
  },

  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};
