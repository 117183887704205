import React, { useState } from "react";
import Spinner from "./Spinner";
import { Fragment } from "react";
import { images } from "../assets/styles/Images";
import { Dialog, Transition } from "@headlessui/react";
import { useLoggedContext } from "../context/provider";
import ReactDatePicker from "react-datepicker";
import { XMarkIcon } from "@heroicons/react/20/solid";
import moment from "moment";
import ApiServices from "../api/services";

export default function CheckInFormFillModal({
  isOpen,
  setIsOpen,
  data,
  onSuccess
}) {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  const [questions, setQuestions] = useState({
    practitionerName: "Practitioner Name",
    managerName: "Manager Name",
    date: "Date",
    personalWellbeingQ1: "What are we worried about?",
    personalWellbeingQ2: "What’s working well?",
    personalWellbeingQ3: "What needs to happen?",
    personalWellbeingQ4: "Evaluation from last check in",

    workActivityQ1: "What are we worried about?",
    workActivityQ2: "What’s working well?",
    workActivityQ3: "What needs to happen?",
    workActivityQ4: "Evaluation from last check in",

    progressQ1: "What are we worried about?",
    progressQ2: "What’s working well?",
    progressQ3: "What needs to happen?",
    progressQ4: "Evaluation from last check in",

    nextCheckInDate: "Date of next check in",
    holidayDaysLeft: "Holiday days left",
    sickDaysTaken: "Sick days taken this year",
  });

  const [checkInFormData, setCheckInFormData] = useState({
    practitionerName: "",
    managerName: "",
    date: "",
    personalWellbeingQ1: "",
    personalWellbeingQ2: "",
    personalWellbeingQ3: "",
    personalWellbeingQ4: "",
    workActivityQ1: "",
    workActivityQ2: "",
    workActivityQ3: "",
    workActivityQ4: "",
    progressQ1: "",
    progressQ2: "",
    progressQ3: "",
    progressQ4: "",
    nextCheckInDate: "",
    holidayDaysLeft: "",
    sickDaysTaken: "",
  });

  const {
    practitionerName,
    managerName,
    date,
    personalWellbeingQ1,
    personalWellbeingQ2,
    personalWellbeingQ3,
    personalWellbeingQ4,
    workActivityQ1,
    workActivityQ2,
    workActivityQ3,
    workActivityQ4,
    progressQ1,
    progressQ2,
    progressQ3,
    progressQ4,
    nextCheckInDate,
    holidayDaysLeft,
    sickDaysTaken,
  } = checkInFormData;



  //Errors
  const [errors, setErrors] = useState({});

  const isValid = () => {
    let isValid = true;
    for (let item in checkInFormData) {
      if (checkInFormData[item] == "") {
        setErrors((prev) => {
          return {
            ...prev,
            [item]: "Field is required",
          };
        });
        isValid = false;
      }
    }
    return isValid;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCheckInFormData({ ...checkInFormData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };


  const handleSubmit = (e) => {
    e.preventDefault();
    let questionsArr = [];
    for (var key in questions) {
      let question = questions[key];
      questionsArr.push({ [question]: checkInFormData[key] });
    }
  
    if (!isValid()) {
      // setIsOpen(false);
    } else {
      const formData = new FormData();
      formData.append("consultation_form_id", data);
      formData.append("is_submit", 1);
      questionsArr.forEach((questionObj, index) => {
        for (let key in questionObj) {
          formData.append(`questions[${index}][${key}]`, questionObj[key]);
        }
      });
  
      ApiServices.employees
        .sendCheckInForm(formData)
        .then((res) => {
          if (res?.data?.code === 200) {
            setBtnLoading(false);
            openSuccessModal({
              title: "Success!",
              message: "Check In Form has been Sent!",
              onPress: (close) => {
                close();
                onSuccess();
              },
            });
            setBtnLoading(false);
            for (var key in checkInFormData) {
              setCheckInFormData({ [key]: "" });
            }
            setErrors("");
          }
        })
        .catch(({ response }) => {
           
        });
    }
  };
  
  


  // Close Modal
  function closeModal() {
    setIsOpen(false);
    setBtnLoading(false);
    for (var key in checkInFormData) {
      setCheckInFormData({ [key]: "" });
    }
    setErrors("");
  }


  const backgroundColor = "secondary"

  const getButtonStyles = (buttonType) => {
    const baseStyles =
      "inline-flex w-full justify-center rounded-full px-4 py-2.5 text-base font-medium transition-all duration-300 focus:outline-none";
    switch (buttonType) {
      case "Submit":
        return `bg-${backgroundColor} text-white ${baseStyles} ${btnLoading ? "" : "hover:opacity-75"}`;
      case "Cancel":
        return `border border-${backgroundColor} text-${backgroundColor} hover:bg-${backgroundColor} ${baseStyles} hover:text-white`;
      default:
        return "";
    }
  };
  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-75"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-75"
              >
                <Dialog.Panel className="w-full max-w-3xl overflow-hidden rounded-2xl bg-white p-8 text-center align-middle shadow-xl transition-all">
                  <h3 className="text-grayColor500 text-center text-lg font-semibold sm:mx-4 md:text-2xl">Well Being Check in</h3>
                  <form
                    encType="multipart/form-data"
                    autoComplete="off"
                    className="mt-10 sm:mx-4"
                  >
                    <div className="relative mb-5 flex flex-col items-center lg:flex-row 2xl:mb-8">
                      <div className="relative clear-left mb-5 flex w-full flex-col lg:clear-none 2xl:mb-8">
                        <div className="grid gap-4 md:grid-cols-3">
                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Practitioner Name</div>
                            <input
                              className=" placeholder:[#C5C5C5] w-full rounded-full border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm "
                              placeholder="Enter practitioner name"
                              type="text"
                              name="practitionerName"
                              value={practitionerName}
                              maxLength={25}
                              onChange={handleChange}
                            />
                            {errors?.practitionerName && (
                              <span className="pl-2 text-sm text-red-600">{errors?.practitionerName}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Manager Name</div>
                            <input
                              className=" placeholder:[#C5C5C5] w-full rounded-full border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm "
                              placeholder="Enter manager name"
                              type="text"
                              name="managerName"
                              value={managerName}
                              maxLength={25}
                              onChange={handleChange}
                            />
                            {errors?.managerName && <span className="pl-2 text-sm text-red-600">{errors?.managerName}</span>}
                          </div>

                          <div className="col-span-4 sm:col-span-2 md:col-span-1 ">
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Date</div>
                            <input
                              min={moment().format("YYYY-MM-DD")}
                              className="placeholder:[#C5C5C5] mb-1 w-full rounded-full border-[1px] border-[#C5C5C5] px-6  py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm "
                              type="date"
                              name="date"
                              value={date}
                              onChange={handleChange}
                            />
                            {errors?.date && <span className="pl-2 text-sm text-red-600">{errors?.date}</span>}
                          </div>
                        </div>

                        <hr className="mx-auto mt-5 mb-8 h-px w-full border-[#C5C5C5] opacity-[0.8]" />
                        <h3 className="text-grayColor500 text-center text-lg font-medium sm:mx-4 md:text-xl">Personal Wellbeing</h3>

                        <div className="mt-8 grid gap-4 md:grid-cols-2">
                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What are we worried about?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="personalWellbeingQ1"
                              value={personalWellbeingQ1}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.personalWellbeingQ1 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.personalWellbeingQ1}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What’s working well?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="personalWellbeingQ2"
                              value={personalWellbeingQ2}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.personalWellbeingQ2 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.personalWellbeingQ2}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What needs to happen?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="personalWellbeingQ3"
                              value={personalWellbeingQ3}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.personalWellbeingQ3 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.personalWellbeingQ3}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Evaluation from last check in</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="personalWellbeingQ4"
                              value={personalWellbeingQ4}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.personalWellbeingQ4 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.personalWellbeingQ4}</span>
                            )}
                          </div>
                        </div>

                        <hr className="mx-auto mt-5 mb-8 h-px w-full border-[#C5C5C5] opacity-[0.8]" />
                        <h3 className="text-grayColor500 text-center text-lg font-medium sm:mx-4 md:text-xl">Work Activity</h3>

                        <div className="mt-8 grid gap-4 md:grid-cols-2">
                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What are we worried about?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="workActivityQ1"
                              value={workActivityQ1}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.workActivityQ1 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.workActivityQ1}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What’s working well?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="workActivityQ2"
                              value={workActivityQ2}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.workActivityQ2 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.workActivityQ2}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What needs to happen?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="workActivityQ3"
                              value={workActivityQ3}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.workActivityQ3 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.workActivityQ3}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Evaluation from last check in</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="workActivityQ4"
                              value={workActivityQ4}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.workActivityQ4 && (
                              <span className="pl-2 text-sm text-red-600">{errors?.workActivityQ4}</span>
                            )}
                          </div>
                        </div>

                        <hr className="mx-auto mt-5 mb-8 h-px w-full border-[#C5C5C5] opacity-[0.8]" />
                        <h3 className="text-grayColor500 text-center text-lg font-medium sm:mx-4 md:text-xl">Progress</h3>

                        <div className="mt-8 grid gap-4 md:grid-cols-2">
                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What are we worried about?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="progressQ1"
                              value={progressQ1}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.progressQ1 && <span className="pl-2 text-sm text-red-600">{errors?.progressQ1}</span>}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What’s working well?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="progressQ2"
                              value={progressQ2}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.progressQ2 && <span className="pl-2 text-sm text-red-600">{errors?.progressQ2}</span>}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">What needs to happen?</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="progressQ3"
                              value={progressQ3}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.progressQ3 && <span className="pl-2 text-sm text-red-600">{errors?.progressQ3}</span>}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Evaluation from last check in</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="progressQ4"
                              value={progressQ4}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.progressQ4 && <span className="pl-2 text-sm text-red-600">{errors?.progressQ4}</span>}
                          </div>
                        </div>

                        <hr className="mx-auto mt-5 mb-8 h-px w-full border-[#C5C5C5] opacity-[0.8]" />
                        <h3 className="text-grayColor500 text-center text-lg font-medium sm:mx-4 md:text-xl">Agreements</h3>

                        <div className="mt-8 grid gap-4 md:grid-cols-2">
                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Date of next check in</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="nextCheckInDate"
                              value={nextCheckInDate}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.nextCheckInDate && (
                              <span className="pl-2 text-sm text-red-600">{errors?.nextCheckInDate}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Holiday days left</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="holidayDaysLeft"
                              value={holidayDaysLeft}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.holidayDaysLeft && (
                              <span className="pl-2 text-sm text-red-600">{errors?.holidayDaysLeft}</span>
                            )}
                          </div>

                          <div>
                            <div className="mb-1 ml-4 text-start text-[11px] text-grayColor300 xl:text-sm">Sick days taken this year</div>
                            <textarea
                              placeholder="Write here..."
                              className="placeholder:[#C5C5C5] w-full rounded-xl border-[1px]  border-[#C5C5C5] px-6 py-2.5 text-sm font-normal text-[#474747] placeholder:text-[11px] 2xl:py-3 2xl:placeholder:text-sm"
                              rows="8"
                              name="sickDaysTaken"
                              value={sickDaysTaken}
                              onChange={handleChange}
                            ></textarea>
                            {errors?.sickDaysTaken && (
                              <span className="pl-2 text-sm text-red-600">{errors?.sickDaysTaken}</span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="mt-12 flex justify-center gap-x-6 px-8">
                    <button type="button" className={getButtonStyles("Submit")} onClick={handleSubmit }>
                      {btnLoading ? <Spinner /> : "Submit"}
                    </button>

                    <button type="button" className={getButtonStyles("Cancel")} onClick={closeModal}>
                      Cancel
                    </button>
                  </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
