import { axios } from "../axios";
import { POST, GET, DELETE } from "../endpoints";

export const getAdmins = async (data) => {
  try {
    let response = await axios.get(GET.GET_ADMINS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getAdminCounts = async (data) => {
  try {
    let response = await axios.get(GET.GET_ADMIN_COUNTS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const AddNewAdmin = async (requestData) => {
  try {
    let response = await axios.post(POST.ADD_NEW_ADMIN, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const updateAdmin = async (id, requestData) => {
  try {
    let response = await axios.post(`${POST.UPDATE_ADMIN}/${id}`, requestData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const removeAdmin = async (id) => {
  try {
    let response = await axios.get(`${GET.GET_REMOVE_ADMIN}/${id}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
