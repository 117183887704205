import React, { useState } from "react";
import { EyeIcon, EyeSlashIcon } from "@heroicons/react/24/outline";
import moment from "moment";

const InputField = ({
  label,
  name,
  disabled,
  type,
  value,
  readOnly,
  onChange,
  required,
  placeholder,
  error,
  maxLength,
  className,
  errorOnBottom,
}) => {
  // Use States
  const [showPassword, setShowPassword] = useState(false);

  // Toggle Password
  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  // Function to get the current date using moment
  const getCurrentDate = () => moment().format("YYYY-MM-DD");

  return (
    <div className="space-y-2">
      {label ? (
        <label htmlFor={name} className="pl-2 text-sm font-medium text-primary-color-100">
          {label} {required && <span className="text-red-500">*</span>}{" "}
          {error && !errorOnBottom && <span className="text-xs text-red-500">{error}</span>}
        </label>
      ) : (
        <span className="text-xs text-red-500">{error}</span>
      )}

      {type === "password" ? (
        <>
          <div className="relative">
            <input
              name={name}
              type={type === "password" && !showPassword ? "password" : "text"}
              value={value}
              onChange={onChange}
              className={`h-12 w-full rounded-full border px-6 py-3 text-sm font-normal text-primary-color-200 placeholder:text-primary-color-100 ${
                error ? "!border-red-500 focus:ring-red-500" : "border-primary-color-300"
              } ${className ? className : ""}`}
              placeholder={placeholder}
              maxLength={maxLength}
              disabled={disabled}
            />

            {type === "password" && (
              <span onClick={togglePasswordVisibility} className="absolute right-0 top-1/2 -translate-y-1/2 cursor-pointer px-4">
                {showPassword ? (
                  <EyeIcon className="text-primary-color h-5 w-5 dark:text-white/75" />
                ) : (
                  <EyeSlashIcon className="text-primary-color h-5 w-5 dark:text-white/75" />
                )}
              </span>
            )}
          </div>
          {errorOnBottom && <span className="text-xs text-red-500">{error}</span>}
        </>
      ) : (
        <div>
          <input
            name={name}
            type={type ? type : "text"}
            value={value}
            onChange={onChange}
            className={`h-12 w-full rounded-full border px-6 py-3 text-sm text-primary-color-200 placeholder:text-primary-color-100 ${
              error ? "!border-red-500 focus:ring-red-500" : "border-primary-color-300"
            } ${className ? className : ""}`}
            placeholder={placeholder}
            maxLength={maxLength}
            min={0}
            readOnly={readOnly}
            max={name === "dateOfBirth" ? getCurrentDate() : undefined}
            disabled={disabled}
          />

          {errorOnBottom && <span className="text-xs text-red-500">{error}</span>}
        </div>
      )}
    </div>
  );
};

export default InputField;
