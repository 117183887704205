import React, { useState } from "react";
import Badge from "./Badge";
import ImagesModal from "./ImagesModal";
import { Tooltip } from "react-tooltip";
import { mediaUrl, ticketResponseMediaUrl } from "../utils/config";
import moment from "moment";
import { images } from "../assets/styles/Images";

const SupportTicketComment = ({ personImage, personName, personStatus, tooltipDate, commentDate, commentMsg, commentImage, commentIndex, selectedTicketDetail }) => {
  const [openCommentImageModal, setOpenCommentImageModal] = useState({ open: false, data: null });
  const [selectedCommentImageIndex, setSelectedCommentImageIndex] = useState(0);

  const handleImageClick = (index) => {
    setSelectedCommentImageIndex(index); // Store the index of the clicked image
    setOpenCommentImageModal({ open: true, data: null }); // Open the modal
  };
  return (
    <>
      <div className="flex flex-col items-start gap-y-2">
        <div className="flex items-center justify-between w-full">
          <div className="flex items-center gap-x-4">
            <div className="w-12 h-12 ">
              <img src={personImage} alt="person" className="w-full h-full rounded-full" />
            </div>
            <h2 className="text-base font-semibold 3xl:text-lg text-secondary-color">{personName}</h2>
            <Badge status={personStatus} />
          </div>
          <h2 id="commentdate" className="text-sm text-primary-color-200">
            {commentDate}
          </h2>
          <Tooltip
            anchorSelect="#commentdate"
            id="commentdate-tooltip"
            offset={5}
            className="border border-gray-100 !bg-white !text-primary-color-100 !opacity-100 shadow-card"
            classNameArrow="hidden"
            effect="solid"
            place="left"
          >
            {/* Tooltip Content */}
            <span>{tooltipDate}</span>
          </Tooltip>
        </div>

        <div className="flex-grow w-[90%] last:pb-0 py-4 pl-4 ml-6 space-y-5 text-justify border-l-2">
          <div className="pb-4 border-grayColor200 ">
            <p className="pb-4 text-sm text-grayColor400">{commentMsg}</p>
            <div className="relative flex gap-x-2">
              {commentImage?.slice(0, 3).map((image, index) => (
                <div
                  key={index}
                  className={`relative h-24 w-24 cursor-pointer rounded-xl border  border-grayColor200`}
                  onClick={() => handleImageClick(index)}
                >
                 {image.endsWith(".pdf") ? (
                    <div className="flex h-full w-full items-center justify-center rounded-2xl bg-gray-200">
                       <span><img src={images.PDF} className="w-16 h-16" alt="pdf-icon" /></span>
                    </div>
                  ) : (
                    <img src={ticketResponseMediaUrl + "/" + image} className="h-full w-full rounded-2xl object-cover" alt={`Image ${index}`} />
                  )}
                  {index === 2 && commentImage.length > 3 && (
                    <div className="absolute inset-0 flex items-center justify-center rounded-xl bg-black/50">
                      <span className="text-xl font-bold text-white">{`+${commentImage.length - 3}`}</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {/* <div className="flex items-center w-full !-mb-2">
            <div className={`h-px flex-grow w-auto bg-grayColor200`}></div>
            <>
              {selectedTicketDetail?.status === "Closed" && commentIndex === selectedTicketDetail?.ticket_responses?.length - 1 && (
                <div className="flex-grow-[2]">
                  <div className="ml-5 text-sm text-grayColor400">
                    <span>Closed by </span>
                    <span className="text-sm font-medium text-secondary">{selectedTicketDetail?.closed_by}</span>
                    <span> at </span>
                    <span>{moment.unix(selectedTicketDetail?.updated_at_unix).format("D MMM YYYY h:mm A")}</span>
                  </div>
                </div>
              )}
            </>
          </div> */}
          
        </div>
      </div>


      <ImagesModal
        imagespdf={commentImage?.map((imageUrl) => ({
          original: ticketResponseMediaUrl + "/" + imageUrl,
          thumbnail: ticketResponseMediaUrl + "/" + imageUrl,
        }))}
        images={commentImage?.map((imageUrl) => ({
          original: imageUrl.endsWith('.pdf') ? images.PDF : ticketResponseMediaUrl + "/" + imageUrl,
          thumbnail: imageUrl.endsWith('.pdf') ? images.PDF : ticketResponseMediaUrl + "/" + imageUrl,
        }))}
        isOpen={openCommentImageModal.open}
        setIsOpen={(isOpen) => setOpenCommentImageModal({ ...openCommentImageModal, open: isOpen })}
        selectedImageIndex={selectedCommentImageIndex}
      />
    </>
  );
};

export default SupportTicketComment;
