import * as auth from "./auth";
import * as users from "./users";
import * as admins from "./admin";
import * as report from "./report";
import * as campus from "./campus";
import * as profile from "./profile";
import * as payments from "./payments";
import * as settings from "./settings";
import * as dashboard from "./dashboard";
import * as employees from "./employees";
import * as contactBook from "./contactBook";
import * as supportTickets from "./supportTickets";
import * as causeForConcern from "./causeForConcern";
import * as subscriptionPlans from "./subscriptionPlans";
import * as recentEvaluations from "./recentEvaluations";
import * as broadcastNotification from "./broadcastNotification";
import * as department from "./department";


export default {
  auth,
  users,
  admins,
  report,
  campus,
  profile,
  settings,
  payments,
  employees,
  dashboard,
  contactBook,
  supportTickets,
  causeForConcern,
  subscriptionPlans,
  recentEvaluations,
  broadcastNotification,
  department
};
