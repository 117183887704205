import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getSupportTickets = async (data) => {
  try {
    let response = await axios.get(GET.GET_SUPPORT_TICKETS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSupportTicketsDetail = async (id) => {
  try {
    let response = await axios.get(`${GET.GET_TICKET_DETAILS}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSupportTicketCounts = async (data) => {
  try {
    let response = await axios.get(GET.GET_SUPPORT_TICKET_COUNTS, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateSupportTicket = async (formData) => {
  try {
    let response = await axios.post(POST.UPDATE_TICKET_RESPONSE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getSettingsWithoutPagination = async (data) => {
  try {
    let response = await axios.get(GET.GET_ALL_ACTIVE_NURSURIES, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateTicketStatus = async (id, formData) => {
  try {
    let response = await axios.post(`${POST.UPDATE_TICKET_STATUS}/${id}`, formData);
    return response;
  } catch (err) {
    throw err;
  }
};


export const createTicket = async (formData) => {
  try {
    let response = await axios.post(`${POST.CREATE_TICKET}` , formData);
    return response;
  } catch (err) {
    throw err;
  }
};