import Button from "../Button";
import React, { useState } from "react";
import ApiServices from "../../api/services";
import { useLoggedContext } from "../../context/provider";

const ImportEmployees = ({ closeModal, onSuccess }) => {
  // Context
  const { btnLoading, setBtnLoading, openSuccessModal } = useLoggedContext();

  // useStates
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileError, setFileError] = useState("");

  // Accepted file formats
  const acceptedFormats = [".csv", ".xls", ".xlsx"];

  // Handle File Change
  const handleFileChange = (event) => {
    const file = event.target.files[0];

    if (!file) {
      setFileError("Please select a file");
      setSelectedFile(null);
      return;
    }

    const fileName = file.name.toLowerCase();
    const isValidFormat = acceptedFormats.some((format) => fileName.endsWith(format));

    if (!isValidFormat) {
      setFileError("Invalid file format. Please select a .CSV, .XLS, or .XLSX file");
      setSelectedFile(null);
    } else {
      setFileError("");
      setSelectedFile(file);
    }
  };

  // Submit
  const handleSubmit = () => {
    if (!selectedFile) {
      setFileError("Please select a file");
      return;
    }

    const formData = new FormData();

    formData.append("excel_file", selectedFile);

    setBtnLoading(true);
    ApiServices.employees
      .importEmployeeFromFile(formData)
      .then((res) => {
        const { data, message } = res;

        if (data.code === 200) {
          setBtnLoading(false);
          closeModal();
          onSuccess();
          openSuccessModal({
            title: "Success!",
            message: "Employee added successfully.",
            onPress: (close) => {
              close();
              onSuccess();
            },
          });
        }
      })
      .catch((err) => {
        setFileError(err?.response?.data?.message);
        setBtnLoading(false);
      });
  };

  return (
    <>
      <div className="space-y-2">
        <label htmlFor="file" className="pl-1 text-sm font-medium text-primary-color-100">
          File
        </label>
        <label
          htmlFor="uploadFile"
          className="mx-auto flex h-52 w-full cursor-pointer flex-col items-center justify-center space-y-4 rounded border-2 border-dashed border-gray-300 bg-white font-[sans-serif] text-base text-black"
        >
          <label
            htmlFor="uploadFile"
            className="flex h-10 cursor-pointer items-center justify-center rounded-full border border-secondary bg-white px-6 text-secondary hover:shadow"
          >
            Browse files
          </label>
          <input accept=".csv, .xls, .xlsx" type="file" id="uploadFile" className="hidden" onChange={handleFileChange} />
          <p className="text-sm text-primary-color-100">
            Accepted file formats: <span className="font-semibold">.CSV, .XLS, .XLSX</span>
          </p>
          <a href="https://api.edufeel.co.uk/Employee%20Data%20CSV.csv" download className="text-sm text-secondary underline">
            Download the template here
          </a>
        </label>

        {selectedFile && <p className="text-sm text-primary-color-100">Selected file: {selectedFile.name}</p>}

        {fileError && <p className="text-sm text-red-500">{fileError}</p>}
      </div>

      {/* Submit Button */}
      <div className="mt-8 flex items-center justify-end gap-x-8">
        <h3 onClick={closeModal} className="cursor-pointer text-lg font-medium text-secondary">
          Cancel
        </h3>
        <Button loading={btnLoading} title="Add Employee" onClick={handleSubmit} />
      </div>
      {/* End Submit Button */}
    </>
  );
};

export default ImportEmployees;
