import { axios } from "../axios";
import { POST, GET } from "../endpoints";

export const getEmployeeListing = async (data) => {
  try {
    let response = await axios.get(GET.GET_EMPLOYEE_LIST, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getInvitedEmployeeListing = async (data) => {
  try {
    let response = await axios.get(GET.INVITE_LIST, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const activeDeactiveEmployee = async (formData) => {
  try {
    let response = await axios.post(POST.ACTIVE_DEACTIVE_EMPLOYEE, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const inviteEmployee = async (jsonData) => {
  try {
    let response = await axios.post(POST.INVITE_EMPLOYEE, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const createByArray = async (jsonData) => {
  try {
    let response = await axios.post(POST.CREATE_BY_ARRAY, jsonData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const importEmployeeFromFile = async (formData) => {
  try {
    let response = await axios.post(POST.IMPORT_EMPLOYEE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const addEmployee = async (formData) => {
  try {
    let response = await axios.post(POST.ADD_EMPLOYEE, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateEmployee = async (id, formData) => {
  try {
    let response = await axios.post(`${POST.UPDATE_EMPLOYEE}/${id}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeDetailById = async (id) => {
  try {
    let response = await axios.get(`${GET.GET_EMPLOYEE_DETAIL}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeConsultaionFormById = async (id, data) => {
  try {
    let response = await axios.get(`${GET.GET_EMPLOYEE_CONSULTATION_FORM_LIST}/${id}`, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeResponsesId = async (id, data) => {
  try {
    let response = await axios.get(`${GET.GET_EMPLOYEE_RESPONSES_LIST}/${id}`, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendNotificationMessage = async (formData) => {
  try {
    let response = await axios.post(POST.NOTIFY_EMPLOYEE, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendQuestionnaireForm = async (formData) => {
  try {
    let response = await axios.post(POST.SEND_QUESTIONNAIRE_FORM_TO_EMPLOYEE_BY_ID, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const requestWellBeingCheckInForm = async (formData) => {
  try {
    let response = await axios.post(POST.REQUEST_WELL_BEING_CHECK_IN_FORM, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeStatsByID = async (id, data) => {
  try {
    let response = await axios.get(`${GET.GET_EMPLOYEE_STATS_BY_ID}/${id}`, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const markMentalHealthAbsenceByID = async (formData) => {
  try {
    let response = await axios.post(POST.MARK_MENTAL_HEALTH_ABSENCE_BY_EMPLOYEE_ID, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const addActionLogByID = async (formData) => {
  try {
    let response = await axios.post(POST.ADD_ACTION_LOG_BY_EMPLOYEE_ID, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeActionLogById = async (id, data) => {
  try {
    let response = await axios.get(`${GET.GET_ACTION_LOG_BY_EMPLOYEE_ID}/${id}`, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteActionLog = async (formData) => {
  try {
    let response = await axios.post(POST.DELETE_ACTION_LOG, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const updateActionLog = async (formData) => {
  try {
    let response = await axios.post(POST.UPDATE_ACTION_LOG, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const cancelInvite = async (id) => {
  try {
    let response = await axios.get(`${GET.INVITATOIN_CANCEL}/${id}`);
    return response;
  } catch (err) {
    throw err;
  }
};
export const resendInvite = async (formData) => {
  try {
    let response = await axios.post(POST.RESEND_INVITE, formData);
    return response;
  } catch (err) {
    throw err;
  }
};
export const cancelInviteAll = async () => {
  try {
    let response = await axios.get(`${GET.CANCEL_INVITATION_ALL}`);
    return response;
  } catch (err) {
    throw err;
  }
};
export const resendInviteAll = async () => {
  try {
    let response = await axios.get(`${GET.RESEND_INVITATION_ALL}`);
    return response;
  } catch (err) {
    throw err;
  }
};
export const inviteResendSelected = async (jsonData) => {
  try {
    let response = await axios.post(POST.INVITATION_RESEND_SELECTED, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};
export const inviteCancelSelected = async (jsonData) => {
  try {
    let response = await axios.post(POST.INVITATION_CANCEL_SELECTED, jsonData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const sendCheckInForm = async (formData) => {
  try {
    let response = await axios.post(POST.SEND_EMPLOYEE_CHECK_IN_FORM, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeId = async (data) => {
  try {
    let response = await axios.get(`${GET.GET_EMPLOYEE_ID}`, {
      params: data,
    });
    return response;
  } catch (err) {
    throw err;
  }
};

export const deleteEmployee = async (data) => {
  try {
    let response = await axios.post(POST.DELETE_EMPLOYEE, data);
    return response;
  } catch (err) {
    throw err;
  }
};

export const getEmployeeIds = async (data) => {
  try {
    let response = await axios.get(GET.GET_EMP_ID, data);
    return response;
  } catch (err) {
    throw err;
  }
};
